import AppBody from './app-body';
import Maintenance from 'ui/maintenance/maintenance';

function MaintenanceBody() {
    return (
        <AppBody>
            <Maintenance />
        </AppBody>
    );
}

export default MaintenanceBody;
