import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import {
    faArrowUpLeftFromCircle,
    faBell,
    faBoxArchive,
    faGear,
    faUser
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import React, { ReactNode } from 'react';

import { Widget } from 'lib-ui';
import AppBody from 'app-layout/bodies/app-body';
import cn from 'core/utils/cn';

const SettingsWidget = ({ children }: { children: ReactNode }) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    const settingsList = [
        {
            icon: faUser,
            id: 1,
            title: t('settings-tabs.personal'),
            url: '/settings/profile'
        },
        {
            icon: faGear,
            id: 2,
            title: t('settings-tabs.system'),
            url: '/settings/system'
        },
        {
            icon: faBell,
            id: 3,
            title: t('settings-tabs.notifications'),
            url: '/settings/notifications'
        },
        {
            icon: faBoxArchive,
            id: 4,
            title: t('settings-tabs.audit-history'),
            url: '/settings/audit-history'
        },
        {
            icon: faArrowUpLeftFromCircle,
            id: 5,
            title: t('settings-tabs.request-termination'),
            url: '/settings/request-termination'
        }
    ];

    return (
        <AppBody>
            <Widget className="h-full">
                <div className="flex">
                    <div className="w-[400px] max-w-[400px] h-screen ">
                        <div className="p-4 border-b border-general-grey-grey-30 py-6 px-4">
                            <h2 className="font-medium text-xl">
                                {t('settings-widget.title')}
                            </h2>
                        </div>
                        <ul>
                            {settingsList.map((item) => (
                                <li
                                    key={item.id}
                                    className={cn(
                                        'hover:bg-general-grey-grey-10 border-b border-general-grey-grey-30',
                                        item.url === pathname &&
                                            'bg-general-grey-grey-10 text-brand-escode-neonblue-neonblue-120 font-semibold cursor-default hover:bg-general-grey-grey-10'
                                    )}
                                >
                                    <Link
                                        className={cn(
                                            'text-md',
                                            item.url === pathname &&
                                                'cursor-default'
                                        )}
                                        onClick={(e) =>
                                            item.url === pathname &&
                                            e.preventDefault()
                                        }
                                        to={item.url}
                                    >
                                        <div className="flex items-center gap-4 h-20 px-6">
                                            <FontAwesomeIcon
                                                className="h-6 w-6"
                                                icon={item.icon}
                                            />
                                            <div>{item.title}</div>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex-1 h-screen">
                        <div className="h-[77px] border-b border-general-grey-grey-30" />
                        {children}
                    </div>
                </div>
            </Widget>
        </AppBody>
    );
};

export default SettingsWidget;
