import { Button } from 'lib-ui';
import { DepositItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { DepositsTableContext } from './deposits-table-context';
import { ICellRendererParams } from 'ag-grid-community';
import { NavLink } from 'react-router-dom';
import { memo } from 'react';
import { useAuth } from 'react-oidc-context';

import depositStepFormatter from 'core/formatters/deposit-step-formatter';
import useUserDetails from 'core/api/client-portal/hooks/user/use-user-details';

function DepositActionsCellRenderer({
    context: { t },
    data
}: ICellRendererParams<DepositItem, DepositsTableContext>) {
    const { user } = useAuth();
    const { data: userDetails } = useUserDetails(user || null);

    // A user can only continue In Progress deposits that they've created.
    const userIsCreator = data?.createdByUserId === userDetails?.id;
    const incomplete = data?.escrowDepositStatus === 1;
    const inProgress = data?.escrowDepositStatus === 2;
    const userCanContinue =
        (userIsCreator && (inProgress || incomplete)) ||
        (!userIsCreator && incomplete);

    return (
        (data &&
            !data.isSourceControl &&
            !data.isReadOnly &&
            userCanContinue && (
                <div className="flex h-full items-center">
                    <NavLink
                        to={`/deposit-now/${
                            data.depositId
                        }/${depositStepFormatter(data.lastSavedState ?? 0)}`}
                    >
                        <Button>{t('deposits.continue')}</Button>
                    </NavLink>
                </div>
            )) ||
        null
    );
}

export default memo(DepositActionsCellRenderer);
