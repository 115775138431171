import deDE from './de-DE.json';
import enGB from './en-GB.json';
import enUS from './en-US.json';
import esES from './es-ES.json';
import frFR from './fr-FR.json';
import nl from './nl.json';

enum Lang {
    de = 'de',
    deDE = 'de-DE',
    en = 'en',
    enGB = 'en-GB',
    enUS = 'en-US',
    esES = 'es-ES',
    frFR = 'fr-FR',
    nl = 'nl'
}

const resources = {
    [Lang.de]: { translation: deDE },
    [Lang.deDE]: { translation: deDE },
    [Lang.en]: { translation: enGB },
    [Lang.enGB]: { translation: enGB },
    [Lang.enUS]: { translation: enUS },
    [Lang.esES]: { translation: esES },
    [Lang.frFR]: { translation: frFR },
    [Lang.nl]: { translation: nl }
};

const LANGUAGES: { lang: Lang; name: string }[] = [
    { lang: Lang.deDE, name: 'Deutsch (Deutschland)' },
    { lang: Lang.nl, name: 'Dutch (Nederland)' },
    { lang: Lang.enGB, name: 'English (United Kingdom)' },
    { lang: Lang.enUS, name: 'English (United States)' },
    { lang: Lang.esES, name: 'Español (España)' },
    { lang: Lang.frFR, name: 'Français (France)' }
];

export default resources;
export { LANGUAGES, Lang };
