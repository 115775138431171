import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoltLightning } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import React from 'react';

const Notifications = () => {
    const { t } = useTranslation();
    return (
        <div className="w-full h-full bg-gradient-to-r from-escode-overlay-texture-light to-escode-overlay-texture-dark">
            <div className="coming-soon w-full h-full flex flex-col gap-8 items-center justify-center text-general-grey-grey-10">
                <FontAwesomeIcon icon={faBoltLightning} className="text-9xl" />
                <div className="flex flex-col gap-9 items-center">
                    <div className="text-5xl text-general-white-white-100 font-semibold">
                        {t('coming-soon.title')}
                    </div>
                    <div className="text-xl text-center font-semibold">
                        {t('coming-soon.description')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Notifications;
