import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { useFormContext, useWatch } from 'react-hook-form';
import { useId } from 'react';
import Field, { FieldProps } from './field';
import React, { useEffect, useRef, useState } from 'react';

import cn from 'core/utils/cn';
import getNestedError from 'core/utils/get-nested-error';

interface Option {
    label: string | React.ReactNode;
    value: string;
}

interface ChoiceFieldProps extends FieldProps {
    className?: string;
    disabled?: boolean;
    label: string;
    name: string;
    options: Option[];
    orientation?: 'vertical' | 'horizontal';
    placeholder?: string;
    readOnly?: boolean;
    required?: boolean;
    suppressReq?: boolean;
    tooltip?: string;
    tooltipDisabled?: boolean;
}

export default function ChoiceField({
    className,
    disabled = false,
    label,
    name,
    options,
    orientation = 'vertical',
    placeholder = 'Select an option',
    readOnly = false,
    required = false,
    suppressReq = false,
    tooltip,
    tooltipDisabled = false,
    tooltipPlacement = 'right'
}: ChoiceFieldProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [openAbove, setOpenAbove] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const id = useId();

    const {
        control,
        formState: { errors },
        register
    } = useFormContext();
    const formValue = useWatch({ control, name });

    const fieldError = getNestedError(name, errors);

    const selectedOption =
        options.find((option) => option.value === formValue) || null;

    const toggleDropdown = () => {
        if (!isOpen) {
            const buttonRect = buttonRef.current?.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const spaceBelow = windowHeight - (buttonRect?.bottom || 0);
            const spaceNeeded = Math.min(options.length * 40, 240); // Assuming each option is 40px high, max 240px

            setOpenAbove(spaceBelow < spaceNeeded);
        }
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option: Option) => {
        const event = {
            target: {
                name,
                value: option.value
            }
        } as React.ChangeEvent<HTMLSelectElement>;
        register(name).onChange(event);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Field
            as="label"
            className={className}
            name={name}
            label={label}
            orientation={orientation}
            readOnly={readOnly}
            disabled={disabled}
            id={id}
            required={required}
            suppressReq={suppressReq}
            tooltip={tooltip}
            tooltipDisabled={tooltipDisabled}
            tooltipPlacement={tooltipPlacement}
            error={fieldError ? <>{fieldError}</> : undefined}
        >
            <div className="relative w-full" ref={dropdownRef}>
                <button
                    type="button"
                    ref={buttonRef}
                    className={cn(
                        'relative w-full bg-white border border-general-grey-grey-40 rounded-lg shadow-sm pl-3 pr-10 py-2 text-left text-general-grey-grey-100 focus:outline-none focus:ring-none sm:text-sm h-[40px]',
                        {
                            'border-general-red-red-100': !!fieldError,
                            'cursor-default': readOnly,
                            'cursor-not-allowed bg-gray-100': disabled,
                            'focus:border-brand-escode-neonblue-neonblue-100 focus-within:border-brand-escode-neonblue-neonblue-100':
                                !fieldError,
                            'text-general-grey-grey-100': !selectedOption
                        }
                    )}
                    onClick={toggleDropdown}
                    disabled={disabled || readOnly}
                    aria-haspopup="listbox"
                    aria-expanded={isOpen}
                    id={id}
                >
                    <span className="block truncate">
                        {selectedOption ? (
                            selectedOption.label
                        ) : (
                            <span className="text-general-grey-grey-50">
                                {placeholder}
                            </span>
                        )}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <FontAwesomeIcon
                            icon={openAbove ? faCaretUp : faCaretDown}
                            className="mr-1 text-general-grey-grey-90"
                            aria-hidden="true"
                        />
                    </span>
                </button>

                {isOpen && !disabled && !readOnly && (
                    <div
                        className={cn(
                            'absolute z-10 w-full shadow-lg shadow- bg-white max-h-60 p-3 text-base overflow-auto focus:outline-none sm:text-sm scrollbar-hide border space-y-1  border-general-grey-grey-40 rounded-lg',
                            {
                                'bottom-[calc(100%+0rem)]': openAbove,
                                'top-[calc(100%+0rem)]': !openAbove
                            }
                        )}
                    >
                        {options.map((option) => (
                            <div
                                key={option.value}
                                className={cn(
                                    'cursor-pointer select-none relative py-2 pl-3 pr-9 rounded-lg hover:bg-general-grey-grey-10',
                                    {
                                        'bg-blue-100 hover:bg-blue-100':
                                            option.value === formValue
                                    }
                                )}
                                onClick={() => handleOptionClick(option)}
                                role="option"
                                aria-selected={option.value === formValue}
                            >
                                <span className="block truncate text-sm">
                                    {option.label}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <select
                {...register(name)}
                value={formValue || ''}
                className="sr-only"
                aria-hidden="true"
            >
                <option value="">{placeholder}</option>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </Field>
    );
}
