import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import axios, { AxiosResponse } from 'axios';

import { ProblemDetails } from '../../autogenerated/data-contracts';
import { useAuth } from 'react-oidc-context';
import useDepositNowStore from 'core/stores/use-deposit-now-store';

function useRefreshToken(options?: Omit<UseQueryOptions, 'queryFn'>) {
    const queryClient = useQueryClient();
    const auth = useAuth();
    const { VVToken, depositUniqueReference, setVVToken, spectreControlUrl } =
        useDepositNowStore();

    return useQuery(
        [
            'spectreControlRefresh',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled; useSpectreControlRefresh call ignored.'
                );
                return Promise.resolve({} as AxiosResponse<void | unknown>);
            }
            const baseURL = spectreControlUrl;

            const refreshVVToken = (params = {}) => {
                const axiosInstance = axios.create({
                    baseURL,
                    headers: {
                        Authorization: `Bearer ${VVToken}`,
                        'Cache-FormControl':
                            'no-cache, no-store, must-revalidate',
                        Expires: '0',
                        Pragma: 'no-cache'
                    }
                    // withCredentials: true
                });

                return axiosInstance.post<void | ProblemDetails>(
                    `/api/auth/refresh`,

                    { depositId: depositUniqueReference },
                    {
                        withCredentials: true
                    }
                );
            };
            const token = (await refreshVVToken()).data!.token || null;
            if (token) {
                // as the token needs to be refreshed in so many places, better to store it in a global store
                setVVToken(token);
            }
            return token;
        },
        {
            enabled: false,
            onError: () => {
                queryClient.invalidateQueries('use-request-token');
            }
        }
    );
}

export default useRefreshToken;
