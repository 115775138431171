import { CommonProps } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler, useMemo } from 'react';
import {
    faArrowLeftFromLine,
    faAt,
    faCalendarLines,
    faCheckCircle,
    faCodeBranch,
    faCog,
    faFileContract,
    faGrid2,
    faInfoSquare,
    faShield,
    faUserHeadset
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ReactComponent as AppFavLogo } from 'fav-logo.svg';

import { Divider } from 'lib-ui';
import AppSider from 'app-layout/app-sider';
import SiderLink from './sider-link';
import SiderSection, { SiderSectionProps } from './sider-section';
// import ThemeSwitch from './theme-switch'; - hidden for MVP

interface MainSiderProps extends CommonProps {
    collapsed?: boolean;
    onToggleClick: MouseEventHandler<HTMLButtonElement>;
}

function MainSider({ className, collapsed, onToggleClick }: MainSiderProps) {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();

    const cssClasses = classNames(className, {
        'w-sider': !collapsed,
        'w-sider-collapsed': collapsed
    });
    const iconContainerCssClasses = classNames(
        'relative h-escode-header py-4 flex items-center bg-brand-escode-neonblue-neonblue-100 px-5.5'
    );
    const padding = 'py-3 px-3.5';

    const sections = useMemo<Omit<SiderSectionProps, 'collapsed'>[]>(
        () => [
            {
                links: [
                    {
                        icon: <FontAwesomeIcon icon={faGrid2} />,
                        text: t('links.home'),
                        to: '/'
                    }
                ]
            },
            {
                links: [
                    {
                        icon: <FontAwesomeIcon icon={faCheckCircle} />,
                        text: t('links.agreements'),
                        to: '/agreements'
                    }
                ]
            },
            {
                links: [
                    {
                        icon: (
                            <FontAwesomeIcon
                                icon={faCodeBranch}
                                flip="vertical"
                            />
                        ),
                        text: t('links.source-control'),
                        to: '/source-control'
                    },
                    {
                        icon: <FontAwesomeIcon icon={faCalendarLines} />,
                        text: t('links.schedules'),
                        to: '/schedules'
                    }
                ]
            },
            {
                links: [
                    {
                        icon: <FontAwesomeIcon icon={faAt} />,
                        text: t('links.contacts'),
                        to: '/contacts'
                    }
                ]
            },
            {
                links: [
                    {
                        icon: <FontAwesomeIcon icon={faInfoSquare} />,
                        text: t('links.help'),
                        to: '/help'
                    },
                    {
                        icon: <FontAwesomeIcon icon={faUserHeadset} />,
                        text: t('links.support'),
                        to: '/support'
                    },
                    {
                        icon: <FontAwesomeIcon icon={faFileContract} />,
                        text: t('links.terms-and-conditions'),
                        to: '/terms-and-conditions'
                    },
                    {
                        externalLink: true,
                        icon: <FontAwesomeIcon icon={faShield} />,
                        text: t('privacy-policy'),
                        to: 'https://www.escode.com/privacy-policy/'
                    }
                ]
            }
        ],
        [t]
    );

    const footerSections = useMemo<Omit<SiderSectionProps, 'collapsed'>[]>(
        () => [
            {
                links: [
                    {
                        icon: <FontAwesomeIcon icon={faCog} />,
                        text: t('links.settings'),
                        to: '/settings/system'
                    }
                ]
            }
        ],
        [t]
    );

    return (
        <AppSider className={cssClasses}>
            <div className={iconContainerCssClasses}>
                {collapsed ? (
                    <AppFavLogo
                        data-testid="collapsed-logo"
                        className="h-escode-header-button"
                    />
                ) : (
                    <>
                        <AppFavLogo data-testid="collapsed-logo" className="" />
                        <span className="border-l border-general-white-white-100 ml-[16px] pl-[16px] text-[18px] text-general-white-white-100">
                            Escode View
                        </span>
                        <div className="absolute right-1 bottom-0 truncate text-2xs font-medium text-general-white-white-100/80">
                            {process.env.REACT_APP_VERSION}
                        </div>
                    </>
                )}
            </div>
            <div className={padding}>
                {sections.map((sectionProps, i) => (
                    <SiderSection
                        {...sectionProps}
                        key={i}
                        collapsed={collapsed}
                    />
                ))}
            </div>
            <div
                data-testid="footer"
                className={`mt-auto ${padding} transition-all`}
            >
                {footerSections.map((sectionProps, i) => (
                    <SiderSection
                        {...sectionProps}
                        key={i}
                        collapsed={collapsed}
                    />
                ))}
                <Divider />
                <div
                    className={classNames(
                        'flex items-center text-escode-white',
                        { 'flex-col-reverse': collapsed }
                    )}
                >
                    <SiderLink
                        text={t('links.toggle-sidebar')}
                        data-testid="toggle"
                        onClick={onToggleClick}
                        icon={
                            <FontAwesomeIcon
                                className="transition-all"
                                icon={faArrowLeftFromLine}
                                flip={collapsed ? 'horizontal' : undefined}
                            />
                        }
                        suppressHover
                        withoutText
                    />
                    {/* <ThemeSwitch collapsed={collapsed} /> hidden for MVP */}
                </div>
                <div
                    className={classNames(
                        'truncate text-2xs text-general-white-white-100/80'
                    )}
                    data-testid="main-sider-copyright"
                >
                    {collapsed ? (
                        <>&copy; NCC Group</>
                    ) : (
                        <>
                            Copyright &copy; {currentYear} NCC Group. All rights
                            reserved.
                        </>
                    )}
                </div>
            </div>
        </AppSider>
    );
}

export default MainSider;
export type { MainSiderProps };
