import AppBody from './app-body';
import Notifications from 'ui/settings/notifications/notifications';
import SettingsWidget from 'ui/settings/settings-widget/settings-widget';

function SettingsNotificationsBody() {
    return (
        <AppBody>
            <SettingsWidget>
                <Notifications />
            </SettingsWidget>
        </AppBody>
    );
}

export default SettingsNotificationsBody;
