import React from 'react';

export default function ExternalTermsAndConditions() {
    return (
        <div className="">
            <div className="max-w-4xl max-h-[60rem] overflow-auto mx-auto p-6 bg-white">
                <h1 className="text-2xl font-bold text-center mb-6">
                    NCC Group's Online Depositing Terms and Conditions
                </h1>

                <div className="space-y-6 text-sm">
                    <p>
                        The use of NCC Services Limited's ("NCC Group") secure
                        online application ("View") and/or the use of any Secure
                        File Transfer Protocol ("SFTP") used to submit code,
                        documents and/or information to NCC Group are subject to
                        the following terms and conditions which you should read
                        carefully prior to using View or SFTP.
                    </p>

                    <section>
                        <h2 className="text-lg font-semibold mb-2">
                            1 USE OF VIEW
                        </h2>
                        <ol className="list-decimal list-inside space-y-2">
                            <li>
                                View is a secure online application through
                                which clients of NCC Group can request
                                information regarding their account and
                                agreements and electronically deposit escrow
                                material.
                            </li>
                            <li>
                                By accessing or using View or SFTP, you agree to
                                be bound by these terms. If you are using View
                                or SFTP on behalf of a company, organisation, or
                                other legal entity, then you:
                                <ul className="list-disc list-inside ml-4 mt-1">
                                    <li>
                                        are accepting them on behalf of yourself
                                        and that entity;
                                    </li>
                                    <li>
                                        represent and warrant that you are an
                                        authorised representative of the entity;
                                        and
                                    </li>
                                    <li>
                                        have the requisite authority to bind the
                                        entity to these terms.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                You agree to promptly inform your account
                                manager at NCC Group of any changes in your role
                                or responsibilities which mean you are no longer
                                authorised to use View.
                            </li>
                            <li>
                                NCC Group will respond to all requests submitted
                                through View as soon as reasonably practicable.
                            </li>
                            <li>
                                The material you deposit will be held on NCC
                                Group's online deposit storage system and then
                                transferred to one of NCC Group's secure
                                electronic or physical vaults.
                            </li>
                        </ol>
                    </section>

                    <section>
                        <h2 className="text-lg font-semibold mb-2">
                            2 USE OF SFTP
                        </h2>
                        <ol className="list-decimal list-inside space-y-2">
                            <li>
                                Clients of NCC Group can deposit and receive
                                escrow material electronically via SFTP.
                            </li>
                            <li>
                                The material you deposit will be transferred to
                                one of NCC Group's secure electronic or physical
                                vaults.
                            </li>
                        </ol>
                    </section>

                    <section>
                        <h2 className="text-lg font-semibold mb-2">
                            3 WARRANTIES AND DEPOSIT OF MATERIAL
                        </h2>
                        <ol className="list-decimal list-inside space-y-2">
                            <li>
                                You warrant that:
                                <ul className="list-disc list-inside ml-4 mt-1">
                                    <li>
                                        the information you provide to NCC Group
                                        via View and SFTP is correct and
                                        accurate to the best of your knowledge;
                                    </li>
                                    <li>
                                        you have the full right, ability and
                                        authority to deposit the material;
                                    </li>
                                    <li>
                                        your use of View and transfer of
                                        material via SFTP complies with all
                                        applicable laws and regulations which
                                        govern the use of online facilities and
                                        import, export or transmission of
                                        software and any other electronic data;
                                    </li>
                                    <li>
                                        the deposit of the material via View or
                                        SFTP will not breach any personal data
                                        or import, re-import, export or
                                        re-export controls, laws or regulations;
                                        and
                                    </li>
                                    <li>
                                        the material you deposit is virus free.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                You should ensure that:
                                <ul className="list-disc list-inside ml-4 mt-1">
                                    <li>
                                        every deposit is accompanied by a
                                        completed deposit form;
                                    </li>
                                    <li>
                                        you only make deposits in compressed
                                        archive file format;
                                    </li>
                                    <li>
                                        each electronic compressed file is not
                                        be larger than 4GGB (and that if it is,
                                        split it so that each electronic
                                        compressed file is less than 4GGB); and
                                    </li>
                                    <li>
                                        the total sum of each deposit will not
                                        be larger than 100GB (save as agreed in
                                        advance of the deposit with NCC Group).
                                    </li>
                                </ul>
                            </li>
                            <li>
                                You may be required to pay NCC Group's
                                additional fees for any deposit that exceeds the
                                limits at clause 3.2.
                            </li>
                            <li>
                                You acknowledge that NCC Group is not
                                responsible for any inaccuracies or errors in
                                the material that is deposited using SFTP or
                                View.
                            </li>
                        </ol>
                    </section>

                    <section>
                        <h2 className="text-lg font-semibold mb-2">
                            4 PERSONAL DATA
                        </h2>
                        <ol className="list-decimal list-inside space-y-2">
                            <li>
                                NCC Group will handle personal data in
                                accordance with the Privacy Policy.
                            </li>
                            <li>
                                By inputting or depositing any personal data via
                                View or SFTP, you warrant and represent that
                                you:
                                <ul className="list-disc list-inside ml-4 mt-1">
                                    <li>
                                        will only input personal data to the
                                        extent that it is necessary for the
                                        purposes of the relevant escrow
                                        agreement and for your use of View or
                                        SFTP and;
                                    </li>
                                    <li>
                                        have all requisite authority and have
                                        obtained and will maintain (including by
                                        way of incorporating into all materials
                                        and processes through which personal
                                        data is captured) all necessary consents
                                        required, or otherwise have a valid
                                        legal basis for such disclosure and use
                                        of applicable data protection and
                                        privacy legislation in force from time
                                        to time in the UK including without
                                        limitation the General Data Protection
                                        Regulation ((EU) 2016/679), the UK GDPR
                                        (having the meaning given to it in
                                        section 3(10) (as supplemented by
                                        section 205(4)) of the Data Protection
                                        Act 2018), the Privacy and Electronic
                                        Communications Directive (2002/58/EC)
                                        (as updated by Directive 2009/136/EC)
                                        and the Privacy and Electronic
                                        Communications Regulations 2003 (SI
                                        2003/2426) as amended ("Data Protection
                                        Legislation");
                                    </li>
                                    <li>
                                        have fully complied with and will comply
                                        with all of your obligations under the
                                        Data Protection Legislation; and
                                    </li>
                                    <li>
                                        will clearly instruct NCC Group in
                                        relation to the personal data and enter
                                        into a data processing agreement where
                                        necessary.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </section>

                    <section>
                        <h2 className="text-lg font-semibold mb-2">
                            5 SECURITY
                        </h2>
                        <ol className="list-decimal list-inside space-y-2">
                            <li>
                                You must protect and keep secret the security
                                details issued to you to access View and SFTP
                                and must not record your security details in any
                                way which might lead to their discovery and/or
                                use by any other person.
                            </li>
                            <li>
                                You must take reasonable precautions in order to
                                prevent the unauthorised use of any device that
                                is logged into View for the whole time that the
                                device is logged into View.
                            </li>
                            <li>
                                If you suspect that an unauthorised person has
                                gained access to and/or used your security
                                details, you must inform NCC Group immediately.
                            </li>
                            <li>
                                You acknowledge that you are solely responsible
                                for:
                                <ul className="list-disc list-inside ml-4 mt-1">
                                    <li>
                                        all information that is transmitted to
                                        View or via SFTP using your Security
                                        Details; and
                                    </li>
                                    <li>
                                        all actions in relation to View of any
                                        unauthorised Person who is logged into
                                        View using your security details
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </section>

                    <section>
                        <h2 className="text-lg font-semibold mb-2">
                            6 CONFIDENTIALITY
                        </h2>
                        <p>
                            The confidentiality provisions of the escrow
                            agreement in place between NCC Group and you shall
                            apply to the material deposited using View and
                            transferred to NCC Group via SFTP.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-lg font-semibold mb-2">
                            7 LIMITATION OF LIABILITY
                        </h2>
                        <p>
                            Please read the limitation of liability provisions
                            carefully as they limit the liability of NCC Group
                            in relation to your use of View and the transfer of
                            material via SFTP.
                        </p>
                        <ol className="list-decimal list-inside space-y-2 mt-2">
                            <li>
                                Nothing in these terms and conditions shall
                                exclude or limit any liability that it is not
                                permissible to exclude or limit. In particular,
                                but without limitation, nothing in these terms
                                and conditions shall limit NCC Group's liability
                                for death or personal injury caused by its
                                negligence or fraudulent misrepresentation.
                            </li>
                            <li>
                                Whilst NCC Group has taken reasonable steps to
                                ensure the security and correct functioning of
                                View, no warranties or representations are given
                                (either express or implied) that View is secure
                                or will function correctly or that View will be
                                available at all times.
                            </li>
                            <li>
                                NCC Group shall not be liable for loss or damage
                                suffered by you through the use of View and SFTP
                                except to the extent that such loss or damage is
                                directly caused by NCC Group's negligence or
                                breach of contractual or statutory duty. In the
                                event of a claim or claims against NCC Group by
                                you for loss or damage, NCC Group shall only be
                                liable in the aggregate amount of £50,000 for
                                all claims. NCC Group shall not be liable for
                                any indirect or consequential loss or damage
                                (including, without limitation, loss of profit,
                                loss of business or depletion of goodwill) in
                                connection with the use of View and SFTP even if
                                such loss or damage was reasonably foreseeable.
                            </li>
                            <li>
                                You agree to indemnify and hold harmless NCC
                                Group from any losses, damages, fines, costs and
                                expenses that NCC Group incurs as a result of
                                your breach of any applicable laws or
                                regulations.
                            </li>
                            <li>
                                The parties acknowledge that this clause 7
                                covers the use of View or SFTP to provide code,
                                documents and/or information to NCC Group. Once
                                such material has been received by NCC Group,
                                NCC Group's liability for the material will be
                                covered by the liability in the relevant escrow
                                agreement entered into between you and NCC Group
                                or one of its affiliates.
                            </li>
                        </ol>
                    </section>

                    <section>
                        <h2 className="text-lg font-semibold mb-2">
                            8 CHANGES TO VIEW, SFTP AND THESE TERMS AND
                            CONDITIONS
                        </h2>
                        <ol className="list-decimal list-inside space-y-2">
                            <li>
                                NCC Group reserves the right to refuse access to
                                View for any reason.
                            </li>
                            <li>
                                The functions and availability of View and SFTP
                                are subject to change, suspension (for any
                                reason including, without limitation,
                                maintenance and/or upgrades) and termination by
                                NCC Group without notification.
                            </li>
                            <li>
                                These terms and conditions are subject to change
                                by NCC Group without notification. Therefore you
                                should ensure that you read the terms and
                                conditions on this page prior to each use of
                                View or transfer of material via SFTP.
                            </li>
                        </ol>
                    </section>

                    <section>
                        <h2 className="text-lg font-semibold mb-2">
                            9 INTERNET SAFETY AND SECURITY
                        </h2>
                        <p>
                            In order to maintain the security of its systems,
                            protect its staff and detect fraud and other crimes,
                            NCC Group reserves the right to monitor your use of
                            View without notifying you. Monitoring includes
                            (without limitation) checking for viruses and other
                            malignant code, criminal activity, the deposit of
                            illegal, seditious, pornographic, racist or other
                            undesirable information or content which is
                            unauthorised or which NCC Group considers as being
                            in breach of generally accepted and reasonable
                            information security policies.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-lg font-semibold mb-2">
                            10 ENFORCEABILITY
                        </h2>
                        <p>
                            In the event that any provision of these terms and
                            conditions is held to be unenforceable, it will not
                            affect the validity and enforceability of the
                            remaining provisions. The unenforceable term will be
                            treated as being replaced by an enforceable
                            provision that is the closest to the intended
                            purpose of the unenforceable provision.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-lg font-semibold mb-2">
                            11 GOVERNING LAW
                        </h2>
                        <p>
                            These terms of use, its subject matter and its
                            formation are governed by English law and any
                            disputes regarding the use of View or these terms
                            and conditions shall be subject to the exclusive
                            jurisdiction of the courts of England and Wales.
                        </p>
                    </section>
                </div>
            </div>
        </div>
    );
}
