import { create } from 'zustand';

interface StoreState {
    VVToken: string;
    depositUniqueReference: string;
    isUploadingFile: boolean;
    setDepositUniqueReference: (depositUniqueReference: string) => void;
    setIsUploadingFile: (isUploadingFile: boolean) => void;
    setShouldClaimToken: (shouldClaimToken: boolean) => void;
    setSpectreAgentUrl: (spectreAgentUrl: string) => void;
    setSpectrecontrolUrl: (spectreControlUrl: string) => void;
    setVVToken: (VVToken: string) => void;
    shouldClaimToken?: boolean;
    spectreAgentUrl?: string;
    spectreControlUrl?: string;
}

const useDepositNowStore = create<StoreState>()((set) => ({
    VVToken: '',
    depositUniqueReference: '',
    isUploadingFile: false,
    setDepositUniqueReference: (depositUniqueReference: string) =>
        set({ depositUniqueReference }),
    setIsUploadingFile: (isUploadingFile: boolean) => set({ isUploadingFile }),
    setShouldClaimToken: (shouldClaimToken: boolean) =>
        set({ shouldClaimToken }),
    setSpectreAgentUrl: (spectreAgentUrl: string) => set({ spectreAgentUrl }),
    setSpectrecontrolUrl: (spectreControlUrl: string) =>
        set({ spectreControlUrl }),
    setVVToken: (VVToken: string) => set({ VVToken }),
    shouldClaimToken: false
}));

export default useDepositNowStore;
