import { Asterisk, ConfirmationTooltip, Select } from 'lib-ui';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDiamondExclamation,
    faSpinner
} from '@fortawesome/pro-solid-svg-icons';
import { isEmpty, isNull } from 'lodash';
import { useAuth } from 'react-oidc-context';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import Button from 'lib-ui/button/button';
import useAgreementsDepositsDataCentreUpdate from 'core/api/client-portal/hooks/agreements/use-agreements-deposits-data-centre-update';
import useAgreementsGetDataCentre from 'core/api/client-portal/hooks/agreements/use-agreements-get-data-centre';
import useGetDepositDetails from 'core/api/client-portal-v2/hooks/deposits/use-get-deposit-details';

type DataCentreProps = {
    agreementId: string;
    currentDataCentre?: string;
    depositId: number;
};

const DataCentre = ({
    agreementId,
    currentDataCentre,
    depositId
}: DataCentreProps) => {
    const auth = useAuth();
    const queryClient = useQueryClient();

    const { t } = useTranslation();
    const [value, setValue] = useState<string | null>(null);

    // Are there any data centres previously selected?
    const { data: depositData, isLoading: isDepositsLoading } =
        useGetDepositDetails(depositId, !!currentDataCentre);

    // get all Data Centres
    const { data: dataCentres, isLoading: isDataCentreLoading } =
        useAgreementsGetDataCentre(agreementId, {
            enabled: isEmpty(currentDataCentre)
        });

    // function to pick one Data Centre
    const { mutate: mutationFn } = useAgreementsDepositsDataCentreUpdate({
        onSettled() {
            queryClient.invalidateQueries(['use-deposit-details-v2']);
            queryClient.invalidateQueries([
                'spectreToken',
                {
                    token: auth.user?.access_token,
                    tokenType: auth.user?.token_type
                }
            ]);
        }
    });

    const defaultDataCentre = useMemo(() => {
        const priorityDataCentre = dataCentres?.items?.find((dataCentre) => {
            return dataCentre.priorityOrder === 1;
        });

        return {
            label: priorityDataCentre?.platform || '',
            value: priorityDataCentre?.status || ''
        };
    }, [dataCentres]);

    const dataCentreSelected = useMemo(() => {
        return {
            label: depositData?.dataCentre?.split(':')[0] || '',
            value: depositData?.dataCentre?.split(':')[1] || ''
        };
    }, [depositData]);

    const handleOnClick = useCallback((): void => {
        if (!value && !defaultDataCentre.label) return;

        const parsedValue = value?.split(':');
        const platform = parsedValue?.[0] ?? defaultDataCentre.label;
        const region = parsedValue?.[1] ?? defaultDataCentre.label;

        mutationFn({
            data: {
                agreementId: agreementId.toString(),
                depositDataCentre: {
                    isOnPrem: false,
                    platform,
                    region
                },
                depositId
            }
        });
    }, [agreementId, defaultDataCentre.label, depositId, mutationFn, value]);

    const handlePlaceholder = useMemo(() => {
        if (value) {
            const parsedValue = value.split(':')[1];
            return parsedValue;
        }

        if (dataCentreSelected.value) {
            return dataCentreSelected.value;
        }

        return defaultDataCentre.label;
    }, [value, dataCentreSelected.value, defaultDataCentre.label]);

    const handleOnChange = useCallback(
        (event: ChangeEvent<{ value: string }>): void => {
            const inputValue = event.target.value;
            setValue(inputValue);
        },
        []
    );

    const optionsArray = useMemo(() => {
        return (
            dataCentres?.items?.map((item) => ({
                label: `${item.region}` || '',
                value: `${item.platform}:${item.region}` || ''
            })) || []
        );
    }, [dataCentres]);

    if (isDataCentreLoading || isDepositsLoading) {
        return (
            <div className="flex justify-center items-center">
                <FontAwesomeIcon
                    className="h-4 w-4 mx-auto"
                    icon={faSpinner}
                    spin
                />
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-2">
            <label className="text-sm font-semibold text-general-grey-grey-100 flex gap-1">
                {isEmpty(currentDataCentre) ? (
                    <>
                        {t('upload-step.data-centre-not-selected')}
                        <Asterisk className="text-general-red-red-80" />
                    </>
                ) : (
                    t('data-centre.data-centre')
                )}
            </label>
            <div className="flex w-72 flex-row gap-2">
                <Select
                    placeholder={handlePlaceholder}
                    disabled={!isEmpty(currentDataCentre)}
                    options={optionsArray}
                    onChange={handleOnChange}
                    disablePlaceholderOpacity={!isNull(currentDataCentre)}
                ></Select>
                {isEmpty(currentDataCentre) && (
                    <ConfirmationTooltip
                        placement="bottom"
                        icon={<FontAwesomeIcon icon={faDiamondExclamation} />}
                        title={t('data-centre.confirmation')}
                        message={t('data-centre.confirm-tooltip-message')}
                        onConfirm={handleOnClick}
                        variant="warning"
                        confirmMessage={t('tooltip.proceed')}
                        rejectMessage={t('tooltip.reject')}
                        className="relative"
                    >
                        <Button
                            variant="tertiary"
                            type="button"
                            size="s"
                            className="w-full ml-2"
                        >
                            {t('data-centre.confirm')}
                        </Button>
                    </ConfirmationTooltip>
                )}
            </div>
        </div>
    );
};

export default DataCentre;
