/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider, useForm } from 'react-hook-form';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';

import { Divider, InputField, SelectField } from 'lib-ui';
import SettingsContentSection from '../settings-content-section';
import toastFunctions from 'lib-ui/toast/Toast';
import useUpdateUserDetails from 'core/api/client-portal/hooks/user/use-update-user-details';
import useUserDetails from 'core/api/client-portal/hooks/user/use-user-details';

const requiredMessage = 'This field is required';
const schema = yup.object({
    accountManagerFullName: yup.string().required(requiredMessage),
    addressLine1: yup.string().required(requiredMessage),
    addressLine2: yup.string().required(requiredMessage),
    addressLine3: yup.string().required(requiredMessage),
    emailAddress: yup.string().required(requiredMessage),
    firstName: yup.string().required(requiredMessage),
    jobTitle: yup.string().required(requiredMessage),
    lastName: yup.string().required(requiredMessage),
    postcode: yup.string().required(requiredMessage),
    telephoneNumber: yup.string().required(requiredMessage),
    title: yup.string().required(requiredMessage),
    uiCulture: yup.string(),
    userName: yup.string().required(requiredMessage)
});

const Profile = () => {
    const { user } = useAuth();
    const { data: userDetails } = useUserDetails(user || null);
    const { mutate: updateUser } = useUpdateUserDetails({
        onError: () => {
            toastFunctions.error(t('settings.personal.toast.error'));
        },
        onSuccess: () => {
            toastFunctions.success(t('settings.personal.toast.success'));
        }
    });
    const { t } = useTranslation();

    const methods = useForm({
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
        values: {
            accountManagerFullName:
                userDetails?.accountManagerFullName || 'Pending',
            addressLine1: userDetails?.addressLine1,
            addressLine2: userDetails?.addressLine2,
            addressLine3: userDetails?.addressLine3,
            emailAddress: userDetails?.emailAddress,
            firstName: userDetails?.firstName,
            jobTitle: userDetails?.jobTitle,
            lastName: userDetails?.lastName,
            postcode: userDetails?.postcode,
            telephoneNumber: userDetails?.telephoneNumber,
            title: userDetails?.title,
            uiCulture: userDetails?.uiCulture,
            userName: userDetails?.userName
        }
    });

    const submit = (data: any) => {
        updateUser(data);
    };

    return (
        <FormProvider {...methods}>
            <form
                className="h-full w-full pb-6 border-l border-general-grey-grey-30 "
                onSubmit={methods.handleSubmit(submit)}
            >
                <div className="flex gap-4 items-center justify-between h-[60px] px-7.5 border-b-2 border-general-grey-grey-30">
                    <div className="flex gap-4 items-center">
                        <FontAwesomeIcon icon={faUser} className=" h-6 w-6" />
                        <h2 className="text-xl font-medium">
                            {t('settings.personal.title')}
                        </h2>
                    </div>
                    {/* <Button variant="primary">
                        {t('settings.personal.action-button')}
                    </Button> HIDDEN UNTIL BACKEND IS CONNECTED TO SALESFORCE */}
                </div>
                <div className="p-7.5 h-full overflow-y-scroll space-y-11">
                    <SettingsContentSection
                        description={t('settings.personal.description-one')}
                        subtitle={t('settings.personal.subtitle-one')}
                    >
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-8">
                                <div className="flex flex-1 gap-2">
                                    <SelectField
                                        className="w-24"
                                        disabled
                                        name="title"
                                        label={t(
                                            'settings.personal.form.title'
                                        )}
                                        placeholder="Title"
                                        options={[
                                            { label: 'Mr', value: 'Mr' },
                                            { label: 'Mrs', value: 'Mrs' },
                                            {
                                                label: 'Miss',
                                                value: 'Miss'
                                            },
                                            { label: 'Ms', value: 'Ms' },
                                            { label: 'Dr', value: 'Dr' }
                                        ]}
                                    />
                                    <InputField
                                        className="flex-1"
                                        disabled
                                        name="firstName"
                                        label={t(
                                            'settings.personal.form.first-name'
                                        )}
                                        placeholder="Adam"
                                    />
                                    <InputField
                                        className="flex-1"
                                        disabled
                                        name="lastName"
                                        label={t(
                                            'settings.personal.form.last-name'
                                        )}
                                        placeholder="Smith"
                                    />
                                </div>
                                <div className="flex flex-1 gap-2">
                                    <InputField
                                        className="flex-1"
                                        disabled
                                        name="userName"
                                        label={t(
                                            'settings.personal.form.username'
                                        )}
                                        placeholder="adam.smith@example.com"
                                    />
                                    <InputField
                                        className="flex-1"
                                        disabled
                                        name="emailAddress"
                                        label={t(
                                            'settings.personal.form.email'
                                        )}
                                        placeholder="adam.smith@example.com"
                                    />
                                </div>
                            </div>
                            <div className="flex space-x-4">
                                <InputField
                                    className="flex-1"
                                    disabled
                                    name="jobTitle"
                                    label={t(
                                        'settings.personal.form.job-title'
                                    )}
                                    placeholder="Data Analyst"
                                />
                                <InputField
                                    className="flex-1"
                                    disabled
                                    name="telephoneNumber"
                                    label={t(
                                        'settings.personal.form.phone-number'
                                    )}
                                    placeholder="0712 3456789"
                                />
                            </div>
                            <div className="flex flex-col gap-8">
                                <InputField
                                    className="flex-1"
                                    disabled
                                    name="addressLine1"
                                    label={t(
                                        'settings.personal.form.address-one'
                                    )}
                                    placeholder="1 Place Street"
                                />
                                <InputField
                                    className="flex-1"
                                    disabled
                                    name="addressLine2"
                                    label={t(
                                        'settings.personal.form.address-two'
                                    )}
                                    placeholder="Enter your details"
                                />
                                <div className="flex flex-1 gap-2">
                                    <InputField
                                        className="flex-1"
                                        disabled
                                        name="addressLine3"
                                        label={t(
                                            'settings.personal.form.address-three'
                                        )}
                                        placeholder="Enter your details"
                                    />
                                    <InputField
                                        className="flex-1"
                                        disabled
                                        name="postcode"
                                        label={t(
                                            'settings.personal.form.post-code'
                                        )}
                                        placeholder="M3 3AQ"
                                    />
                                </div>
                            </div>
                        </div>
                    </SettingsContentSection>
                    <Divider color="bg-general-grey-grey-30" />
                    <SettingsContentSection
                        description={t('settings.personal.description-two')}
                        subtitle={t('settings.personal.subtitle-two')}
                    >
                        <div className="flex flex-1 gap-2">
                            <InputField
                                className="flex-1"
                                disabled
                                name="uiCulture"
                                label={t('settings.personal.form.ui-culture')}
                                placeholder="en-GB"
                            />
                            <InputField
                                className="flex-1"
                                disabled
                                name="accountManagerFullName"
                                label={t(
                                    'settings.personal.form.account-manager'
                                )}
                            />
                        </div>
                    </SettingsContentSection>
                    <div className="h-[300px]" />
                </div>
            </form>
        </FormProvider>
    );
};

export default Profile;
