import React from 'react';

import cn from 'core/utils/cn';

interface TermTabsProps {
    activeTab: number;
    onTabChange: (tabIndex: number) => void;
}

const TermTabs: React.FC<TermTabsProps> = ({ activeTab, onTabChange }) => {
    const tabs: string[] = [
        'Use of View',
        'Use of SFTP',
        'Warranties and Deposit of material',
        'Personal Data',
        'Security',
        'Confidentiality',
        'Limitation of Liability',
        'Changes to View, SFTP and these Terms and Conditions',
        'Internet safety and Security',
        'Enforceability',
        'Governing Law'
    ];

    return (
        <div className="w-1/4">
            <ul className="">
                {tabs.map((tab, index) => (
                    <li
                        key={index}
                        className={cn(
                            'p-4 cursor-pointer border-b border-r border-general-grey-grey-30 bg-white text-black hover:bg-general-grey-grey-10 last:border-b-0',
                            activeTab === index + 1 &&
                                'bg-general-grey-grey-20 text-brand-escode-neonblue-neonblue-100 font-semibold'
                        )}
                        onClick={() => onTabChange(index + 1)}
                    >
                        {index + 1}. {tab}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TermTabs;
