/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiController } from '../../autogenerated/Api';
import { AxiosResponse } from 'axios';
import { SpectreDepositResponse } from '../../autogenerated/data-contracts';
import { UseQueryOptions, useQuery } from 'react-query';
import { useAuth } from 'react-oidc-context';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/security-worker';
import useDepositNowStore from 'core/stores/use-deposit-now-store';

function useRequestSpectreToken(
    depositId: number,
    // options?: Omit<UseQueryOptions, 'queryFn'>
    options?: Omit<
        UseQueryOptions<
            SpectreDepositResponse | undefined,
            ReactQueryError<Error>,
            SpectreDepositResponse | undefined
        >,
        'queryKey' | 'queryFn'
    >
) {
    const auth = useAuth();
    const {
        setDepositUniqueReference,
        setShouldClaimToken,
        setSpectreAgentUrl,
        setSpectrecontrolUrl,
    } = useDepositNowStore();

    return useQuery(
        ['use-request-token'],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Mock is enabled useRequestToken call ignored.');
                return Promise.resolve({} as AxiosResponse<void | unknown>);
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const data = await controller.v1DepositsSpectretokenDetail(
                depositId
            );

            const spectreControlUrl = (data?.data as any)?.spectreControlUrl;
            const spectreAgentUrl = (data?.data as any)?.spectreAgentUrl;
            const depositUniqueReference = (data?.data as any)?.id;

            // set both urls in the store for easy access
            if (spectreControlUrl && spectreAgentUrl) {
                setSpectreAgentUrl(spectreAgentUrl.toString());
                setSpectrecontrolUrl(spectreControlUrl.toString());
                setDepositUniqueReference(depositUniqueReference.toString());
            }

            return data.data;
        },
        {
            enabled: options?.enabled ?? false,
            onSettled: (data) => {
                setShouldClaimToken(true);
            },
        }
    );
}

export default useRequestSpectreToken;
