import AppBody from './app-body';
import Help from 'ui/help/help';

function HelpBody() {
    return (
        <AppBody suppressPadding className="bg-white pb-12">
            <Help />
        </AppBody>
    );
}

export default HelpBody;
