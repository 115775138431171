import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

import { RadioProps } from '../element/radio';
import Field, { FieldProps } from './field';
import RadioField from './radio-field';
import cn from 'core/utils/cn';
import getNestedError from 'core/utils/get-nested-error';

interface RadioGroupFieldProps
    extends Omit<FieldProps, 'error'>,
        Omit<RadioProps, 'name' | 'label' | 'children' | 'value'> {
    elementClassName?: string;
    options: { label: ReactNode; value: string | boolean | number }[];
    post?: ReactNode;
    pre?: ReactNode;
    radioStyles?: string;
}

function RadioGroupField({
    className,
    disabled,
    elementClassName,
    label,
    name,
    options,
    orientation,
    post,
    pre,
    radioStyles,
    readOnly,
    tailwindStyle,
    tooltip,
    tooltipDisabled,
    tooltipPlacement,
    ...restProps
}: RadioGroupFieldProps) {
    const {
        formState: { errors }
    } = useFormContext();

    const fieldError = getNestedError(name, errors);

    const cssClasses = cn(
        'flex gap-2.5 ml-[0.20rem]',
        orientation === 'vertical' && 'flex-col',
        radioStyles
    );

    return (
        <Field
            as="radio"
            name={name}
            label={label}
            orientation={orientation}
            readOnly={readOnly}
            disabled={disabled}
            className={className}
            optional={restProps.optional}
            required={restProps.required}
            tailwindStyle={tailwindStyle}
            tooltip={tooltip}
            tooltipDisabled={tooltipDisabled}
            tooltipPlacement={tooltipPlacement}
            error={fieldError ? <>{fieldError}</> : undefined}
        >
            <div className={cssClasses}>
                {pre}
                {options.map((item) => (
                    <RadioField
                        key={item.value.toString()}
                        name={name}
                        value={item.value}
                        label={item.label}
                        disabled={disabled}
                        elementClassName={elementClassName}
                        suppressError
                        readOnly={readOnly}
                    />
                ))}
                {post}
            </div>
        </Field>
    );
}

export default RadioGroupField;
export type { RadioGroupFieldProps };
