import AppBody from './app-body';
import Support from 'ui/support/support';

function SupportBody() {
    return (
        <AppBody suppressPadding>
            <Support />
        </AppBody>
    );
}

export default SupportBody;
