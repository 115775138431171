import {
    Control,
    FieldValues,
    Path,
    useController,
    useFormContext
} from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendar,
    faChevronLeft,
    faChevronRight
} from '@fortawesome/pro-regular-svg-icons';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import './date-pick-field.scss';
import Field from './field';
import cn from 'core/utils/cn';

type DatePickFieldProps<T extends FieldValues> = {
    closeOnSelect?: boolean;
    control?: Control<T>;
    enableTodayButton?: boolean;
    enableViewButtons?: boolean;
    label?: string;
    name: Path<T>;
    optional?: boolean;
    orientation?: 'vertical' | 'horizontal' | 'reversed-horizontal';
    placeholder?: string;
    required?: boolean;
    tooltip?: string;
    tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left';
};

const DAYS = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const getTodayLocal = () => {
    const now = new Date();
    return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
        2,
        '0'
    )}-${String(now.getDate()).padStart(2, '0')}`;
};

function DatePickField<T extends FieldValues>({
    closeOnSelect = true,
    enableTodayButton = false,
    enableViewButtons = false,
    label,
    name,
    optional = false,
    orientation = 'vertical',
    placeholder = 'Select date',
    required = false,
    tooltip,
    tooltipPlacement = 'right'
}: DatePickFieldProps<T>) {
    const { control } = useFormContext();
    const { field, fieldState } = useController({
        control,
        name,
        rules: { required: required ? 'Date is required' : false }
    });

    const [showCalendar, setShowCalendar] = useState(false);
    const [view, setView] = useState<'day' | 'month' | 'year'>('day');
    const [viewDate, setViewDate] = useState<Date>(new Date());
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const calendarRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const today = getTodayLocal();

    // Update internal state when field value changes (including reset)
    useEffect(() => {
        if (field.value) {
            setSelectedDate(new Date(field.value));
        } else {
            setSelectedDate(null);
        }
    }, [field.value]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                calendarRef.current &&
                !calendarRef.current.contains(event.target as Node) &&
                inputRef.current &&
                !inputRef.current.contains(event.target as Node)
            ) {
                setShowCalendar(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDateChange = useCallback(
        (newDate: Date) => {
            const selectedDate = new Date(
                Date.UTC(
                    newDate.getFullYear(),
                    newDate.getMonth(),
                    newDate.getDate()
                )
            );
            setSelectedDate(selectedDate);
            field.onChange(selectedDate.toISOString());
            if (closeOnSelect) {
                setShowCalendar(false);
            }
        },
        [closeOnSelect, field]
    );

    const formatDisplayDate = (date: Date | null): string => {
        if (!date) return '';
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const getDisplayValue = () => {
        return formatDisplayDate(selectedDate);
    };

    const renderDayView = useCallback(() => {
        const monthStart = new Date(
            viewDate.getFullYear(),
            viewDate.getMonth(),
            1
        );
        const monthEnd = new Date(
            viewDate.getFullYear(),
            viewDate.getMonth() + 1,
            0
        );
        const startDate = new Date(monthStart);
        startDate.setDate(
            startDate.getDate() -
                (startDate.getDay() === 0 ? 6 : startDate.getDay() - 1)
        );
        const endDate = new Date(monthEnd);
        endDate.setDate(
            endDate.getDate() +
                (endDate.getDay() === 0 ? 0 : 7 - endDate.getDay())
        );

        const calendar = [];
        let week = [];

        while (startDate <= endDate) {
            week.push(new Date(startDate));
            if (week.length === 7) {
                calendar.push(week);
                week = [];
            }
            startDate.setDate(startDate.getDate() + 1);
        }

        return (
            <>
                <div className="grid grid-cols-7 gap-1 mb-2 border-general-grey-grey-30 border-b pb-3 cursor-default">
                    {DAYS.map((day) => (
                        <div
                            key={day}
                            className="text-center text-xs font-medium text-general-grey-grey-70 select-none"
                        >
                            {day}
                        </div>
                    ))}
                </div>
                {calendar.map((week, weekIndex) => (
                    <div key={weekIndex} className="grid grid-cols-7 gap-3">
                        {week.map((day, dayIndex) => {
                            const dayString = `${day.getFullYear()}-${String(
                                day.getMonth() + 1
                            ).padStart(2, '0')}-${String(
                                day.getDate()
                            ).padStart(2, '0')}`;
                            return (
                                <button
                                    key={dayIndex}
                                    onClick={() => handleDateChange(day)}
                                    className={cn(
                                        'w-8 h-8 flex items-center justify-center text-sm rounded-lg font-normal hover:bg-brand-escode-neonblue-neonblue-10',
                                        day.getMonth() !==
                                            viewDate.getMonth() &&
                                            'text-general-grey-grey-60 bg-general-grey-grey-100 not-this-month',
                                        selectedDate &&
                                            day.toDateString() ===
                                                selectedDate.toDateString() &&
                                            'text-white selected',
                                        dayString === today &&
                                            'font-medium is-today'
                                    )}
                                    type="button"
                                >
                                    {day.getDate()}
                                </button>
                            );
                        })}
                    </div>
                ))}
            </>
        );
    }, [viewDate, selectedDate, today, handleDateChange]);

    const renderMonthView = useCallback(() => {
        return (
            <div className="grid grid-cols-3 gap-4">
                {MONTHS.map((month, index) => (
                    <button
                        key={month}
                        onClick={() => {
                            setViewDate(
                                new Date(viewDate.getFullYear(), index, 1)
                            );
                            setView('day');
                        }}
                        className={cn(
                            'p-2 text-sm hover:bg-gray-100 rounded-lg',
                            index === viewDate.getMonth() &&
                                'bg-brand-escode-neonblue-neonblue-100 text-white font-medium'
                        )}
                    >
                        {month}
                    </button>
                ))}
            </div>
        );
    }, [viewDate]);

    const renderYearView = useCallback(() => {
        const currentYear = viewDate.getFullYear();
        const years = Array.from({ length: 12 }, (_, i) => currentYear - 5 + i);

        return (
            <div className="grid grid-cols-3 gap-4">
                {years.map((year) => (
                    <button
                        key={year}
                        onClick={() => {
                            setViewDate(new Date(year, viewDate.getMonth(), 1));
                            setView('month');
                        }}
                        className={cn(
                            'p-2 text-sm hover:bg-gray-100 rounded-lg',
                            year === currentYear &&
                                'bg-brand-escode-neonblue-neonblue-100 text-white font-medium'
                        )}
                    >
                        {year}
                    </button>
                ))}
            </div>
        );
    }, [viewDate]);

    const handlePrevious = () => {
        if (view === 'day') {
            setViewDate(
                new Date(viewDate.getFullYear(), viewDate.getMonth() - 1, 1)
            );
        } else if (view === 'month') {
            setViewDate(
                new Date(viewDate.getFullYear(), viewDate.getMonth() - 1, 1)
            );
        } else if (view === 'year') {
            setViewDate(
                new Date(viewDate.getFullYear() - 12, viewDate.getMonth(), 1)
            );
        }
    };

    const handleNext = () => {
        if (view === 'day') {
            setViewDate(
                new Date(viewDate.getFullYear(), viewDate.getMonth() + 1, 1)
            );
        } else if (view === 'month') {
            setViewDate(
                new Date(viewDate.getFullYear(), viewDate.getMonth() + 1, 1)
            );
        } else if (view === 'year') {
            setViewDate(
                new Date(viewDate.getFullYear() + 12, viewDate.getMonth(), 1)
            );
        }
    };

    return (
        <Field
            as="label"
            name={name}
            label={label}
            optional={optional}
            required={required}
            tooltip={tooltip}
            tooltipPlacement={tooltipPlacement}
            orientation={orientation}
            error={fieldState.error?.message}
        >
            <div className="relative">
                <div
                    className={cn(
                        'flex items-center border border-general-grey-grey-40 rounded-lg cursor-default h-[40px] ',
                        fieldState.error
                            ? 'border-general-red-red-100'
                            : 'focus-within:ring-brand-escode-neonblue-neonblue-100'
                    )}
                    onClick={() => setShowCalendar(true)}
                >
                    <input
                        ref={inputRef}
                        id={name}
                        type="text"
                        value={getDisplayValue()}
                        className="w-full h-[40px] bg-transparent px-3 py-2 focus:outline-none rounded-lg text-general-grey-grey-100 text-sm cursor-pointer disabled:bg-general-grey-grey-20"
                        readOnly
                        placeholder={placeholder}
                    />
                    <FontAwesomeIcon
                        icon={faCalendar}
                        className={cn(
                            'mr-3 text-general-grey-grey-90 cursor-pointer',
                            fieldState.error && 'text-general-red-red-100'
                        )}
                    />
                </div>
                {showCalendar && (
                    <div
                        ref={calendarRef}
                        className="absolute z-10 mt-1 flex flex-col gap-3 bg-white border border-general-grey-grey-40 rounded-lg shadow-lg p-4 cursor-default w-[347px]"
                    >
                        <div
                            className={cn(
                                'flex justify-between items-center border-general-grey-grey-30 border-b pb-3',
                                view === 'month' && 'justify-center'
                            )}
                        >
                            {view !== 'month' && (
                                <>
                                    <button
                                        onClick={handlePrevious}
                                        className="p-1 hover:bg-gray-100 rounded-lg"
                                        type="button"
                                    >
                                        <FontAwesomeIcon
                                            icon={faChevronLeft}
                                            className="w-5 h-5"
                                        />
                                    </button>
                                    <div className="flex gap-4 px-6">
                                        {view === 'day' ? (
                                            <>
                                                <button
                                                    onClick={() =>
                                                        setView('month')
                                                    }
                                                    className="text-lg font-semibold w-[117px]"
                                                    type="button"
                                                >
                                                    {
                                                        MONTHS[
                                                            viewDate.getMonth()
                                                        ]
                                                    }{' '}
                                                    <FontAwesomeIcon
                                                        icon={faSortDown}
                                                        className="w-5 align-top"
                                                    />
                                                </button>
                                                <div className="flex items-center">
                                                    <button
                                                        onClick={() =>
                                                            setView('year')
                                                        }
                                                        className="text-lg font-semibold"
                                                        type="button"
                                                    >
                                                        {viewDate.getFullYear()}{' '}
                                                        <FontAwesomeIcon
                                                            icon={faSortDown}
                                                            className="w-5 align-top"
                                                        />
                                                    </button>
                                                </div>
                                            </>
                                        ) : (
                                            <p className="text-lg font-semibold">
                                                Year
                                            </p>
                                        )}
                                    </div>
                                    <button
                                        onClick={handleNext}
                                        className="p-1 hover:bg-gray-100 rounded-lg"
                                        type="button"
                                    >
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                            className="w-5 h-5"
                                        />
                                    </button>
                                </>
                            )}
                            {view === 'month' && (
                                <p className="text-lg font-semibold">Month</p>
                            )}
                        </div>
                        <div className="">
                            {view === 'day' && renderDayView()}
                            {view === 'month' && renderMonthView()}
                            {view === 'year' && renderYearView()}
                        </div>
                        {enableTodayButton && (
                            <div className="w-full flex items-center justify-center">
                                <button
                                    onClick={() => {
                                        setView('day');
                                        setViewDate(new Date());
                                    }}
                                    className="px-3 py-1 text-sm today-button rounded"
                                    type="button"
                                >
                                    Today
                                </button>
                            </div>
                        )}

                        {enableViewButtons && (
                            <div className="flex w-full justify-between">
                                <div className="flex w-full font-medium">
                                    <button
                                        onClick={() => setView('day')}
                                        className={cn(
                                            'flex-1 px-3 py-1 text-sm rounded-l-lg rounded-r-none view-selection transition-all duration-300',
                                            view === 'day' &&
                                                'border border-general-grey-grey-20 selected-view',
                                            view !== 'day' && 'unselected-view'
                                        )}
                                        type="button"
                                    >
                                        Day
                                    </button>
                                    <button
                                        onClick={() => setView('month')}
                                        className={cn(
                                            'flex-1 px-3 py-1 text-sm view-selection transition-all duration-300',
                                            view === 'month' &&
                                                'border border-general-grey-grey-20 selected-view',
                                            view !== 'month' &&
                                                'unselected-view'
                                        )}
                                        type="button"
                                    >
                                        Month
                                    </button>
                                    <button
                                        onClick={() => setView('year')}
                                        className={cn(
                                            'flex-1 px-3 py-1 text-sm rounded-r-lg transition-all duration-300',
                                            view === 'year' &&
                                                'border border-general-grey-grey-20 selected-view',
                                            view !== 'year' && 'unselected-view'
                                        )}
                                        type="button"
                                    >
                                        Year
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Field>
    );
}

export default DatePickField;
export type { DatePickFieldProps };
