/* eslint-disable @typescript-eslint/no-explicit-any */
import { TailwindStyle } from '@ncc-frontend/core';
import { merge } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { useId, useMemo } from 'react';

import Field, { FieldProps } from './field';
import Radio, { RadioProps } from '../element/radio';
import classNames from 'classnames';
import getNestedError from 'core/utils/get-nested-error';

interface RadioFieldProps
    extends Omit<FieldProps, 'error'>,
        Omit<RadioProps, 'name' | 'label' | 'children' | 'error'> {
    elementClassName?: string;
    suppressError?: boolean;
}

function RadioField({
    className,
    disabled,
    elementClassName,
    label,
    name,
    orientation = 'reversed-horizontal',
    readOnly,
    suppressError = false,
    tailwindStyle,
    value,
    ...restProps
}: any) {
    const {
        formState: { errors },
        register
    } = useFormContext();

    const formValue = useWatch({ name });
    const checked = formValue === value;

    const id = useId();

    const fieldError = getNestedError(name, errors);

    const mergedTailwindStyle = useMemo<TailwindStyle>(
        () =>
            merge(
                {
                    gap: 'gap-2',
                    items: 'items-center'
                },
                tailwindStyle
            ),
        [tailwindStyle]
    );

    return (
        <Field
            name={name}
            label={label}
            orientation={orientation}
            readOnly={readOnly}
            disabled={disabled}
            id={id}
            tailwindStyle={mergedTailwindStyle}
            className={className}
            required={restProps.required}
            error={!suppressError && fieldError ? label : undefined}
        >
            <Radio
                {...restProps}
                id={id}
                className={classNames(elementClassName)}
                value={value}
                {...register(name)}
                error={!suppressError && !!fieldError}
                checked={checked}
                readOnly={readOnly}
                disabled={disabled}
            />
        </Field>
    );
}

export default RadioField;
export type { RadioFieldProps };
