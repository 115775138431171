import { DropdownPanel, Menu } from 'lib-ui';
import { LANGUAGES } from 'localization';

import LanguageOption from 'app-layout/headers/language-switch/language-option';

function SystemLanguagePanel() {
    return (
        <DropdownPanel>
            <Menu tailwindStyle={{ gap: 'gap-1' }}>
                {LANGUAGES.map(({ lang, name }) => (
                    <LanguageOption key={lang} lang={lang}>
                        {name}
                    </LanguageOption>
                ))}
            </Menu>
        </DropdownPanel>
    );
}

export default SystemLanguagePanel;
