import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { memo, useId, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import Field, { FieldProps } from './field';
import Input, { InputProps } from '../element/input';
import getNestedError from 'core/utils/get-nested-error';

interface InputFieldProps
    extends FieldProps,
        Omit<
            InputProps,
            | 'value'
            | 'name'
            | 'id'
            | 'error'
            | 'wrapperClassName'
            | 'wrapperStyle'
            | 'wrapperTailwindStyle'
        > {
    description?: React.ReactNode;
    suppressReq?: boolean;
}

function InputField({
    className,
    description,
    disabled,
    label,
    name,
    optional,
    orientation,
    readOnly,
    required,
    style,
    suppressReq,
    tailwindStyle,
    tooltip,
    tooltipDisabled,
    tooltipPlacement,
    type = 'text',
    ...restProps
}: InputFieldProps) {
    const {
        formState: { errors },
        register
    } = useFormContext();
    const formValue = useWatch({ name });
    const id = useId();
    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = () => {
        setShowPassword((prev) => !prev);
    };

    const fieldError = getNestedError(name, errors);

    const MemoizedEyeIcon = memo(({ onClick }: { onClick: () => void }) => (
        <FontAwesomeIcon
            aria-label="Hide password"
            className="cursor-pointer absolute text-lg right-2 top-3 z-50"
            icon={faEye}
            role="button"
            onClick={onClick}
        />
    ));

    const MemoizedEyeSlashIcon = memo(
        ({ onClick }: { onClick: () => void }) => (
            <FontAwesomeIcon
                aria-label="Show password"
                className="cursor-pointer absolute text-lg right-2 top-3 z-50"
                icon={faEyeSlash}
                onClick={onClick}
                role="button"
            />
        )
    );

    return (
        <Field
            as="label"
            name={name}
            label={label}
            optional={optional}
            orientation={orientation}
            readOnly={readOnly}
            disabled={disabled}
            id={id}
            required={required}
            suppressReq={suppressReq}
            error={fieldError ? <>{fieldError}</> : undefined}
            className={className}
            style={style}
            tailwindStyle={tailwindStyle}
            tooltip={tooltip}
            tooltipDisabled={tooltipDisabled}
            tooltipPlacement={tooltipPlacement}
        >
            <div className="relative">
                <Input
                    {...restProps}
                    id={id}
                    {...register(name)}
                    value={formValue}
                    readOnly={readOnly}
                    disabled={disabled}
                    error={!!fieldError}
                    type={type === 'password' && showPassword ? 'text' : type}
                />
                {type === 'password' && showPassword && (
                    <MemoizedEyeIcon onClick={togglePassword} />
                )}
                {type === 'password' && !showPassword && (
                    <MemoizedEyeSlashIcon onClick={togglePassword} />
                )}
            </div>
            {!!!fieldError && description && (
                <div className="mt-1">
                    <p className="text-sm">{description}</p>
                </div>
            )}
        </Field>
    );
}

export default InputField;
export type { InputFieldProps };
