import { DepositData } from 'ui/deposit-now/upload-step/upload-types';

export type VVUploaderProps = {
    currentDataCentre?: string;
    depositId: number;
};

export const initialDepositData: DepositData = {
    depositUniqueReference: '',
    error: null,
    fileId: 0,
    spectreDepositResponse: {
        authToken: {
            audiences: [],
            id: '',
            location: '',
            token: ''
        },
        id: '',
        location: ''
    },
    success: false,
    uniqueReference: '',
    uploaderBaseUrl: null
};
