import { AgGridReact } from 'ag-grid-react';
import { DataWidgetContextValue, TableWidget, TableWidgetProps } from 'lib-ui';
import {
    DepositItem,
    GetDepositsResponse
} from 'core/api/client-portal/autogenerated/data-contracts';
import {
    ForwardedRef,
    MouseEventHandler,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef
} from 'react';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import { DepositsTableContext } from './deposits-table-context';
import DepositActionsCellRenderer from './deposit-actions-cell-renderer';
import DepositStatusCellRenderer, {
    depositStatusValueGetter
} from './deposit-status-cell-renderer';
import getDateFilter from 'core/ag-grid/get-date-filter';
// import depositMediaCheckCellRenderer from './deposit-media-check-cell-renderer';
// import depositVerificationCellRenderer from './deposit-verification-cell-renderer';
import AgreementDetailsDateCellRenderer from '../agreement-details-date-cell-renderer';
import DepositDocumentsCellRenderer from './deposit-documents-cell-renderer';
import DepositNameCellRenderer from './deposit-name-cell-renderer';
import useBeautify from 'core/beautifiers/use-beautify';
import useDepositMediaCheckDownload from 'core/api/client-portal/hooks/deposits/use-deposit-media-check-download';

interface DepositsTableWidgetProps {
    agreementNumber: number | undefined;
    className?: string;
    data: (GetDepositsResponse | undefined)[] | undefined;
    error?: number;
    loading: boolean;
    onNext: () => void;
    onReload?: MouseEventHandler<HTMLButtonElement>;
    totalPages: number;
}

function DepositsTableWidget(
    {
        agreementNumber,
        className,
        data,
        error,
        loading,
        onNext,
        onReload,
        totalPages
    }: DepositsTableWidgetProps,
    ref: ForwardedRef<AgGridReact<DepositItem>>
) {
    const { i18n, t } = useTranslation();
    const { push } = useModals();
    const { beautifyDepositStatus } = useBeautify();
    const dataWidgetRef = useRef<DataWidgetContextValue | null>(null);

    const id = 'deposits-table';
    const agGridRef = useRef<AgGridReact<DepositItem>>(null);

    const {
        isLoading: isLoadingDepositMediaCheckDownload,
        mutate: requestDepositMediaCheckDownload
    } = useDepositMediaCheckDownload();

    const setDataWidgetRef = useCallback(
        (ref: DataWidgetContextValue | null) => {
            dataWidgetRef.current = ref;

            if (ref === null) return;

            ref.setTotalPages(totalPages);
        },
        [totalPages]
    );

    const columnDefs = useMemo<TableWidgetProps<true>['columnDefs']>(() => {
        const result: TableWidgetProps<true>['columnDefs'] = [
            {
                cellRenderer: DepositNameCellRenderer,
                field: 'softwarePackageName',
                headerName: t('deposits.header.software')
            },
            {
                cellRenderer: DepositStatusCellRenderer,
                field: 'escrowDepositStatus',
                headerName: t('deposits.header.status'),
                valueGetter: depositStatusValueGetter
            },
            {
                ...getDateFilter('depositDate', i18n.language),
                cellRenderer: AgreementDetailsDateCellRenderer,
                colId: 'depositDate',
                headerName: t('deposits.header.deposit-date')
            },
            {
                cellRenderer: DepositDocumentsCellRenderer,
                colId: 'documents',
                headerName: t('deposits.header.documents')
            },
            // Hidden for MPV https://nccdev.atlassian.net/browse/ED-1578
            // {
            //     cellRenderer: depositVerificationCellRenderer,
            //     colId: 'verification',
            //     headerName: t('deposits.header.verification')
            // },
            // Hidden for MVP https://nccdev.atlassian.net/browse/ED-1579
            // {
            //     cellRenderer: depositMediaCheckCellRenderer,
            //     colId: 'mediaCheck',
            //     headerName: t('deposits.header.media-check')
            // },
            {
                field: 'testingLevelDescription',
                headerName: t('deposits.header.testing-level')
            },
            {
                field: 'depositOwner',
                headerName: t('deposits.header.deposit-owner')
            },
            {
                cellRenderer: DepositActionsCellRenderer,
                colId: 'actions',
                headerName: t('deposits.header.actions')
            }
        ];

        return result;
    }, [i18n.language, t]);

    const selectableFields = useMemo<
        TableWidgetProps<true>['selectableFields']
    >(() => {
        const result: TableWidgetProps<false>['selectableFields'] = [
            {
                fieldId: 'softwarePackageName',
                label: t('deposits.header.software')
            },
            {
                fieldId: 'escrowDepositStatus',
                label: t('deposits.header.status')
            },
            {
                fieldId: 'depositDate',
                label: t('deposits.header.deposit-date')
            },
            {
                fieldId: 'documents',
                label: t('deposits.header.documents')
            },
            // Hidden for MPV https://nccdev.atlassian.net/browse/ED-1578
            // {
            //     fieldId: 'verification',
            //     label: t('deposits.header.verification')
            // },
            // Hidden for MVP https://nccdev.atlassian.net/browse/ED-1579
            // {
            //     fieldId: 'mediaCheck',
            //     label: t('deposits.header.media-check')
            // },
            {
                fieldId: 'testingLevelDescription',
                label: t('deposits.header.testing-level')
            },
            {
                fieldId: 'depositOwner',
                label: t('deposits.header.deposit-owner')
            },
            {
                fieldId: 'actions',
                label: t('deposits.header.actions')
            }
        ];

        return result;
    }, [t]);

    const tableContext = useMemo<DepositsTableContext>(
        () => ({
            agreementNumber,
            beautifyDepositStatus,
            isLoadingDepositMediaCheckDownload,
            push,
            requestDepositMediaCheckDownload,
            t
        }),
        [
            agreementNumber,
            beautifyDepositStatus,
            isLoadingDepositMediaCheckDownload,
            push,
            requestDepositMediaCheckDownload,
            t
        ]
    );

    const noRowsOverlayComponentParams = useMemo<
        TableWidgetProps<true>['noRowsOverlayComponentParams']
    >(() => ({ message: t('agreement-deposit.empty') }), [t]);

    useImperativeHandle(ref, () => agGridRef.current as AgGridReact);

    useEffect(() => {
        if (dataWidgetRef.current === null) return;

        dataWidgetRef.current.setTotalPages(totalPages);
    }, [totalPages]);

    return (
        <TableWidget
            ref={setDataWidgetRef}
            id={id}
            context={tableContext}
            selectableFields={selectableFields}
            title={t('deposits.title')}
            columnDefs={columnDefs}
            rowData={data?.flatMap((item) => item?.deposits).filter(Boolean)}
            loading={loading}
            className={className}
            backendPagination
            fetchNextPage={onNext}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            error={error}
            onReload={onReload}
        />
    );
}

export default forwardRef(DepositsTableWidget);
export type { DepositsTableWidgetProps };
