import { useFormContext, useWatch } from 'react-hook-form';
import { useId } from 'react';

import { DatePicker } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputProps } from '../element/input';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import Field, { FieldProps } from './field';
import cn from 'core/utils/cn';

interface DatePickerFieldProps
    extends FieldProps,
        Omit<
            InputProps,
            | 'value'
            | 'name'
            | 'id'
            | 'error'
            | 'wrapperClassName'
            | 'wrapperStyle'
            | 'wrapperTailwindStyle'
        > {}

function DatePickerField({
    className,
    disabled,
    label,
    name,
    optional,
    orientation,
    readOnly,
    required,
    style,
    suppressReq,
    tailwindStyle,
    tooltip,
    tooltipDisabled,
    tooltipPlacement,
    ...restProps
}: DatePickerFieldProps) {
    const {
        formState: { errors },
        register
    } = useFormContext();

    const value = useWatch({ name });
    const id = useId();

    return (
        <Field
            as="label"
            name={name}
            label={label}
            optional={optional}
            orientation={orientation}
            readOnly={readOnly}
            disabled={disabled}
            id={id}
            required={required}
            suppressReq={suppressReq}
            error={!!errors[name] ? <>{errors[name]?.message}</> : undefined}
            className={cn(className, 'relative')}
            style={style}
            tailwindStyle={tailwindStyle}
            tooltip={tooltip}
            tooltipDisabled={tooltipDisabled}
            tooltipPlacement={tooltipPlacement}
        >
            <DatePicker
                className="w-full border p-1.5 rounded-md "
                placeholder={label?.toString()}
                {...register(name)}
                value={value}
            />
            <FontAwesomeIcon
                icon={faCalendar}
                className="absolute top-11 right-3"
            />
        </Field>
    );
}

export default DatePickerField;
export type { DatePickerFieldProps };
