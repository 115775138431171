import {
    AgreementPartyContactViewModel,
    AgreementPartyViewModel
} from 'core/api/client-portal/autogenerated/data-contracts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'lib-ui';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import assignColour from 'core/utils/colour-randomiser';
import cn from 'core/utils/cn';

function removeDuplicates(
    contacts: AgreementPartyContactViewModel[] | null | undefined
): AgreementPartyContactViewModel[] {
    if (!contacts) {
        return [];
    }

    const uniqueContacts = new Map<number, AgreementPartyContactViewModel>();

    contacts.forEach((contact) => {
        if (
            contact.contactId !== undefined &&
            !uniqueContacts.has(contact.contactId)
        ) {
            uniqueContacts.set(contact.contactId, contact);
        }
    });

    return Array.from(uniqueContacts.values());
}

function AgreementPartiesContactModal({
    agreementPartyContacts
}: AgreementPartyViewModel) {
    const { t } = useTranslation();
    const agreementContacts = removeDuplicates(agreementPartyContacts);

    const initialiseNames = (name: string) => {
        if (!name) return '';
        return name
            .split(' ')
            .map((n) => n[0])
            .join('');
    };

    const listItemStyles =
        'text-general-grey-grey-70 text-base py-4 border-b border-escode-grey-20 list-none';

    function DetailsSection({
        role,
        subheading,
        suppressBottomBorder = false
    }: {
        role: string;
        subheading: string;
        suppressBottomBorder?: boolean;
    }) {
        const contacts = agreementContacts.filter(
            (contact) =>
                (contact.roles?.length ?? 0) > 0 &&
                contact.roles?.includes(role)
        );

        return (
            <li
                className={cn(
                    listItemStyles,
                    suppressBottomBorder && 'border-none'
                )}
            >
                <div
                    className="uppercase mb-2"
                    data-testid={`subheading-${subheading}`}
                >
                    {subheading}
                </div>
                <div className="font-normal grid grid-cols-3 gap-4">
                    {agreementContacts
                        .filter(
                            (contact) =>
                                (contact.roles?.length ?? 0) > 0 &&
                                contact.roles?.includes(role)
                        )
                        .map((contact) => (
                            <div
                                className="flex gap-2 items-center"
                                key={contact.name}
                            >
                                <div
                                    className={cn(
                                        'h-10 w-10 text-xs font-bold rounded-full flex items-center justify-center text-white',
                                        assignColour(contact.name as string)
                                    )}
                                >
                                    {initialiseNames(contact.name as string)}
                                </div>
                                <p className="text-sm font-semibold">
                                    {contact.name}
                                </p>
                            </div>
                        ))}
                    {contacts.length === 0 && (
                        <div className="flex items-center gap-2">
                            <div
                                className={cn(
                                    'h-10 w-10 text-xs font-bold rounded-full flex items-center justify-center bg-general-grey-grey-20 text-general-grey-grey-100'
                                )}
                            >
                                <FontAwesomeIcon icon={faUser} />
                            </div>
                            <p className="text-sm font-semibold">No Contacts</p>
                        </div>
                    )}
                </div>
            </li>
        );
    }

    return (
        <Modal size="m">
            <Modal.Header>
                {t('agreement-parties-contact-modal.header')}
            </Modal.Header>
            <Modal.Body>
                <DetailsSection role="Main" subheading="Main Contact" />
                <DetailsSection role="Code" subheading="Code" />
                <DetailsSection
                    role="Finance"
                    subheading="Finance"
                    suppressBottomBorder
                />
            </Modal.Body>
            <Modal.Footer closeButtonVariant="tertiary"> </Modal.Footer>
        </Modal>
    );
}

export default AgreementPartiesContactModal;
