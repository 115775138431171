import { AgreementListResponseViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import {
    CheckField,
    ChoiceField,
    DatePickField,
    SelectFieldProps,
    TextareaField
} from 'lib-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface TerminationDataStepProps {
    agreementsToTerminate: AgreementListResponseViewModel | undefined;
}

const TerminationDataStep = ({
    agreementsToTerminate
}: TerminationDataStepProps) => {
    const { t } = useTranslation();

    const agreementNumbers = agreementsToTerminate!.agreements!.map(
        (agreement) => ({
            agreementId: agreement.agreementId,
            agreementNumber: agreement.agreementNumber
        })
    );

    const agreementToTerminateOptions = useMemo<SelectFieldProps['options']>(
        () =>
            agreementNumbers?.map((agreement) => ({
                label: agreement ? agreement.agreementNumber?.toString() : '',
                value: agreement ? agreement.agreementId!.toString() : ''
            })) || [],
        [agreementNumbers]
    );

    const partyTypeOptions = useMemo<SelectFieldProps['options']>(
        () => [
            {
                label: t('termination-form.software-vendor'),
                value: 'Software Vendor'
            },
            {
                label: t('termination-form.software-license'),
                value: 'Software Licensee'
            }
        ],
        [t]
    );

    return (
        <section
            data-testid="personal-information"
            className="flex flex-col gap-4 mb-8"
        >
            <div className="flex gap-4">
                <div className="w-full">
                    <DatePickField
                        enableViewButtons
                        name="dateOfTermination"
                        label={t('termination-form.date-termination')}
                        placeholder="DD/MM/YYYY"
                    />
                </div>
                <div className="w-full">
                    <ChoiceField
                        label={t('termination-form.agreement-terminate')}
                        placeholder={t(
                            'termination-form.agreement-terminate-placeholder'
                        )}
                        name="agreementToTerminate"
                        options={agreementToTerminateOptions}
                        className="w-full"
                    />
                </div>
            </div>
            <TextareaField
                name="reason"
                label={t('termination-form.reason')}
                placeholder={t('termination-form.reason-placeholder')}
                tailwindStyle={{
                    width: 'w-full'
                }}
            />
            <TextareaField
                name="comments"
                label={t('termination-form.comments')}
                placeholder={t('termination-form.comments-placeholder')}
                tailwindStyle={{
                    width: 'w-full'
                }}
            />
            <ChoiceField
                label={t('termination-form.party-type')}
                placeholder={t('termination-form.party-type-placeholder')}
                name="partyType"
                options={partyTypeOptions}
            />
            <CheckField
                errorPosition="bottom"
                id="confirmed"
                label={t('termination-form.confirmed')}
                name="confirmed"
                orientation="checkbox-first"
            />
        </section>
    );
};

export default TerminationDataStep;
