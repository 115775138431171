import { Button, Modal } from 'lib-ui';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import React from 'react';

const SupportSuccessModal = () => {
    const { t } = useTranslation();
    const { pop } = useModals();

    return (
        <Modal size="m">
            <Modal.Header>{t('support-modal.header')}</Modal.Header>
            <Modal.Body>
                <div className="w-full flex gap-4 justify-center mb-6">
                    {t('support-modal.message')}
                </div>
                <div className="flex justify-end">
                    <Button onClick={() => pop()} variant="danger">
                        {t('support-modal.close')}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SupportSuccessModal;
