import { GetScheduledDepositStatsResponse } from 'core/api/client-portal/autogenerated/data-contracts';
import { useTranslation } from 'react-i18next';

import SchedulesMetricPanel from '../schedules-metric-panel';

type CurrentMonthSchedulesProps = {
    data: GetScheduledDepositStatsResponse | undefined;
    loading?: boolean;
};

function CurrentMonthSchedules({ data, loading }: CurrentMonthSchedulesProps) {
    const { t } = useTranslation();
    console.log(data);

    return (
        <SchedulesMetricPanel
            data={data}
            title={t('schedules.metrics.upcoming-this-month')}
            loading={loading}
        />
    );
}

export default CurrentMonthSchedules;
