import { MouseEventHandler, ReactNode } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { Tooltip } from 'lib-ui';
import { isNull } from 'lodash';
import classNames from 'classnames';

interface SiderLinkProps {
    collapsed?: boolean;
    externalLink?: boolean;
    icon: ReactNode;
    onClick?: MouseEventHandler<HTMLElement>;
    suppressHover?: boolean;
    text?: string;
    to?: string;
    withoutText?: boolean;
}

function SiderLink({
    collapsed,
    externalLink,
    icon,
    onClick,
    suppressHover,
    text,
    to,
    withoutText
}: SiderLinkProps) {
    const match = useMatch(to ?? '');
    const active = !isNull(match);

    const cssClasses = classNames(
        'relative',
        'h-11',
        'flex items-center',
        'text-white',
        'rounded-lg',
        'transition-colors duration-300',
        'cursor-pointer',
        {
            'bg-brand-escode-neonblue-neonblue-80': active && to,
            'hover:bg-brand-escode-neonblue-neonblue-80': !suppressHover
        }
    );

    const content = collapsed ? (
        <>
            <Tooltip
                message={text}
                trigger="hover"
                data-testid=""
                placement="right"
                panelProps={{ suppressTick: true }}
            >
                <div className="h-11 w-11 flex items-center justify-center relative">
                    {icon}
                </div>
            </Tooltip>
            {!collapsed && text && !withoutText && (
                <div className="absolute left-11 overflow-hidden">{text}</div>
            )}
        </>
    ) : (
        <>
            <div
                className="h-11 w-11 flex items-center justify-center"
                data-testid="icon"
            >
                {icon}
            </div>
            {!collapsed && text && !withoutText && (
                <div
                    className="absolute left-11 overflow-hidden w-[80%] break-words"
                    data-testid="sider-link-text"
                >
                    {text}
                </div>
            )}
        </>
    );

    return to && !active ? (
        !externalLink ? (
            <NavLink data-testid="link" to={to} className={cssClasses}>
                {content}
            </NavLink>
        ) : (
            <a
                data-testid="link"
                href={to}
                className={cssClasses}
                target="_blank"
                rel="noreferrer"
            >
                {content}
            </a>
        )
    ) : (
        <div onClick={onClick} className={cssClasses}>
            {content}
        </div>
    );
}

export default SiderLink;
export type { SiderLinkProps };
