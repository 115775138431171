/* eslint-disable sort-keys-fix/sort-keys-fix */
import * as yup from 'yup';

const requiredMessage = 'This field is required';
const countryMessage = 'This is required. Please select a country';
const radioMessage = 'This field is required, please select "Yes" or "No"';
const licenseesMessage = 'Please select licensee(s)';

const schema = yup.object({
    contactDetails: yup.object({
        addressLine1: yup.string().required(requiredMessage),
        addressLine2: yup.string(),
        addressLine3: yup.string(),
        city: yup.string().required(requiredMessage),
        companyName: yup.string().required(requiredMessage),
        contactName: yup.string().required(requiredMessage),
        country: yup
            .string()
            .required(countryMessage)
            .typeError(countryMessage),
        county: yup.string(),
        emailAddress: yup.string().required(requiredMessage),
        jobTitle: yup.string().required(requiredMessage),
        postCode: yup.string().required(requiredMessage),
        telephoneNumber: yup.string().required(requiredMessage)
    }),
    details: yup.object({
        applicationCategory: yup.string().nullable(),
        approximateSizeOfMediaContent: yup
            .number()
            .required(requiredMessage)
            .typeError(requiredMessage),
        containsVirtualMachine: yup
            .mixed<string | boolean>()
            .required(radioMessage)
            .typeError(radioMessage),
        contentsOfThisDeposit: yup.lazy((value) => {
            return yup
                .object()
                .shape({
                    sourceCode: yup.boolean(),
                    development: yup.boolean(),
                    environmentOrBuildTools: yup.boolean(),
                    database: yup.boolean(),
                    testEnvironment: yup.boolean(),
                    otherData: yup.boolean(),
                    configurationFiles: yup.boolean()
                })
                .test({
                    test: (obj) => {
                        // Check if at least one field is true
                        return Object.values(obj).some(
                            (value) => value === true
                        );
                    },
                    message: 'At least one of these options must be selected'
                });
        }),
        fileTypes: yup.mixed().when('contentsOfThisDeposit.sourceCode', {
            is: true,
            then: yup
                .array()
                .min(1, requiredMessage)
                .of(
                    yup.object().shape({
                        label: yup.string().required(),
                        value: yup.number().required()
                    })
                )
        }),
        financialServicesCategory: yup.string().nullable(),
        hardwareUsedToCreateTheDeposit: yup.string(),
        isForAMediaFileReview: yup
            .string()
            .required(radioMessage)
            .typeError(radioMessage),
        isForAScheduledVerificationExercise: yup
            .string()
            .required(radioMessage)
            .typeError(radioMessage),
        isForASpecificLicensee: yup.string(),
        licenseesThatRequireThisDeposit: yup
            .array()
            .when('isForASpecificLicensee', {
                is: (value: string) => value === '1',
                then: yup
                    .array()
                    .min(1, licenseesMessage)
                    .of(
                        yup.object().shape({
                            id: yup.string().required(),
                            label: yup.string().required(),
                            value: yup.number().required()
                        })
                    )
            }),
        operatingSystemUsed: yup.string(),
        softwareOwnerCompanyName: yup.string().required(requiredMessage),
        softwarePackageName: yup.string().required(requiredMessage),
        versionOrReleaseNumber: yup.string().required(requiredMessage)
    }),
    moreDetails: yup.object({
        compressionMethod: yup.string().when('isCompressed', {
            is: 'yes',
            then: yup.string().required('Compression method is required'),
            otherwise: yup.string()
        }),
        containsPersonalData: yup
            .mixed<string | boolean>()
            .required('This field is required')
            .typeError('Please select an option'),
        documentation: yup.string(),
        encryptMethod: yup.string().when('isEncrypted', {
            is: 'yes',
            then: yup.string().required('Encryption method is required'),
            otherwise: yup.string()
        }),
        exportControlsDetails: yup
            .string()
            .when('isSubjectToAnyExportControls', {
                is: 'yes',
                then: yup.string().required('This field is required'),
                otherwise: yup.string()
            }),
        isCompressed: yup
            .mixed<string | boolean>()
            .required('This field is required')
            .typeError('Please select an option'),
        isEncrypted: yup
            .mixed<string | boolean>()
            .required('This field is required')
            .typeError('Please select an option'),
        isPasswordRequired: yup
            .mixed<string | boolean>()
            .required('This field is required')
            .typeError('Please select an option'),
        isReplacementOfPreviousDeposit: yup.mixed<string | boolean>(),
        isSubjectToAnyExportControls: yup
            .mixed<string | boolean>()
            .required('This field is required')
            .typeError('Please select an option'),
        password: yup
            .string()
            .when('isPasswordRequired', {
                is: 'yes',
                then: yup
                    .string()
                    .required('This field is required')
                    // .min(8, 'Password must exceed 8 characters')
                    .max(150, 'Password cannot exceed 150 characters')
            })
            .typeError('Please enter a valid password')
            .nullable(),
        personalDataDetails: yup.string().when('containsPersonalData', {
            is: 'yes',
            then: yup.string().required('This field is required'),
            otherwise: yup.string()
        })
    }),
    dataCentre: yup.string().nullable(),
    relatedAgreements: yup.array().of(yup.number().required()).required()
});

export default schema;
