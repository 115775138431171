import { PropsWithChildren } from 'react';

import cn from 'core/utils/cn';

interface AppBodyProps {
    className?: string;
    suppressPadding?: boolean;
}

function AppBody({
    children,
    className,
    suppressPadding = false
}: PropsWithChildren<AppBodyProps>) {
    return (
        <div
            className={cn(
                'absolute left-0 right-0 top-0 bottom-0 overflow-auto',
                !suppressPadding &&
                    'px-escode-body-padding-x py-escode-body-padding-y',
                className
            )}
        >
            {children}
        </div>
    );
}

export default AppBody;
