import { GetScheduledDepositStatsResponse } from '../../autogenerated/data-contracts';
import { UseQueryOptions, useQuery } from 'react-query';
import { isUndefined } from 'lodash';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';
import useDepositsStatsMockData from './use-deposits-stats-mock-data';

function useDepositsStats<TError = unknown>(
    options?: Omit<
        UseQueryOptions<
            GetScheduledDepositStatsResponse | undefined,
            ReactQueryError<TError>,
            GetScheduledDepositStatsResponse | undefined
        >,
        'queryKey' | 'queryFn'
    >,
    query?: { endDate: string; startDate: string }
) {
    const auth = useAuth();

    return useQuery(
        [
            'deposits-stats',
            'list',
            query,
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useDepositsStats');
                return await new Promise<GetScheduledDepositStatsResponse>(
                    (resolve) => {
                        setTimeout(() => {
                            resolve(useDepositsStatsMockData);
                        }, 2000);
                    }
                );
            }

            if (
                isUndefined(auth.user?.access_token) ||
                isUndefined(auth.user?.token_type)
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const response = await controller.v1SchedulesDepositStatsList(
                query
            );
            return response.data;
        },
        options
    );
}

export default useDepositsStats;
