import { ReactComponent as AppLogoWhite } from 'logo-white-text.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Outlet } from 'react-router-dom';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { faLeft } from '@fortawesome/pro-solid-svg-icons';

import '../ui/auth/auth.scss';
import { Button, Tooltip } from 'lib-ui';

function AuthLayout({ enableBack = false }) {
    const goBack = () => {
        window.history.back();
    };

    return (
        <div className="flex h-screen w-screen bg-gradient-to-r from-brand-escode-neonblue-neonblue-100 to-brand-escode-tourmalineblack-tourmalineblack-120">
            <div className="flex items-center justify-center h-full w-[50%] auth-texture">
                <div className="w-[50%]">
                    <AppLogoWhite className="flex items-start"></AppLogoWhite>
                    <h1 className="text-white text-[48px] font-semibold leading-tight">
                        Our secure online depositing portal.
                    </h1>
                </div>
            </div>
            <div className="flex items-center justify-center w-[50%] bg-white relative">
                {enableBack && (
                    <div className="absolute top-2 left-2">
                        <Tooltip
                            message={'Back'}
                            trigger="hover"
                            data-testid=""
                            placement="right"
                            panelProps={{ suppressTick: true }}
                        >
                            <Button onClick={goBack} variant="ghost">
                                <FontAwesomeIcon
                                    icon={faLeft}
                                    className="cursor-pointer text-brand-escode-neonblue-neonblue-100"
                                />
                            </Button>
                        </Tooltip>
                    </div>
                )}
                <div className="absolute top-2 right-2">
                    <Tooltip
                        message={'Download Deposit form'}
                        trigger="hover"
                        data-testid=""
                        placement="left"
                        panelProps={{ suppressTick: true }}
                    >
                        <a
                            href="/assets/downloads/blank_deposit_form.doc"
                            download
                        >
                            <Button variant="ghost">
                                <FontAwesomeIcon icon={faArrowDownToLine} />
                            </Button>
                        </a>
                    </Tooltip>
                </div>
                <div className="flex flex-col">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default AuthLayout;
