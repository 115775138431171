import { AgreementPartiesTableContext } from './agreement-parties-table-context';
import { AgreementPartyViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import { Button, ICellRendererParams } from 'lib-ui';
import { useCallback } from 'react';
import AgreementPartiesContactModal from './agreement-parties-contact-modal';

function AgreementPartyContactsCellRenderer({
    context: { push, t },
    data
}: ICellRendererParams<AgreementPartyViewModel, AgreementPartiesTableContext>) {
    const viewContactDetails = useCallback(() => {
        push(AgreementPartiesContactModal, {
            agreementPartyContacts: data?.agreementPartyContacts
        });
    }, [data?.agreementPartyContacts, push]);

    return (
        <div className="py-3 flex flex-col">
            <Button onClick={viewContactDetails} variant="tertiary">
                {t('agreement-party-contact-details')}
            </Button>
        </div>
    );
}

export default AgreementPartyContactsCellRenderer;
