import { UseQueryOptions, useQuery } from 'react-query';
import { isUndefined } from 'lodash';

import { ApiController } from '../../autogenerated/Api';
import { User } from 'oidc-client-ts';
import { UserResponseViewModel } from '../../autogenerated/data-contracts';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';
import useUserDetailsMockData from './use-user-details-mock-data';

function useUserDetails<TError = unknown>(
    user: User | null,
    options?: Omit<
        UseQueryOptions<
            UserResponseViewModel | undefined,
            ReactQueryError<TError>,
            UserResponseViewModel | undefined
        >,
        'queryKey' | 'queryFn'
    >
) {
    return useQuery<
        UserResponseViewModel | undefined,
        ReactQueryError<TError>,
        UserResponseViewModel | undefined
    >(
        [
            'user',
            'details',
            { token: user?.access_token, tokenType: user?.token_type }
        ],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useUserDetails');
                // return Promise.reject({ response: { status: 404 } });
                return useUserDetailsMockData;
            }

            if (
                isUndefined(user?.access_token) ||
                isUndefined(user?.token_type)
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    user?.token_type,
                    user?.access_token
                )
            });

            const response = await controller.getUserAccountDetails();
            return response.data;
        },
        options
    );
}

export default useUserDetails;
