import { UpdateUserDetailsRequestViewModel } from '../../autogenerated/data-contracts';

const useUpdateUserDetailsMockData: UpdateUserDetailsRequestViewModel = {
    accountManagerFullName: 'Bill Withers',
    addressLine1: '123 Test Street',
    addressLine2: 'Testville',
    addressLine3: 'Testshire',
    emailAddress: 'johndoe@testmail.com',
    firstName: 'John',
    id: '123456',
    jobTitle: 'Tester',
    lastName: 'Doe',
    postcode: 'TE5 T12',
    telephoneNumber: '01234567890',
    title: 'Mr',
    uiCulture: 'en-GB',
    userName: 'johndoe@testmail.com'
};

export { useUpdateUserDetailsMockData };
