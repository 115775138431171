import { useQuery } from 'react-query';

import { ApiController } from '../../autogenerated/Api';

import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

function useDepositFileTypes() {
    const auth = useAuth();

    return useQuery(
        [
            'deposit',
            'fileTypes',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async () => {
            if (
                auth.user?.access_token === undefined ||
                auth.user?.token_type === undefined
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const response = await controller.getDepositFileTypes(1);
            return response.data;
        }
    );
}

export default useDepositFileTypes;
