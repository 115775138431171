import { ApiController } from '../../autogenerated/Api';
import { AxiosResponse } from 'axios';
import { ContactUsRequest } from '../../autogenerated/data-contracts';
import { MutationOptions, useMutation } from 'react-query';
import { RequestParams } from '../../autogenerated/http-client';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

type CreateFeedbackType = {
    data: ContactUsRequest;
    params?: RequestParams;
};

function useContactUs<TError = unknown, TContext = unknown>(
    // data: NewPortalFeedbackRequest,
    options?: Omit<
        MutationOptions<
            AxiosResponse<void, unknown>,
            ReactQueryError<TError>,
            CreateFeedbackType,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation<
        AxiosResponse,
        ReactQueryError<TError>,
        CreateFeedbackType,
        TContext
    >(
        [
            {
                token: auth.user?.access_token,
                tokenType: auth.user?.token_type
            }
        ],
        async ({ data, params }) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Mock is enabled useContactUs call ignored.');
                return Promise.resolve({} as AxiosResponse<void, unknown>);
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });
            return controller.contactUs(data, params);
        },
        {
            ...options
        }
    );
}

export default useContactUs;
