import { ApiController } from '../../autogenerated/Api';
import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation } from 'react-query';
import { useAuth } from 'react-oidc-context';
import interceptors from 'core/api/interceptors';
import useDepositNowStore from 'core/stores/use-deposit-now-store';

type DepositConfirmUpdateType = {
    depositUniqueReference: string;
};

function useUploadsComplete<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void, unknown>,
            ReactQueryError<TError>,
            DepositConfirmUpdateType,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();
    const { VVToken, spectreAgentUrl } = useDepositNowStore();

    return useMutation<
        AxiosResponse,
        ReactQueryError<TError>,
        DepositConfirmUpdateType,
        TContext
    >(
        [
            'useUploadsComplete',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async ({ depositUniqueReference }: DepositConfirmUpdateType) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useUploadsComplete call ignored.'
                );
                return Promise.resolve({} as AxiosResponse);
            }


            const controller = new ApiController({
                baseURL: spectreAgentUrl,
                headers: {
                    Authorization: `Bearer ${VVToken}`,
                    'Cache-FormControl': 'no-cache, no-store, must-revalidate',
                    Expires: '0',
                    Pragma: 'no-cache'
                },
                interceptors
            });

            return controller.uploadsComplete(depositUniqueReference, {
                headers: {
                    Authorization: `Bearer ${VVToken}`
                }
            });
        },
        {
            ...options
        }
    );
}

export default useUploadsComplete;
