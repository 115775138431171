import * as yup from 'yup';
import { Button, InputField, Modal, SelectField, TextareaField } from 'lib-ui';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';

import { ContactCategory } from 'core/api/client-portal/autogenerated/data-contracts';
import toastFunctions from 'lib-ui/toast/Toast';
import useContactUs from 'core/api/client-portal/hooks/contact/use-contact-us';

interface ContactFormData {
    contactCategory: ContactCategory;
    contactEmail: string;
    contactName: string;
    contactRequest: string;
    contactTelephone: string;
}

const requiredMessage = 'This is required';
const schema = yup.object({
    contactCategory: yup.string().required(requiredMessage),
    contactEmail: yup
        .string()
        .email('Invalid email address')
        .required(requiredMessage),
    contactName: yup.string().required(requiredMessage),
    contactRequest: yup.string().required(requiredMessage),
    contactTelephone: yup.string().required(requiredMessage)
});

const ContactUsModal = () => {
    const { t } = useTranslation();
    const { pop } = useModals();

    const { mutate: submitRequest } = useContactUs();

    const methods = useForm<ContactFormData>({
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema)
    });

    const submit: SubmitHandler<ContactFormData> = (formData) => {
        submitRequest(
            { data: formData },
            {
                onError: () => {
                    toastFunctions.error(t('contact-us.error'));
                },
                onSuccess: () => {
                    pop();
                    toastFunctions.success(t('contact-us.success'));
                }
            }
        );
    };

    return (
        <Modal size="m">
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(submit)}>
                    <Modal.Header>{t('contact-us.heading')}</Modal.Header>
                    <Modal.Body>
                        <div className="w-full flex gap-4 justify-center">
                            <a
                                className="tracking-wide text-brand-escode-neonblue-neonblue-100 cursor-pointer hover:underline"
                                href="tel:+441612095209"
                            >
                                +44 (0) 161 209 5209
                            </a>{' '}
                            |{' '}
                            <a
                                className="tracking-wide text-brand-escode-neonblue-neonblue-100 cursor-pointer hover:underline"
                                href="tel:+18008133523"
                            >
                                +1 (800) 813 3523
                            </a>
                        </div>
                        <div className="flex flex-col gap-6">
                            <InputField label="Full Name" name="contactName" />
                            <InputField
                                label="Email Address"
                                name="contactEmail"
                            />
                            <InputField
                                label="Telephone Number"
                                name="contactTelephone"
                            />
                            <SelectField
                                label="Subject"
                                name="contactCategory"
                                options={[
                                    {
                                        label: 'Sales Enquiry',
                                        value: '0'
                                    },
                                    {
                                        label: 'Escrow Deposit',
                                        value: '1'
                                    },
                                    {
                                        label: 'Escrow Agreement',
                                        value: '2'
                                    },
                                    {
                                        label: 'Invoicing',
                                        value: '3'
                                    },
                                    {
                                        label: 'Document Enquiry',
                                        value: '4'
                                    }
                                ]}
                                placeholder={'Please select an option'}
                            />
                            <TextareaField
                                label="Request"
                                name="contactRequest"
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer suppressCloseButton>
                        <Button onClick={() => pop()} variant="danger">
                            {t('contact-us.cancel')}
                        </Button>
                        <Button variant="primary">
                            {t('contact-us.submit')}
                        </Button>
                    </Modal.Footer>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default ContactUsModal;
export type { ContactFormData };
