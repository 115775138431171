import { MutationOptions, useMutation, useQueryClient } from 'react-query';
import { ProblemDetails } from '../../autogenerated/data-contracts';
import { useAuth } from 'react-oidc-context';
import axios, { AxiosResponse } from 'axios';
import useDepositNowStore from 'core/stores/use-deposit-now-store';

type DeleteFileParams = {
    depositUniqueReference: string | null | undefined;
    uniqueReference: string | undefined;
};

function useDeleteFile<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void, unknown>,
            ReactQueryError<TError>,
            DeleteFileParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const queryClient = useQueryClient();
    const auth = useAuth();
    const { VVToken, spectreAgentUrl } = useDepositNowStore();

    return useMutation<
        AxiosResponse,
        ReactQueryError<TError>,
        DeleteFileParams,
        TContext
    >(
        [
            {
                token: auth.user?.access_token,
                tokenType: auth.user?.token_type
            }
        ],
        async ({ depositUniqueReference, uniqueReference }) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Mock is enabled useDeleteFile call ignored.', {
                    depositUniqueReference,
                    uniqueReference
                });
                return Promise.resolve({} as AxiosResponse<void, unknown>);
            }

            const baseURL = spectreAgentUrl;

            const uploadsFilesDelete = async (
                depositUniqueReference: string | null | undefined,
                uniqueReference: string | undefined,
                params = {}
            ): Promise<AxiosResponse<void | ProblemDetails>> => {
                const axiosInstance = axios.create({
                    baseURL,
                    headers: {
                        Authorization: `Bearer ${VVToken}`,
                        'Cache-Control': 'no-cache, no-store, must-revalidate',
                        Expires: '0',
                        Pragma: 'no-cache'
                    }
                });

                const deleteUrl = axiosInstance.delete<void | ProblemDetails>(
                    `/api/uploads/${depositUniqueReference}/${uniqueReference}`,
                    params
                );

                try {
                    return await deleteUrl

                } catch (error) {
                    if (axios.isAxiosError(error) && error.response?.status === 401) {
                        await queryClient.invalidateQueries('use-request-token');

                        return await deleteUrl
                    }
                    throw error;
                }
            };

            return await uploadsFilesDelete(depositUniqueReference, uniqueReference);
        },
        {
            ...options
        }
    );
}

export default useDeleteFile;
