import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'lib-ui/button/button';

function Login() {
    const { t } = useTranslation();
    const { signinRedirect } = useAuth();
    const navigate = useNavigate();

    return (
        <div>
            <h1 className="text-general-grey-grey-90 text-3xl">
                {t('auth.login.title')}{' '}
                <span className="font-semibold text-brand-escode-neonblue-neonblue-100">
                    {t('auth.login.title2')}
                </span>
            </h1>
            <h2 className="text-general-grey-grey-90 pb-9.5 pt-escode-m text-xl">
                {t('auth.login.title3')}
            </h2>
            <div className="flex w-full mb-1 text-sm gap-1">
                Before using View, you can review our{' '}
                <a
                    className="text-brand-escode-neonblue-neonblue-100 underline "
                    href="https://www.escode.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                >
                    {t('privacy-policy')}
                </a>
            </div>
            <div className="flex w-full mb-4 text-sm gap-1">
                and our{' '}
                <button
                    className="text-brand-escode-neonblue-neonblue-100 underline"
                    onClick={() => navigate(`/terms`)}
                >
                    {t('terms-and-conditions')}
                </button>
                {/* </a> */}
            </div>
            <Button
                className="w-full"
                variant="primary"
                data-testid="signin-button"
                onClick={() => signinRedirect()}
            >
                {t('auth.login.siginButton')}
            </Button>
            <div className="flex items-center justify-center border-b border-general-grey-grey-30 my-9.5">
                <div className="bg-white text-general-grey-grey-90 p-4 absolute text-[14px]">
                    {t('auth.login.or')}
                </div>
            </div>
            <Button
                className="w-full"
                variant="tertiary"
                data-testid="signin-button"
                onClick={() => navigate(`/register`)}
            >
                {t('auth.login.registerButton')}
            </Button>
        </div>
    );
}

export default Login;
