/* eslint-disable @typescript-eslint/no-explicit-any */

export const styles = (theme: string, hideIcons?: any, hasError?: boolean) => {
    let result: { [key: string]: any } = {};
    if (theme === 'light') {
        result = {
            control: (provided: any, state: any) => ({
                ...provided,
                '&:hover': {
                    borderColor: hasError ? '#d0003b' : '#c4c7cf'
                },
                background: state.isDisabled ? '#e9ecf2' : '#FFFFFF',
                border: state.isFocused
                    ? '1px solid #c4c7cf'
                    : hasError
                    ? '1px solid #d0003b'
                    : '1px solid #c4c7cf',
                borderRadius: '8px',
                boxShadow: state.isFocused ? '0 0 0 0.5px #000FFF' : 'none',
                color: '#888888',
                display: 'flex',
                flexWrap: 'wrap',
                font: 'Inter',
                fontSize: '16px',
                fontWeight: 400,
                height: 'auto',
                minHeight: '40px'
            }),
            input: (provided: any, state: any) => ({
                ...provided
            }),
            menuList: (provided: any) => ({
                ...provided,
                backgroundColor: '#FFFFFF',
                borderRadius: '8px'
            }),
            multiValue: (provided: any) => ({
                ...provided,
                backgroundColor: '#e6e6e6',
                borderRadius: '4px',
                fontSize: '16px',
                margin: '2px'
            }),
            option: (provided: any, state: { isSelected: any }) => ({
                ...provided,
                '&:hover': {
                    background: '#000FFF',
                    color: '#FFFFFF'
                },
                background: state.isSelected ? '#000FFF' : '#FFFFFF',
                color: state.isSelected ? '#FFFFFF' : '#888888',
                font: 'IBM Plex Sans',
                fontSize: '14px',
                fontWeight: 400,
                padding: '5px 10px'
            }),
            placeholder: (provided: any, state: { isSelected: any }) => ({
                ...provided,
                color: '#a1a5b1',
                font: 'IBM Plex Sans',
                fontSize: '14px',
                fontWeight: 400
            }),
            singleValue: (provided: any, state: { isDisabled: any }) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = 'opacity 300ms';
                const fontSize = '14px';

                return { ...provided, fontSize, opacity, transition };
            },
            valueContainer: (provided: any) => ({
                ...provided,
                fontSize: '14px',
                paddingLeft: '15px'
            })
        };
    }

    if (hideIcons) {
        result.dropdownIndicator = () => ({ display: 'none' });
        result.indicatorSeparator = () => ({ display: 'none' });
    }
    return result;
};
