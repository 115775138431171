import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import React, { ReactNode, useState } from 'react';
import cn from 'core/utils/cn';

interface CollapsiblePanelProps {
    items: {
        detail: string;
        summary: string | ReactNode;
    }[];
}

const CollapsiblePanel = ({ items }: CollapsiblePanelProps) => {
    const [openIndices, setOpenIndices] = useState<number[]>([]);
    const toggle = (index: number) => {
        if (openIndices.includes(index)) {
            setOpenIndices(openIndices.filter((i) => i !== index));
        } else {
            setOpenIndices([...openIndices, index]);
        }
    };

    return (
        <div className="flex flex-col gap-4">
            {items.map((item, index) => (
                <div
                    key={index}
                    className="border border-general-grey-grey-30 rounded-md bg-white"
                >
                    <div
                        className="flex gap-2 items-center justify-between cursor-pointer p-4 rounded-md text-brand-escode-neonblue-neonblue-100 bg-white  transition-colors duration-300"
                        onClick={() => toggle(index)}
                    >
                        <div className="text-lg font-medium">{item.detail}</div>
                        <div>
                            <FontAwesomeIcon
                                className={cn(
                                    'transition-transform duration-300 transform',
                                    openIndices.includes(index) && 'rotate-90'
                                )}
                                icon={faChevronRight}
                            />
                        </div>
                    </div>
                    <div
                        className={cn(
                            'overflow-hidden font-normal rounded-b-md transition-all duration-300 px-4',
                            openIndices.includes(index)
                                ? 'max-h-96 p-4 pt-0 bg-white'
                                : 'max-h-0'
                        )}
                    >
                        {item.summary}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CollapsiblePanel;
export type { CollapsiblePanelProps };
