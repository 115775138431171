import { GetScheduledDepositStatsResponse } from 'core/api/client-portal/autogenerated/data-contracts';
import { useTranslation } from 'react-i18next';

import SchedulesMetricPanel from '../schedules-metric-panel';

type PreviousMonthSchedulesProps = {
    data: GetScheduledDepositStatsResponse | undefined;
    loading?: boolean;
};

function PreviousMonthSchedules({
    data,
    loading
}: PreviousMonthSchedulesProps) {
    const { t } = useTranslation();

    return (
        <SchedulesMetricPanel
            data={data}
            title={t('schedules.metrics.previous-month')}
            loading={loading}
        />
    );
}

export default PreviousMonthSchedules;
