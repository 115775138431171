/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider, useForm } from 'react-hook-form';
import { faArrowUpLeftFromCircle } from '@fortawesome/pro-regular-svg-icons';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo } from 'react';

import {
    Button,
    CheckField,
    ChoiceField,
    DatePickField,
    InputField,
    SelectFieldProps,
    TextareaField
} from 'lib-ui';
import { uniquePhoneOptions } from 'core/utils/countries';
import SettingsContentSection from '../settings-content-section';
import TerminationSuccessModal from './termination-success-modal';
import toastFunctions from 'lib-ui/toast/Toast';
import useAgreementsInitiateTermination from 'core/api/client-portal/hooks/agreements/use-agreements-initiate-termination';
import useAgreementsList from 'core/api/client-portal/hooks/agreements/use-agreements-list';

const requiredMessage = 'This field is required';
const schema = yup.object({
    address: yup.string().required(requiredMessage),
    agreementToTerminate: yup.string().required(requiredMessage),
    comments: yup.string(),
    company: yup.string().required(requiredMessage),
    confirmed: yup
        .boolean()
        .oneOf([true], 'This is a required field')
        .typeError('This is a required field'),
    dateOfTermination: yup.string().required(requiredMessage),
    email: yup.string().required(requiredMessage),
    fullName: yup.string().required(requiredMessage),
    jobTitle: yup.string().required(requiredMessage),
    partyType: yup.string().required(requiredMessage),
    phone: yup.string().required(requiredMessage),
    prePhone: yup.string().required(requiredMessage),
    reason: yup.string().required(requiredMessage)
});

const Termination = () => {
    const { t } = useTranslation();
    const { push } = useModals();

    const { mutate: initiateTermination } = useAgreementsInitiateTermination({
        onError: () => {
            toastFunctions.error(t('settings.termination.toast.error'));
        },
        onSuccess: () => {
            push(TerminationSuccessModal, {});
            methods.reset();
        }
    });

    const { data: agreementsToTerminate } = useAgreementsList({
        DepositNow: false,
        IncludeLiveOnly: false
    });

    const methods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema)
    });

    const submit = (formData: any) => {
        const { confirmed, ...rest } = formData;
        const data = {
            ...rest
        };

        initiateTermination({ data });
    };

    const agreementNumbers = agreementsToTerminate?.agreements!.map(
        (agreement) => ({
            agreementId: agreement.agreementId,
            agreementNumber: agreement.agreementNumber
        })
    );

    const agreementToTerminateOptions = useMemo<SelectFieldProps['options']>(
        () =>
            agreementNumbers?.map((agreement) => ({
                id: agreement.agreementId!.toString(),
                label: agreement.agreementNumber?.toString(),
                value: agreement.agreementId!.toString()
            })) || [],
        [agreementNumbers]
    );

    const partyTypeOptions = useMemo(
        () => [
            {
                id: '1',
                label: t('termination-form.software-vendor'),
                value: 'Software Vendor'
            },
            {
                id: '2',
                label: t('termination-form.software-license'),
                value: 'Software Licensee'
            }
        ],
        [t]
    );

    return (
        <FormProvider {...methods}>
            <form
                className="h-full w-full pb-6 border-l border-general-grey-grey-30 "
                onSubmit={methods.handleSubmit(submit)}
            >
                <div className="flex gap-4 items-center justify-between h-[60px] px-7.5 border-b-2 border-general-grey-grey-30">
                    <div className="flex gap-4 items-center">
                        <FontAwesomeIcon
                            icon={faArrowUpLeftFromCircle}
                            className=" h-6 w-6"
                        />
                        <h2 className="text-xl font-medium">
                            {t('settings.termination.title')}
                        </h2>
                    </div>
                    <Button variant="primary">
                        {t('settings.termination.action-button')}
                    </Button>
                </div>
                <div className="p-7.5 h-full overflow-y-scroll space-y-11">
                    <SettingsContentSection
                        description={t('settings.termination.description-one')}
                        subtitle={t('settings.termination.subtitle-one')}
                    >
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-8">
                                <div className="flex flex-1 gap-2">
                                    <InputField
                                        className="flex-1"
                                        name="fullName"
                                        label={t(
                                            'settings.termination.form.full-name'
                                        )}
                                        placeholder="Adam Smith"
                                    />
                                    <InputField
                                        className="flex-1"
                                        name="jobTitle"
                                        label={t(
                                            'settings.termination.form.job-title'
                                        )}
                                        placeholder="Data Analyst"
                                    />
                                </div>
                                <div className="flex flex-1 gap-2">
                                    <InputField
                                        className="flex-1"
                                        name="company"
                                        label={t(
                                            'settings.termination.form.company'
                                        )}
                                        placeholder="adam.smith@example.com"
                                    />
                                    <ChoiceField
                                        name="prePhone"
                                        placeholder="+44"
                                        label={t(
                                            'settings.termination.form.pre-phone'
                                        )}
                                        options={uniquePhoneOptions}
                                        className="w-24"
                                    />
                                    <InputField
                                        className="flex-1"
                                        name="phone"
                                        label={t(
                                            'settings.termination.form.phone-number'
                                        )}
                                        placeholder="0712 3456789"
                                    />
                                    <InputField
                                        className="flex-1"
                                        name="email"
                                        label={t(
                                            'settings.personal.form.email'
                                        )}
                                        placeholder="adam.smith@example.com"
                                    />
                                </div>
                            </div>
                            <div className="flex space-x-4">
                                <InputField
                                    className="flex-1"
                                    name="address"
                                    label={t(
                                        'settings.termination.form.address'
                                    )}
                                    placeholder="Data Analyst"
                                />
                            </div>
                            <div className="flex gap-4">
                                <div className="w-full">
                                    <DatePickField
                                        enableViewButtons
                                        label={t(
                                            'settings.termination.form.termination-date'
                                        )}
                                        name="dateOfTermination"
                                        placeholder="DD/MM/YYYY"
                                    />
                                </div>
                                <div className="w-full">
                                    <ChoiceField
                                        label={t(
                                            'settings.termination.form.agreement-terminate'
                                        )}
                                        placeholder={t(
                                            'termination-form.agreement-terminate-placeholder'
                                        )}
                                        name="agreementToTerminate"
                                        options={agreementToTerminateOptions}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-8">
                                <TextareaField
                                    name="reason"
                                    label={t(
                                        'settings.termination.form.termination-reason'
                                    )}
                                    placeholder={t(
                                        'termination-form.reason-placeholder'
                                    )}
                                    tailwindStyle={{
                                        width: 'w-full'
                                    }}
                                />
                                <TextareaField
                                    name="comments"
                                    label={t(
                                        'settings.termination.form.comments'
                                    )}
                                    placeholder={t(
                                        'termination-form.comments-placeholder'
                                    )}
                                    tailwindStyle={{
                                        width: 'w-full'
                                    }}
                                />
                                <ChoiceField
                                    id="partyType"
                                    label={t(
                                        'settings.termination.form.party-type'
                                    )}
                                    name="partyType"
                                    options={partyTypeOptions}
                                    placeholder={t(
                                        'termination-form.party-type-placeholder'
                                    )}
                                />
                                <CheckField
                                    errorPosition="bottom"
                                    id="confirmed"
                                    label={t(
                                        'settings.termination.form.termination-confirm'
                                    )}
                                    name="confirmed"
                                    orientation="checkbox-first"
                                />
                            </div>
                        </div>
                    </SettingsContentSection>
                    <div className="h-[300px]" />
                </div>
            </form>
        </FormProvider>
    );
};

export default Termination;
