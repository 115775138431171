import { Dropdown } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Lang } from 'localization';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import SystemLanguagePanel from './system-language-panel';
import cn from 'core/utils/cn';

const langMapping: Record<string, string> = {
    [Lang.de]: 'Deutsch (Deutschland)',
    [Lang.deDE]: 'Deutsch (Deutschland)',
    [Lang.en]: 'English (United Kingdom)',
    [Lang.enGB]: 'English (United Kingdom)',
    [Lang.enUS]: 'English (United States)',
    [Lang.esES]: 'Español (España)',
    [Lang.frFR]: 'Français (France)',
    [Lang.nl]: 'Dutch (Nederland)'
};

interface LanguageSelectProps {
    label?: string;
    placeholder?: string;
}

function LanguageSelect({ label, placeholder }: LanguageSelectProps) {
    const { i18n } = useTranslation();
    const panelId = 'language';

    return (
        <Dropdown.Trigger
            className="w-full relative"
            panelId={panelId}
            panel={<SystemLanguagePanel />}
        >
            <label
                htmlFor="language"
                className={cn(
                    'flex-1 flex items-center gap-1 text-general-grey-grey-90 text-base mb-2'
                )}
            >
                <span className="space-x-1">{label}</span>
            </label>
            <input
                className="h-[40px] bg-transparent border border-general-grey-grey-40 w-full px-4 py-2 text-sm focus:outline-none transition-all text-general-grey-grey-100 rounded-lg select-none caret-transparent"
                id="language"
                placeholder={placeholder}
                value={langMapping[i18n.language]}
            />
            <FontAwesomeIcon
                icon={faCaretDown}
                className="absolute right-4 top-[42px]"
            />
        </Dropdown.Trigger>
    );
}

export default LanguageSelect;
