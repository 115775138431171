import { useFormContext, useWatch } from 'react-hook-form';
import { useId } from 'react';

import Field, { FieldProps } from './field';
import Select, { SelectProps } from '../element/select';
import getNestedError from 'core/utils/get-nested-error';

interface SelectFieldProps
    extends FieldProps,
        Omit<SelectProps, 'name' | 'id' | 'error'> {
    fieldStyles?: string;
}

function SelectField({
    disabled,
    fieldStyles,
    label,
    name,
    orientation,
    readOnly,
    required,
    ...restProps
}: SelectFieldProps) {
    const {
        formState: { errors },
        register
    } = useFormContext();
    const formValue = useWatch({ name });
    const id = useId();

    const fieldError = getNestedError(name, errors);

    return (
        <Field
            as="label"
            className={fieldStyles}
            name={name}
            label={label}
            orientation={orientation}
            readOnly={readOnly}
            disabled={disabled}
            id={id}
            required={required}
            error={fieldError ? <>{fieldError}</> : undefined}
        >
            <Select
                {...restProps}
                id={id}
                {...register(name)}
                label={label}
                // readOnly={readOnly}
                required={required}
                disabled={disabled}
                value={formValue}
                error={!!fieldError}
            />
        </Field>
    );
}

export default SelectField;
export type { SelectFieldProps };
