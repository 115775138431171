import { useFormContext } from 'react-hook-form';
import { useId } from 'react';

import Field, { FieldProps } from './field';
import Textarea, { TextareaProps } from '../element/textarea';
import getNestedError from 'core/utils/get-nested-error';

interface TextareaFieldProps
    extends FieldProps,
        Omit<TextareaProps, 'name' | 'id' | 'error'> {}

function TextareaField({
    disabled,
    label,
    name,
    orientation,
    readOnly,
    required,
    tooltip,
    ...restProps
}: TextareaFieldProps) {
    const {
        formState: { errors },
        register
    } = useFormContext();
    const id = useId();

    const fieldError = getNestedError(name, errors);

    return (
        <Field
            as="label"
            error={fieldError ? <>{fieldError}</> : undefined}
            name={name}
            label={label}
            orientation={orientation}
            readOnly={readOnly}
            disabled={disabled}
            id={id}
            required={required}
            tooltip={tooltip}
        >
            <Textarea
                {...restProps}
                id={id}
                {...register(name)}
                readOnly={readOnly}
                disabled={disabled}
                error={!!fieldError}
            />
        </Field>
    );
}

export default TextareaField;
export type { TextareaFieldProps };
