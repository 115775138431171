import { ChoiceField, InputField } from 'lib-ui';
import { uniquePhoneOptions } from 'core/utils/countries';
import { useTranslation } from 'react-i18next';
import React from 'react';

const PersonalInformationStep = () => {
    const { t } = useTranslation();

    return (
        <section
            data-testid="personal-information"
            className="flex flex-col gap-4 mb-8"
        >
            <div className="flex gap-4">
                <InputField
                    name="fullName"
                    label={t('termination-form.full-name')}
                    placeholder={t('termination-form.full-name-placeholder')}
                    tailwindStyle={{
                        width: 'w-full'
                    }}
                />

                <InputField
                    name="jobTitle"
                    label={t('termination-form.job-title')}
                    placeholder={t('termination-form.job-title-placeholder')}
                    tailwindStyle={{
                        width: 'w-full'
                    }}
                />
            </div>
            <div className="w-full flex gap-4">
                <InputField
                    name="company"
                    type="text"
                    label={t('termination-form.company')}
                    placeholder={t('termination-form.company-placeholder')}
                    className="flex-1"
                />
                <div className="flex flex-1 gap-2">
                    <ChoiceField
                        name="prePhone"
                        placeholder="+44"
                        label={t('termination-form.pre-phone')}
                        options={uniquePhoneOptions}
                        className="w-48"
                    />
                    <InputField
                        label={t('termination-form.phone')}
                        name="phone"
                        placeholder={t(
                            'deposit-now-stage-two.phone-placeholder'
                        )}
                        className="w-full"
                    />
                </div>
            </div>

            <InputField
                name="email"
                type="email"
                label={t('termination-form.email')}
                placeholder={t('termination-form.email-placeholder')}
                tailwindStyle={{
                    width: 'w-full'
                }}
            />
            <InputField
                name="address"
                type="text"
                label={t('termination-form.address')}
                placeholder={t('termination-form.address-placeholder')}
                tailwindStyle={{
                    width: 'w-full'
                }}
            />
        </section>
    );
};

export default PersonalInformationStep;
