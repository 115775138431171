import { InputProps as CoreInputProps } from '@ncc-frontend/core';
import { forwardRef } from 'react';
import { memo } from 'react';
import cn from 'core/utils/cn';

import './input.css';

interface InputProps extends CoreInputProps {
    error?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
    ({ error, wrapperTailwindStyle, ...restProps }, ref) => {
        const cssClasses = cn(
            'h-[40px] bg-transparent border border-general-grey-grey-40 rounded-md w-full px-4 py-2 text-sm focus:outline-none transition-all text-general-grey-grey-100 rounded-lg',
            error && 'border-general-red-red-100',
            !error &&
                'focus:border-brand-escode-neonblue-neonblue-100 focus-within:border-brand-escode-neonblue-neonblue-100',
            restProps.disabled && 'bg-general-grey-grey-20'
        );

        return <input className={cssClasses} {...restProps} ref={ref} />;
    }
);

export default memo(Input);
export type { InputProps };
