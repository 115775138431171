/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import { useMutation } from 'react-query';

import { UpdateUserDetailsRequestViewModel } from '../../autogenerated/data-contracts';
import { useUpdateUserDetailsMockData } from './use-update-user-details-mock-data';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/security-worker';

function useUpdateUserDetails(options?: any) {
    const auth = useAuth();

    return useMutation(
        ['settings', 'update-user-details'],
        async (data: UpdateUserDetailsRequestViewModel) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Using mock data for useUpdateUserDetails');
                return useUpdateUserDetailsMockData;
            }

            if (
                auth.user?.access_token === undefined ||
                auth.user?.token_type === undefined
            ) {
                return undefined;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const response = await controller.updateUserAccountDetails(data);
            return response.data;
        },
        { ...options }
    );
}

export default useUpdateUserDetails;
