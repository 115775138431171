import { useTranslation } from 'react-i18next';
import React from 'react';

import { Button, Modal } from 'lib-ui';
import { useModals } from '@ncc-frontend/core';

const TerminationSuccessModal = () => {
    const { t } = useTranslation();
    const { pop } = useModals();

    return (
        <Modal>
            <Modal.Header>{t('settings.termination-modal.title')}</Modal.Header>
            <Modal.Body>{t('settings.termination-modal.text')}</Modal.Body>

            <Modal.Footer suppressCloseButton>
                <Button onClick={pop} variant="danger">
                    {t('settings.termination-modal.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TerminationSuccessModal;
