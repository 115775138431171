import { GetScheduledDepositStatsResponse } from 'core/api/client-portal/autogenerated/data-contracts';

import SchedulesMetric from './schedules-metric';

interface SchedulesMetricPanelProps {
    data: GetScheduledDepositStatsResponse | undefined;
    loading?: boolean;
    title?: string;
}

function SchedulesMetricPanel({ data, title }: SchedulesMetricPanelProps) {
    const total = data
        ? (data.daily ?? 0) +
          (data.weekly ?? 0) +
          (data.monthly ?? 0) +
          (data.quarterly ?? 0) +
          (data.annual ?? 0)
        : 0;
    const factor = 100 / total;

    return (
        <div className="flex flex-col gap-5 border px-6 pt-5 pb-7.5 border-general-grey-grey-30 rounded-lg">
            <div className="pb-3.5 border-b border-escode-grey-20">
                <div className="text-general-grey-grey-90 text-sm">{title}</div>
                <div className="text-general-grey-grey-90 text-xl font-semibold">
                    {total || 0}
                </div>
            </div>
            <SchedulesMetric
                variant="daily"
                value={(data?.daily ?? 0) * factor}
            >
                {data?.daily || 0}
            </SchedulesMetric>
            <SchedulesMetric
                variant="weekly"
                value={(data?.weekly ?? 0) * factor}
            >
                {data?.weekly || 0}
            </SchedulesMetric>
            <SchedulesMetric
                variant="monthly"
                value={(data?.monthly ?? 0) * factor}
            >
                {data?.monthly || 0}
            </SchedulesMetric>
            <SchedulesMetric
                variant="quarterly"
                value={(data?.quarterly ?? 0) * factor}
            >
                {data?.quarterly || 0}
            </SchedulesMetric>
            <SchedulesMetric
                variant="annually"
                value={(data?.annual ?? 0) * factor}
            >
                {data?.annual || 0}
            </SchedulesMetric>
        </div>
    );
}

export default SchedulesMetricPanel;
export type { SchedulesMetricPanelProps };
