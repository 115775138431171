import { Lang } from 'localization';
import { Menu } from 'lib-ui';
import { PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import deleteFromStorage from 'core/utils/local-storage-remover';

const langMapping: Record<string, string> = {
    [Lang.de]: Lang.deDE,
    [Lang.deDE]: Lang.deDE,
    [Lang.en]: Lang.enGB,
    [Lang.enGB]: Lang.enGB,
    [Lang.enUS]: Lang.enUS,
    [Lang.esES]: Lang.esES,
    [Lang.frFR]: Lang.frFR,
    [Lang.nl]: Lang.nl
};

interface LanguageOptionProps {
    lang: Lang;
}

function LanguageOption({
    children,
    lang,
    ...restProps
}: PropsWithChildren<LanguageOptionProps>) {
    const { i18n } = useTranslation();

    const changeLang = useCallback(() => {
        i18n.changeLanguage(lang);
        deleteFromStorage('current-filter');
        window.location.reload();
    }, [i18n, lang]);

    return (
        <Menu.Item
            {...restProps}
            onClick={changeLang}
            active={langMapping[i18n.language] === lang}
            className="text-center"
            suppressIcon
        >
            {children}
        </Menu.Item>
    );
}

export default LanguageOption;
export type { LanguageOptionProps };
export { langMapping };
