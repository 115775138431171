import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { faPaperPlane, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';

import './support.scss';
import { ContactFormData } from 'ui/help/contact-us-modal';
import { InputField, SelectField, TextareaField } from 'lib-ui';
import Button from 'lib-ui/button/button';
import SupportSuccessModal from './support-success-modal';
import toastFunctions from 'lib-ui/toast/Toast';
import useContactUs from 'core/api/client-portal/hooks/contact/use-contact-us';

const requiredMessage = 'This is required';
const schema = yup.object({
    contactCategory: yup.string().required(requiredMessage),
    contactEmail: yup
        .string()
        .email('Invalid email address')
        .required(requiredMessage),
    contactName: yup.string().required(requiredMessage),
    contactRequest: yup.string().required(requiredMessage),
    contactTelephone: yup.string().required(requiredMessage)
});

const Support = () => {
    const { t } = useTranslation();
    const { push } = useModals();
    const { mutate: submitRequest } = useContactUs();

    const methods = useForm<ContactFormData>({
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema)
    });

    const submit: SubmitHandler<ContactFormData> = (formData) => {
        submitRequest(
            { data: formData },
            {
                onError: () => {
                    toastFunctions.error(t('contact-us.error'));
                },
                onSuccess: () => {
                    push(SupportSuccessModal, {});
                    methods.reset();
                }
            }
        );
    };

    return (
        <div className="h-full flex">
            <div className="h-full w-[40%]">
                <div className="h-full w-full bg-gradient-to-r from-escode-overlay-texture-light to-escode-overlay-texture-dark rounded-2x text-white flex flex-col items-center justify-center">
                    <div className="h-[60%] px-44 flex flex-col justify-between">
                        <div className="flex flex-col gap-4">
                            <h1 className="text-[2.50rem] font-semibold">
                                NCC {t('support-page.left-title')}
                            </h1>
                            <h2 className="text-xl font-normal">
                                {t('support-page.left-subtitle')}
                            </h2>
                        </div>
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-4">
                                <p className="text-base font-medium">
                                    {t('support-page.left-cta')}
                                </p>
                                <div>
                                    <Link to="/help">
                                        <Button>
                                            {t('support-page.left-button')}
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4 text-base font-medium">
                                <p>{t('support-page.call-us')}</p>
                                <p>
                                    <FontAwesomeIcon
                                        className="scale-x-[-1] mr-3"
                                        icon={faPhone}
                                    />
                                    <a
                                        className="tracking-wide text-white cursor-pointer hover:underline"
                                        href="tel:+441612095209"
                                    >
                                        +44 (0) 161 209 5209
                                    </a>
                                    <span className="mx-1">|</span>
                                    <a
                                        className="tracking-wide text-white cursor-pointer hover:underline whitespace-nowrap"
                                        href="tel:+18008133523"
                                    >
                                        {' '}
                                        +1 (800) 813 3523
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white h-full w-[60%] flex flex-col justify-center px-72">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(submit)}>
                        <h2 className="text-3xl font-medium mb-12">
                            {t('support-page.right-title')}
                        </h2>
                        <div className="flex flex-col gap-6 mb-9">
                            <InputField
                                label={t('support-page.form.full-name')}
                                name="contactName"
                                placeholder="Full Name"
                            />
                            <InputField
                                label={t('support-page.form.email')}
                                name="contactEmail"
                                placeholder="email@example.com"
                            />
                            <InputField
                                label={t('support-page.form.phone')}
                                name="contactTelephone"
                                placeholder="0712 3456789"
                            />
                            <SelectField
                                label={t('support-page.form.subject')}
                                name="contactCategory"
                                options={[
                                    {
                                        label: 'Sales Enquiry',
                                        value: '0'
                                    },
                                    {
                                        label: 'Escrow Deposit',
                                        value: '1'
                                    },
                                    {
                                        label: 'Escrow Agreement',
                                        value: '2'
                                    },
                                    {
                                        label: 'Invoicing',
                                        value: '3'
                                    },
                                    {
                                        label: 'Document Enquiry',
                                        value: '4'
                                    }
                                ]}
                                placeholder={'Please select an option'}
                            />
                            <TextareaField
                                label={t('support-page.form.request')}
                                name="contactRequest"
                                placeholder="Enter additional comments or description here"
                            />
                        </div>
                        <div className="flex justify-end">
                            <Button variant="primary">
                                <FontAwesomeIcon icon={faPaperPlane} />
                                {t('support-page.form.submit')}
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default Support;
