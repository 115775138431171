import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressRing } from 'lib-ui';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { AgreementViewModel } from 'core/api/client-portal/autogenerated/data-contracts';
import AgreementsMetric from './agreements-metric';
import DashboardWidget, { DashboardWidgetProps } from '../dashboard-widget';
import useAgreementsList from 'core/api/client-portal/hooks/agreements/use-agreements-list';

interface AgreementsWidgetProps extends Omit<DashboardWidgetProps, 'loading'> {}

function AgreementsWidget({ className }: AgreementsWidgetProps) {
    const { t } = useTranslation();

    const {
        data: agreements,
        isFetching,
        isLoading
    } = useAgreementsList({
        IncludeRestrictedAgreements: true
    });
    const loading = isFetching || isLoading;

    const totalAgreements = agreements?.agreements?.length ?? 0;

    function filterVerificationDates(items: AgreementViewModel[]) {
        const currentDate = new Date();

        // Create a date representing one year ago
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

        const verifiedWithinLast365Days: AgreementViewModel[] = [];
        const verifiedOver1YearAgo: AgreementViewModel[] = [];

        items.forEach((item) => {
            if (item.lastVerificationExerciseDate === null) {
                // If the date is null, add to verifiedOver1YearAgo
                verifiedOver1YearAgo.push(item);
            } else {
                const verificationDate = new Date(
                    item.lastVerificationExerciseDate!
                );

                if (verificationDate >= oneYearAgo) {
                    verifiedWithinLast365Days.push(item);
                } else {
                    verifiedOver1YearAgo.push(item);
                }
            }
        });

        return {
            verifiedOver1YearAgo,
            verifiedWithinLast365Days
        };
    }

    const { verifiedOver1YearAgo, verifiedWithinLast365Days } =
        filterVerificationDates(agreements?.agreements ?? []);

    const progress =
        totalAgreements === 0
            ? 1
            : verifiedWithinLast365Days.length / totalAgreements;

    return (
        <DashboardWidget className={className}>
            <DashboardWidget.Header
                title={t('dashboard.agreements-widget.title')}
                loading={loading}
            />
            <DashboardWidget.Body className="grid grid-cols-3 grid-rows-5 gap-3">
                <ProgressRing
                    value={progress}
                    className="col-span-3 row-span-3"
                    loading={loading}
                />
                <AgreementsMetric
                    name={t('dashboard.agreements-widget.total-agreements')}
                    value={totalAgreements}
                    loading={loading}
                />
                <AgreementsMetric
                    name={t('dashboard.agreements-widget.verified-agreements')}
                    value={verifiedWithinLast365Days.length}
                    loading={loading}
                    extra={
                        <FontAwesomeIcon
                            className="brand-escode-tourmalineblack-tourmalineblack-100 text-escode-xxxs"
                            icon={faCircle}
                        />
                    }
                />
                <AgreementsMetric
                    name={t(
                        'dashboard.agreements-widget.not-verified-agreements'
                    )}
                    value={verifiedOver1YearAgo.length}
                    loading={loading}
                    extra={
                        <FontAwesomeIcon
                            className="text-brand-escode-neonblue-neonblue-100 text-escode-xxxs"
                            icon={faCircle}
                        />
                    }
                />
            </DashboardWidget.Body>
        </DashboardWidget>
    );
}

export default AgreementsWidget;
export type { AgreementsWidgetProps };
