import { Dropdown } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider, useForm } from 'react-hook-form';
import { faGear } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { Divider } from 'lib-ui';
import LanguageSelect from './language-select';
import SettingsContentSection from '../settings-content-section';

const System = () => {
    const { t } = useTranslation();

    const methods = useForm({
        reValidateMode: 'onBlur'
    });

    return (
        <FormProvider {...methods}>
            <form className="h-full w-full pb-6 border-l border-general-grey-grey-30 ">
                <div className="flex gap-4 items-center justify-between h-[60px] px-7.5 border-b-2 border-general-grey-grey-30">
                    <div className="flex gap-4 items-center">
                        <FontAwesomeIcon icon={faGear} className=" h-6 w-6" />
                        <h2 className="text-xl font-medium">
                            {t('settings.system.title')}
                        </h2>
                    </div>
                </div>
                <div className="p-7.5 h-full overflow-y-scroll space-y-11">
                    <SettingsContentSection
                        description={t('settings.system.description-one')}
                        subtitle={t('settings.system.subtitle-one')}
                    >
                        <div className="flex gap-2">
                            <div className="flex flex-1 gap-2">
                                <Dropdown
                                    multiTrigger
                                    suppressMaxSize
                                    className="flex gap-2 min-w-2xs"
                                >
                                    <LanguageSelect
                                        label={t(
                                            'settings.system.setting.language'
                                        )}
                                        placeholder="Title"
                                    />
                                </Dropdown>
                            </div>
                            {/* <div className="flex flex-1 gap-2">
                                <SwitchField
                                    id="test"
                                    label={t(
                                        'settings.system.setting.theme-toggle'
                                    )}
                                    name="test"
                                    orientation="vertical"
                                />
                            </div> AWAITING DESIGNS */}
                        </div>
                    </SettingsContentSection>
                    <Divider color="bg-general-grey-grey-30" />
                    {/* BELOW HIDDEN WHILST WE USE IDP */}
                    {/* <SettingsContentSection
                        description={t('settings.system.description-two')}
                        subtitle={t('settings.system.subtitle-two')}
                    >
                        <div className="flex flex-1 gap-2">
                            <InputField
                                className="flex-1"
                                disabled
                                name="password"
                                label={t('settings.system.form.password')}
                                type="password"
                            />
                            <InputField
                                className="flex-1"
                                disabled
                                name="confirmPassword"
                                label={t(
                                    'settings.system.form.confirm-password'
                                )}
                                type="password"
                            />
                        </div>
                        <div className="mt-12 flex flex-col gap-4">
                            <div className="text-general-grey-grey-90 text-base">
                                {t('settings.system.form.last-updated')}
                            </div>
                            <div>TBC</div>
                        </div>
                    </SettingsContentSection> */}
                    {/* <Divider color="bg-general-grey-grey-30" /> */}
                    {/* BELOW HIDDEN UNTIL NEEDED */}
                    {/* <SettingsContentSection
                        description={t('settings.system.description-three')}
                        subtitle={t('settings.system.subtitle-three')}
                    >
                        <div className="flex flex-1 gap-36">
                            <div className="flex flex-col flex-1 gap-9">
                                <SwitchField
                                    className="w-full"
                                    id="voiceAssistance"
                                    label="Voice assistance"
                                    name="voiceAssistance"
                                    orientation="horizontal"
                                />
                                <SwitchField
                                    className="w-full"
                                    id="reduceMotion"
                                    label="Reduce motion"
                                    name="reduceMotion"
                                    orientation="horizontal"
                                />
                            </div>
                            <div className="flex flex-col flex-1 gap-9">
                                <SwitchField
                                    className="w-full"
                                    id="largerText"
                                    label="Larger text"
                                    name="largerText"
                                    orientation="horizontal"
                                />
                            </div>
                        </div>
                    </SettingsContentSection> */}
                </div>
            </form>
        </FormProvider>
    );
};

export default System;
