import * as yup from 'yup';
import { ButtonGroup } from 'lib-ui';
import { DownloadScheduleRepo } from 'core/api/client-portal/autogenerated/data-contracts';
import { FormProvider, useForm } from 'react-hook-form';
import { Frequency } from 'lib-ui/source-control-deposits/frequency-enum';
import { MergedFileItem } from './upload-types';
import { parseDownloadDate } from 'lib-ui/source-control-deposits/provider-scd-utils';
import { useAuth } from 'react-oidc-context';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import DNStep from '../deposit-now-step/dn-step';
import DataCentre from 'lib-ui/data-centre/data-centre';
import FinishDepositModal, {
    FinishDepositModalProps
} from './finish-deposit-modal/finish-deposit-modal';
import OnPremUploader from 'lib-ui/on-prem-uploader/on-prem-uploader';
import SourceControlDeposits from 'lib-ui/source-control-deposits/source-control-deposits';
import VVUploader from 'lib-ui/vv-uploader/vv-uploader';
import toast from 'react-hot-toast';
import useAgreementsDepositsFiles from 'core/api/client-portal-v2/hooks/deposits/use-agreements-deposits-files';
import useDepositDetailsV2 from 'core/api/client-portal-v2/hooks/deposits/use-deposit-details-v2';
import useDepositNowStore from 'core/stores/use-deposit-now-store';

export type ButtonActiveId = 'from_device' | 'source_control';
type FormData = {
    agreeAutomatedDepositsCheckbox: boolean;
    connectionsAdded: DownloadScheduleRepo[];
    day: string;
    frequency: string;
    hour: string;
    isAutomated: string;
    minutes: string;
    month: string;
    monthOptions: string;
    notifyChangesCheckbox: boolean;
    week: string;
};

const UploadStep = () => {
    const { signinSilent } = useAuth();
    const queryClient = useQueryClient();
    const { depositId } = useParams();
    const { t } = useTranslation();
    const { push } = useModals();
    const goTo = useNavigate();
    const [activeId, setActiveId] = useState<ButtonActiveId>('from_device');
    const parsedDepositId = Number(depositId);

    const { isUploadingFile: isAnyFileBeingUploaded } = useDepositNowStore();

    const schema = yup.object({
        agreeAutomatedDepositsCheckbox: yup.boolean().when('isAutomated', {
            is: (isAutomated: string) => isAutomated === 'true',
            then: yup.boolean().isTrue()
        }),
        connectionsAdded: yup.array(),
        day: yup.string().when('frequency', {
            is: (frequency: string) =>
                frequency === Frequency.Quarterly ||
                frequency === Frequency.Annually,
            then: yup
                .string()
                .required(t('upload-step.day-required'))
                .nullable()
        }),
        frequency: yup.string(),
        hour: yup.string().when('frequency', {
            is: (frequency: string) => frequency !== '0',
            then: yup
                .string()
                .required(t('upload-step.hour-required'))
                .nullable()
        }),
        isAutomated: yup.string(),
        minutes: yup.string().when('frequency', {
            is: (frequency: string) => frequency !== '0',
            then: yup
                .string()
                .required(t('upload-step.minutes-required'))
                .nullable()
        }),
        month: yup.string().when('frequency', {
            is: (frequency: string) => frequency === Frequency.Monthly,
            then: yup
                .string()
                .required(t('upload-step.month-required'))
                .nullable()
        }),
        monthOptions: yup.string().when('frequency', {
            is: (frequency: string) =>
                frequency === Frequency.Quarterly ||
                frequency === Frequency.Annually,
            then: yup
                .string()
                .required(t('upload-step.month-required'))
                .nullable()
        }),
        notifyChangesCheckbox: yup.boolean().when('isAutomated', {
            is: (isAutomated: string) => isAutomated === 'true',
            then: yup.boolean().isTrue()
        }),
        week: yup.string().when('frequency', {
            is: (frequency: string) => frequency === Frequency.Weekly,
            then: yup
                .string()
                .required(t('upload-step.week-required'))
                .nullable()
        })
    });

    const methods = useForm<FormData>({
        defaultValues: {
            agreeAutomatedDepositsCheckbox: false,
            connectionsAdded: [],
            day: '',
            frequency: '0',
            hour: '',
            isAutomated: 'false',
            minutes: '',
            month: '',
            monthOptions: '',
            notifyChangesCheckbox: false,
            week: ''
        },
        mode: 'all',
        reValidateMode: 'onSubmit',
        resolver: yupResolver(schema)
    });

    const automate = methods?.watch('isAutomated');
    const connectionsAdded = methods?.watch('connectionsAdded');
    const frequency = methods?.watch('frequency');

    const { data: depositFilesData } =
        useAgreementsDepositsFiles(parsedDepositId);

    const depositUniqueReference =
        depositFilesData?.depositFiles[0]?.depositUniqueReference;

    const {
        data: depositDetails,
        isFetching,
        isLoading
    } = useDepositDetailsV2(parsedDepositId);

    const dataCentre = useMemo(
        () => depositDetails?.dataCentre?.toString() ?? '',
        [depositDetails?.dataCentre]
    );

    const isOnPrem = depositDetails?.isOnPrem ?? false;
    const isFileFailed = useMemo(() => {
        return depositFilesData?.depositFiles.some(
            (file: MergedFileItem) =>
                file.verificationState === 0 || !file.completed
        );
    }, [depositFilesData?.depositFiles]);

    const disabledProceed = useMemo(() => {
        if (activeId === 'from_device') {
            return (
                depositFilesData?.depositFiles?.length === 0 ||
                isFileFailed ||
                isAnyFileBeingUploaded
            );
        }
        return activeId === 'source_control' && !connectionsAdded.length;
    }, [
        activeId,
        connectionsAdded.length,
        depositFilesData?.depositFiles?.length,
        isAnyFileBeingUploaded,
        isFileFailed
    ]);

    const handleOnClick = useCallback(
        (id: ButtonActiveId) => {
            if (id !== activeId) {
                setActiveId(id);
            }
        },
        [activeId]
    );
    const agreementId: string =
        depositDetails?.relatedAgreements[0]?.toString() ?? '';

    const handleSubmit = useCallback(() => {
        let raw_source_control: FinishDepositModalProps = {
            activeId,
            depositId: parsedDepositId,
            depositUniqueReference,
            description: t('finish-deposit-modal.description'),
            isOnPrem,
            items: connectionsAdded,
            title: t('finish-deposit-modal.title')
        };

        if (activeId === 'source_control' && automate === 'true') {
            const { day, hour, minutes, month, monthOptions, week } =
                // eslint-disable-next-line no-unsafe-optional-chaining
                methods?.watch();

            const nextDownloadAt = parseDownloadDate(
                day,
                hour,
                minutes,
                monthOptions,
                month,
                week,
                frequency
            );

            if (nextDownloadAt === '') {
                toast.error(t('upload-step.date-error'));
                return;
            }

            raw_source_control = {
                ...raw_source_control,
                frequencyType: Number(frequency),
                items: connectionsAdded,
                nextDownloadAt
            };
        }

        queryClient.invalidateQueries('use-request-token');

        push(FinishDepositModal, {
            ...raw_source_control
        });
        return;
    }, [
        activeId,
        automate,
        connectionsAdded,
        depositUniqueReference,
        frequency,
        isOnPrem,
        methods,
        parsedDepositId,
        push,
        queryClient,
        t
    ]);

    useEffect(() => {
        if (automate === 'true') {
            methods.setValue('frequency', '1');
        } else {
            methods.setValue('frequency', '0');
        }
    }, [automate, methods]);

    // control timing
    useEffect(() => {
        if (frequency) {
            methods.setValue('minutes', '');
            methods.setValue('hour', '');
            methods.setValue('month', '');
            methods.setValue('day', '');
            methods.setValue('week', '');
        }
    }, [frequency, methods]);

    // If the user uploads very long files, they may lose the token and cause errors.
    // For the time being, we control it this way.
    useEffect(() => {
        const intervalId = setInterval(() => {
            return signinSilent();
        }, 1800000); // 30 minutes in milliseconds
        // }, 20000); // 20 seconds in milliseconds for testing purposes

        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
                <DNStep
                    disableProceed={disabledProceed}
                    loading={isFetching || isLoading}
                    onBack={() => goTo(`/deposit-now/${depositId}/disclaimer`)}
                    title={t('upload-step.title')}
                >
                    <div className="flex w-full justify-center">
                        <ButtonGroup activeId={activeId}>
                            <ButtonGroup.Button
                                id="from_device"
                                onClick={() => handleOnClick('from_device')}
                                type="button"
                            >
                                {t('upload-step.upload-from-device')}
                            </ButtonGroup.Button>
                            <ButtonGroup.Button
                                type="button"
                                id="source_control"
                                data-testid="button-source-control"
                                onClick={() => handleOnClick('source_control')}
                            >
                                {t('upload-step.source-control-connections')}
                            </ButtonGroup.Button>
                        </ButtonGroup>
                    </div>
                    {activeId === 'from_device' ? (
                        <div className="pt-6">
                            <p className="mb-4 whitespace-break-spaces">
                                {!isOnPrem
                                    ? t('upload-step.p')
                                    : t('upload-step.title-on-prem')}
                            </p>

                            <div className="flex gap-6">
                                <div className="w-full">
                                    {!isOnPrem ? (
                                        <>
                                            <DataCentre
                                                agreementId={agreementId}
                                                currentDataCentre={dataCentre}
                                                depositId={
                                                    Number(depositId) ?? ''
                                                }
                                            />
                                            <br />

                                            <VVUploader
                                                depositId={parsedDepositId}
                                                currentDataCentre={dataCentre}
                                            />
                                        </>
                                    ) : (
                                        <OnPremUploader
                                            depositId={parsedDepositId}
                                            dataCentre={
                                                depositDetails?.dataCentre?.toString() ??
                                                'NCC:UK'
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <SourceControlDeposits
                            automate={automate}
                            connectionsAdded={connectionsAdded}
                        />
                    )}
                </DNStep>
            </form>
        </FormProvider>
    );
};

export default UploadStep;
