import AppBody from './app-body';
import ComingSoon from 'ui/coming-soon/coming-soon';

function ComingSoonBody() {
    return (
        <AppBody>
            <ComingSoon />
        </AppBody>
    );
}

export default ComingSoonBody;
