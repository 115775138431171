import { Widget } from 'lib-ui';
import { useTranslation } from 'react-i18next';

import CurrentMonthSchedules from './current-month-schedules/current-month-schedules';
import PreviousMonthSchedules from './previous-month-schedules/previous-month-schedules';
import useDepositsStats from 'core/api/client-portal/hooks/schedules/use-deposits-stats';

function getMonthDates() {
    const now = new Date();

    const startOfThisMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    const endOfThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    const endOfLastMonth = new Date(startOfThisMonth);
    endOfLastMonth.setDate(endOfLastMonth.getDate() - 1);

    const startOfLastMonth = new Date(
        endOfLastMonth.getFullYear(),
        endOfLastMonth.getMonth(),
        1
    );

    const startLastMonthISO = startOfLastMonth.toISOString();
    const startThisMonthISO = startOfThisMonth.toISOString();
    const endLastMonthISO = endOfLastMonth.toISOString();
    const endThisMonthISO = endOfThisMonth.toISOString();

    return {
        lastMonthEnd: endLastMonthISO,
        lastMonthStart: startLastMonthISO,
        thisMonthEnd: endThisMonthISO,
        thisMonthStart: startThisMonthISO
    };
}

function SchedulesMetrics() {
    const { t } = useTranslation();

    const monthDates = getMonthDates();

    const { data: upcomingDeposits, isLoading } = useDepositsStats(
        {},
        {
            endDate: monthDates.thisMonthEnd,
            startDate: monthDates.thisMonthStart
        }
    );

    const { data: previousDeposits, isLoading: loadingPrevious } =
        useDepositsStats(
            {},
            {
                endDate: monthDates.lastMonthEnd,
                startDate: monthDates.lastMonthStart
            }
        );

    return (
        <Widget>
            <Widget.Header title={t('schedules.metrics.title')} />
            <Widget.Body className="px-2.5 py-3.5 space-y-2.5">
                <CurrentMonthSchedules
                    data={upcomingDeposits}
                    loading={isLoading}
                />
                <PreviousMonthSchedules
                    data={previousDeposits}
                    loading={loadingPrevious}
                />
            </Widget.Body>
        </Widget>
    );
}

export default SchedulesMetrics;
