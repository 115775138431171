import React, { PropsWithChildren } from 'react';

type SettingsContentSectionProps = {
    description: string;
    subtitle: string;
};

const SettingsContentSection = ({
    children,
    description,
    subtitle
}: PropsWithChildren<SettingsContentSectionProps>) => {
    return (
        <div className="flex gap-8">
            <div className="w-1/3">
                <div className="space-y-4 w-3/4">
                    <h3 className="text-lg font-medium">{subtitle}</h3>
                    <p className="text-sm font-normal">{description}</p>
                </div>
            </div>
            <div className="w-full">{children}</div>
        </div>
    );
};

export default SettingsContentSection;
