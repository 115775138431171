import { CommonProps } from '@ncc-frontend/core';
import { ForwardedRef, forwardRef, useCallback } from 'react';
import { clamp } from 'lodash';
import classNames from 'classnames';

import useDebounce from 'common/use-debounce';

interface ProgressBarProps extends CommonProps {
    color?: { fill: string; track: string };
    value?: number;
    variant?: 'danger' | 'default';
}

function ProgressBar(
    {
        className,
        color,
        value,
        variant = 'default',
        ...restProps
    }: ProgressBarProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const debounce = useDebounce(500);

    const setFillRef = useCallback(
        (ref: HTMLDivElement | null) => {
            if (ref === null) return;
            debounce(() => {
                ref.style.width = `${clamp(value ?? 0, 0, 100)}%`;
            });
        },
        [debounce, value]
    );

    return (
        <div
            {...restProps}
            ref={ref}
            className={classNames(
                'w-full rounded-full h-1.5  ',
                {
                    'bg-brand-escode-neonblue-neonblue-10':
                        !color && variant === 'default',
                    'bg-escode-red-20': !color && variant === 'danger'
                },
                color?.track,
                className
            )}
        >
            <div
                ref={setFillRef}
                className={classNames(
                    'h-full rounded-full transition-all !duration-500 w-0',
                    {
                        'bg-brand-escode-neonblue-neonblue-100':
                            !color && variant === 'default',
                        'bg-general-red-red-100': !color && variant === 'danger'
                    },
                    color?.fill
                )}
            />
        </div>
    );
}

export default forwardRef(ProgressBar);
export type { ProgressBarProps };
