import { MutationOptions, useMutation } from 'react-query';
import {
    PrepareFile,
    PrepareResponse
} from '../../autogenerated/data-contracts';
import { useAuth } from 'react-oidc-context';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import useDepositNowStore from 'core/stores/use-deposit-now-store';

export interface UploadsFilesPrepareData {
    depositUniqueReference: string;
    uniqueReference: string;
    variables: PrepareFile;
}

function useUploadsFilesPrepare<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<PrepareResponse>,
            ReactQueryError<TError>,
            UploadsFilesPrepareData,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();
    const { VVToken, spectreAgentUrl } = useDepositNowStore();

    return useMutation(
        [
            'uploadsFilesPrepare',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async ({
            depositUniqueReference,
            uniqueReference,
            variables
        }: UploadsFilesPrepareData): Promise<
            AxiosResponse<PrepareResponse>
        > => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useUploadsFilesPrepare call ignored.'
                );
                return Promise.resolve({} as AxiosResponse<PrepareResponse>);
            }

            const uploadsFilesPrepare = (
                depositUniqueReference: string,
                uniqueReference: string,
                data: PrepareFile,
                params: AxiosRequestConfig = {}
            ): Promise<AxiosResponse<PrepareResponse>> => {
                const baseURL = spectreAgentUrl;
                const axiosInstance = axios.create({
                    baseURL,
                    headers: {
                        Authorization: `Bearer ${VVToken}`,
                        'Cache-FormControl':
                            'no-cache, no-store, must-revalidate',
                        Expires: '0',
                        Pragma: 'no-cache'
                    }
                });

                return axiosInstance.put(
                    `/api/uploads/${depositUniqueReference}/${uniqueReference}`,
                    data,
                    params
                );
            };

            return uploadsFilesPrepare(
                depositUniqueReference,
                uniqueReference,
                variables
            );
        },
        {
            ...options
        }
    );
}

export default useUploadsFilesPrepare;
