import React, { useState } from 'react';

import './terms-and-conditions.scss';
import TermContent from './terms-content';
import TermTabs from './terms-tabs';

const TermsAndConditions = () => {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabChange = (tabIndex: number) => {
        setActiveTab(tabIndex);
    };

    return (
        <div className="bg-white h-full">
            <div className="h-[102px]">
                <div className="h-full w-full bg-gradient-to-r from-escode-overlay-texture-light to-escode-overlay-texture-dark rounded-2 text-white flex flex-col items-center justify-center text-4xl font-medium mb-7 ">
                    <div className="z-100 h-full w-full error-panel flex items-center justify-center flex-col gap-8">
                        Terms and Conditions
                    </div>
                </div>
                <div className="flex flex-col items-center gap-7">
                    <div className="max-w-[80%]">
                        The use of NCC Services Limited's ("NCC Group") secure
                        online application ("View") and/or the use of any Secure
                        File Transfer Protocol ("SFTP") used to submit code,
                        documents and/or information to NCC Group are subject to
                        the following terms and conditions which you should read
                        carefully prior to using View or SFTP.
                    </div>
                    <div className="w-[80%] max-w-[80%] h-full">
                        <div className="flex border border-general-grey-grey-30 h-full">
                            <TermTabs
                                activeTab={activeTab}
                                onTabChange={handleTabChange}
                            />
                            <TermContent activeTab={activeTab} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
