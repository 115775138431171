const assignColour = (name: string) => {
    const letter = name[0].toLowerCase();
    if (letter >= 'a' && letter <= 'f') {
        return 'bg-[#795EFF]';
    } else if (letter >= 'g' && letter <= 'l') {
        return 'bg-[#0073E5]';
    } else if (letter >= 'm' && letter <= 'r') {
        return 'bg-[#038855]';
    } else {
        return 'bg-[#ED5B00]';
    }
};

export default assignColour;
