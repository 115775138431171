import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import axios, { AxiosResponse } from 'axios';

import {
    AuthToken,
    RequestSpectreTokenResponse
} from 'ui/deposit-now/upload-step/upload-types';
import useDepositNowStore from 'core/stores/use-deposit-now-store';

export interface ExtendedAuthToken extends AuthToken {
    spectreAgentUrl: string;
    spectreControlUrl: string;
}

function useSpectreControlAuth(
    spectreTokenResponse: ExtendedAuthToken,
    options?: Omit<UseQueryOptions, 'queryFn'>
) {
    const queryClient = useQueryClient();
    const { setShouldClaimToken, setVVToken } = useDepositNowStore();

    const data: RequestSpectreTokenResponse | undefined =
        queryClient.getQueryData('requestToken');
    const authToken = data?.authToken.id;
    const spectreControlUrl = data?.spectreControlUrl;

    return useQuery(
        [
            'spectreControlAuth',
        ],
        async () => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled; useSpectreControlAuth call ignored.'
                );
                return Promise.resolve({} as AxiosResponse<void | unknown>);
            }

            const authUrl = new URL(
                `/api/auth/${authToken ?? spectreTokenResponse.authToken.id}`,
                spectreControlUrl ?? spectreTokenResponse.spectreControlUrl
            );

            const authTokenClaim = async (
                id: string,
                query?: { depositId?: string },
                headers = {}
            ) => {
                const url = `/api/auth/${id}`;
                const queryParams = query?.depositId
                    ? `?depositId=${query.depositId}`
                    : '';
                try {
                    const response = await axios.get(url + queryParams, {
                        baseURL: authUrl.origin,
                        headers: {
                            Accept: 'application/json, text/plain, */*',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${spectreTokenResponse.authToken.token}`,
                            'Cache-Control':
                                'no-cache, no-store, must-revalidate',
                            Expires: '0',
                            Pragma: 'no-cache',
                            ...headers
                        },
                        withCredentials: true
                    });
                    return response.data;
                } catch (error) {
                    console.error('Error making Axios request:', error);
                    throw error;
                }
            };

            const data = await authTokenClaim(
                spectreTokenResponse.authToken.id,
                {
                    depositId: spectreTokenResponse.id
                }
            );
            if (data?.token) {
                setVVToken(data?.token);
            }
            return data?.token;
        },
        {
            enabled: options?.enabled ?? false,
            onSuccess: () => {
                setShouldClaimToken(false);
            }
        }
    );
}

export default useSpectreControlAuth;
