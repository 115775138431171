const financialServicesOptions: { label: string; value: string }[] = [
    { label: 'Retail Banking', value: 'Retail Banking' },
    {
        label: 'Corporate/Commercial Banking',
        value: 'Corporate/Commercial Banking'
    },
    { label: 'Global Banking', value: 'Global Banking' },
    { label: 'Private Banking', value: 'Private Banking' },
    { label: 'Investment Banking', value: 'Investment Banking' }
];

const applicationCategoryOptions: { label: string; value: string }[] = [
    { label: 'Retail Banking', value: 'Retail Banking' },
    {
        label: 'Middleware',
        value: 'Middleware'
    },
    { label: 'Universal Banking', value: 'Universal Banking' },
    { label: 'Trade Finance', value: 'Trade Finance' },
    { label: 'Loan Origination', value: 'Loan Origination' },
    { label: 'Analytics', value: 'Analytics' },
    { label: 'Capital Markets', value: 'Capital Markets' },
    { label: 'Lending', value: 'Lending' },
    { label: 'Payments', value: 'Payments' },
    { label: 'Trading & Processing', value: 'Trading & Processing' },
    { label: 'Treasury & Risk', value: 'Treasury & Risk' },
    {
        label: 'Investment Operations & Data Services',
        value: 'Investment Operations & Data Services'
    },
    { label: 'Data Integration', value: 'Data Integration' },
    { label: 'Other - please specify', value: 'Other - please specify' }
];

const radioOptions: { label: string; value: string }[] = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' }
];

const radioLicenseesOptions: { label: string; value: string | number }[] = [
    { label: 'All', value: '0' },
    { label: 'Selected Licensees', value: '1' }
];

const contentOptions: { id: string; label: string; value: string }[] = [
    { id: '1', label: 'Source Code', value: 'sourceCode' },
    { id: '2', label: 'Development', value: 'development' },
    {
        id: '3',
        label: 'Environment or Build Tools',
        value: 'environmentOrBuildTools'
    },
    { id: '4', label: 'Database', value: 'data' },
    { id: '5', label: 'Test Environment', value: 'testEnvironment' },
    { id: '6', label: 'Other Data', value: 'otherData' },
    { id: '7', label: 'Configuration Files', value: 'configurationFiles' }
];

const languages: { id: string; label: string; value: number }[] = [
    {
        id: '1',
        label: 'C',
        value: 1
    },
    {
        id: '2',
        label: 'C++',
        value: 2
    },
    {
        id: '3',
        label: 'C#',
        value: 3
    },
    {
        id: '4',
        label: 'Java/j2ee',
        value: 4
    },
    {
        id: '5',
        label: 'Python',
        value: 5
    },
    {
        id: '6',
        label: 'Visual Basic',
        value: 6
    },
    {
        id: '7',
        label: 'Tool Command Language (TCL)',
        value: 7
    },
    {
        id: '8',
        label: 'Ruby',
        value: 8
    },
    {
        id: '9',
        label: 'Objective C & C++ (Mac OS version of C)',
        value: 9
    },
    {
        id: '10',
        label: 'Fortran',
        value: 10
    },
    {
        id: '11',
        label: 'COBOL',
        value: 11
    },
    {
        id: '12',
        label: 'Assembly',
        value: 12
    },
    {
        id: '23',
        label: '.NET (C++, C#, VB and VB6)',
        value: 23
    },
    {
        id: '24',
        label: '4GL',
        value: 24
    },
    {
        id: '25',
        label: 'ActionScript',
        value: 25
    },
    {
        id: '26',
        label: 'ASP.net (MVC)',
        value: 26
    },
    {
        id: '27',
        label: 'ASP.net WCF and WebServices',
        value: 27
    },
    {
        id: '28',
        label: 'Ajax',
        value: 28
    },
    {
        id: '29',
        label: 'Allegro Common Lisp',
        value: 29
    },
    {
        id: '30',
        label: 'Asp.Net',
        value: 30
    },
    {
        id: '31',
        label: 'Basic/Basic Plus Plus',
        value: 31
    },
    {
        id: '32',
        label: 'Header files for C++',
        value: 32
    },
    {
        id: '33',
        label: 'Centura (Gupta Team Developer)',
        value: 33
    },
    {
        id: '34',
        label: 'Claire',
        value: 34
    },
    {
        id: '35',
        label: 'Coldfusion/Lucee',
        value: 35
    },
    {
        id: '36',
        label: 'Delphi',
        value: 36
    },
    {
        id: '37',
        label: 'F#',
        value: 37
    },
    {
        id: '38',
        label: 'Go',
        value: 38
    },
    {
        id: '39',
        label: 'Groovy',
        value: 39
    },
    {
        id: '40',
        label: 'Gupta',
        value: 40
    },
    {
        id: '41',
        label: 'Haskell',
        value: 41
    },
    {
        id: '42',
        label: 'HTML',
        value: 42
    },
    {
        id: '43',
        label: 'JavaScript',
        value: 43
    },
    {
        id: '44',
        label: 'Kotlin',
        value: 44
    },
    {
        id: '45',
        label: 'Lisp',
        value: 45
    },
    {
        id: '46',
        label: 'Magik',
        value: 46
    },
    {
        id: '47',
        label: 'Mat-Lab/Simulink',
        value: 47
    },
    {
        id: '48',
        label: 'ML',
        value: 48
    },
    {
        id: '0',
        label: 'Other (Please specify below)',
        value: 0
    },
    {
        id: '49',
        label: 'Oracle Forms/D2K',
        value: 49
    },
    {
        id: '50',
        label: 'Oracle Reports/Crystal Reports/D2K',
        value: 50
    },
    {
        id: '51',
        label: 'Oracle PL/SQL',
        value: 51
    },
    {
        id: '52',
        label: 'Pascal',
        value: 52
    },
    {
        id: '53',
        label: 'Perl',
        value: 53
    },
    {
        id: '54',
        label: 'PHP',
        value: 54
    },
    {
        id: '56',
        label: 'PostScript',
        value: 56
    },
    {
        id: '57',
        label: 'PowerBuilder/PowerScript',
        value: 57
    },
    {
        id: '58',
        label: 'Powershell',
        value: 58
    },
    {
        id: '59',
        label: 'Progress (aka OpenEdge ABL and 4GL)',
        value: 59
    },
    {
        id: '60',
        label: 'PLC',
        value: 60
    },
    {
        id: '61',
        label: 'Pro C',
        value: 61
    },
    {
        id: '62',
        label: 'Rexx',
        value: 62
    },
    {
        id: '63',
        label: 'Shell Script & Unix Shell Bash',
        value: 63
    },
    {
        id: '64',
        label: 'Shell (Windows)',
        value: 64
    },
    {
        id: '65',
        label: 'Scala',
        value: 65
    },
    {
        id: '66',
        label: 'Swift',
        value: 66
    },
    {
        id: '67',
        label: 'Siemens PLC (aka Step 7)',
        value: 67
    },
    {
        id: '68',
        label: 'Smalltalk',
        value: 68
    },
    {
        id: '69',
        label: 'SQL Server SSRS reports',
        value: 69
    },
    {
        id: '70',
        label: 'SQL Server Integration Services (SSIS)',
        value: 70
    },
    {
        id: '71',
        label: 'T-SQL',
        value: 71
    },
    {
        id: '72',
        label: 'TypeScript',
        value: 72
    },
    {
        id: '73',
        label: 'Uniface',
        value: 73
    },
    {
        id: '74',
        label: 'VB Script',
        value: 74
    },
    {
        id: '75',
        label: 'VB6 (Visual Basic 6/VBA)',
        value: 75
    },
    {
        id: '76',
        label: 'VB.Net (Visual Basic/VBA)',
        value: 76
    },
    {
        id: '77',
        label: 'Visual Fox Pro (VFP)',
        value: 77
    },
    {
        id: '78',
        label: 'VHDL',
        value: 78
    }
];

export {
    applicationCategoryOptions,
    contentOptions,
    financialServicesOptions,
    languages,
    radioLicenseesOptions,
    radioOptions
};
