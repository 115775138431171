import { DepositState } from 'core/api/client-portal/model/deposit-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ForwardedRef, forwardRef, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import {
    faDiamondExclamation,
    faTrash
} from '@fortawesome/pro-solid-svg-icons';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'react-i18next';

import {
    Button,
    ConfirmationTooltip,
    ProgressBar,
    SkeletonLine,
    Tooltip
} from 'lib-ui';
import { useModals } from '@ncc-frontend/core';
import { useQueryClient } from 'react-query';
import cn from 'core/utils/cn';
import depositStepFormatter from 'core/formatters/deposit-step-formatter';
import timestampFormatter from 'core/formatters/timestamp-formatter';
import toastFunctions from 'lib-ui/toast/Toast';
import useAnimatedNumber from 'common/use-animated-number';
import useBeautify from 'core/beautifiers/use-beautify';
import useDepositDelete from 'core/api/client-portal/hooks/deposits/use-deposit-delete';
import useUserDetails from 'core/api/client-portal/hooks/user/use-user-details';

interface IncompleteDepositProps {
    agreementId?: number;
    agreementNumber?: number;
    continueDisabled?: boolean;
    created?: string;
    createdByUserId?: string;
    danger?: boolean;
    depositId?: number;
    lastSavedState?: number;
    loading?: boolean;
    name?: string | number | null;
    restrictWidth?: boolean;
    state?: DepositState;
    version?: string | null;
}

const IncompleteDeposit = (
    {
        agreementId,
        agreementNumber,
        continueDisabled,
        created,
        createdByUserId,
        danger,
        depositId,
        lastSavedState,
        loading,
        name,
        restrictWidth = false,
        state,
        version
    }: IncompleteDepositProps,
    ref: ForwardedRef<HTMLDivElement>
) => {
    const { i18n, t } = useTranslation();
    const { beautifyDate } = useBeautify();
    const queryClient = useQueryClient();
    const { pop } = useModals();
    const { user } = useAuth();
    const isCollapsed = localStorage.getItem('sider-collapsed') === 'true';

    const { data: userDetails } = useUserDetails(user || null);

    const { isLoading: isDeletingDeposit, mutate: deleteDeposit } =
        useDepositDelete();

    const isRemoveDisabled =
        isDeletingDeposit || createdByUserId !== userDetails?.id;

    const { ref: valueElementRef, updateValue } =
        useAnimatedNumber<HTMLDivElement>({
            formatter: (val) => `${val}%`
        });

    const value =
        state === 'agreement-selection'
            ? 15
            : state === 'contact-details'
            ? 30
            : state === 'software-details' ||
              state === 'deposit-type' ||
              state === 'deposit-specs'
            ? 45
            : state === 'deposit-content'
            ? 60
            : state === 'review-details'
            ? 75
            : state === 'data-centre'
            ? 90
            : 0;
    updateValue(value);

    const remove = useCallback(() => {
        if (isRemoveDisabled) return;
        deleteDeposit(
            {
                agreementId: `${agreementId!}`,
                depositId: depositId!
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries([
                        'use-incomplete-deposit-v2'
                    ]);
                    toastFunctions.success(
                        t('incomplete-deposit.delete-success')
                    );
                }
            }
        );
    }, [
        agreementId,
        deleteDeposit,
        depositId,
        isRemoveDisabled,
        queryClient,
        t
    ]);

    return (
        <div
            className="grid grid-cols-[1fr,auto] gap-6 items-center border-b border-brand-escode-neonblue-neonblue-10 pb-4"
            ref={ref}
        >
            <div className="relative grid gap-1 grid-cols-[auto,1fr]">
                {!!loading && <SkeletonLine absolute />}
                <div className={cn('max-w-sm', restrictWidth && 'w-40')}>
                    <Tooltip
                        message={
                            <p>
                                {name} {version && <span>({version})</span>}
                            </p>
                        }
                        trigger="hover"
                        data-testid={`${name}-${version}-tooltip`}
                        placement="right"
                        panelProps={{ suppressTick: false }}
                    >
                        <p className="text-sm text-general-grey-grey-90 truncate">
                            <span className="font-medium">
                                {agreementNumber}
                            </span>{' '}
                            - {name}
                        </p>
                    </Tooltip>
                    <p className="text-xs text-general-grey-grey-90 truncate">
                        ({version || 'N/A'})
                    </p>
                </div>

                <p
                    className="text-sm row-span-2 place-self-end"
                    ref={valueElementRef}
                ></p>
                <p
                    className={cn(' text-xs', {
                        'text-general-grey-grey-70': !danger,
                        'text-general-red-red-100': danger
                    })}
                >
                    {beautifyDate(timestampFormatter(created, i18n.language))}
                </p>
                <ProgressBar
                    value={value}
                    className="col-span-2"
                    variant={danger ? 'danger' : undefined}
                />
            </div>

            <div className="relative grid grid-cols-2 gap-2">
                {!!loading && <SkeletonLine absolute />}

                <ConfirmationTooltip
                    placement="left"
                    icon={<FontAwesomeIcon icon={faDiamondExclamation} />}
                    title={t('incomplete-deposits-widget.delete-tooltip-title')}
                    message={t(
                        'incomplete-deposits-widget.delete-tooltip-message'
                    )}
                    onConfirm={remove}
                    variant="warning"
                    confirmMessage={t('tooltip.proceed')}
                    rejectMessage={t('tooltip.reject')}
                    disabled={isRemoveDisabled}
                >
                    <Button
                        size="s"
                        variant="danger"
                        className="w-full"
                        disabled={isRemoveDisabled}
                    >
                        {!isCollapsed ? (
                            <FontAwesomeIcon
                                icon={faTrash}
                                className={cn(
                                    'text-red-600 text-lg',
                                    isRemoveDisabled && 'text-gray-500'
                                )}
                            />
                        ) : (
                            t('incomplete-deposits-widget.button-delete')
                        )}
                    </Button>
                </ConfirmationTooltip>
                <NavLink
                    onClick={() => {
                        queryClient.invalidateQueries([
                            'use-deposit-details-v2'
                        ]);
                        pop();
                    }}
                    to={`/deposit-now/${depositId}/${
                        state && depositStepFormatter(lastSavedState ?? 0)
                    }`}
                >
                    <Button
                        variant="tertiary"
                        size="s"
                        disabled={continueDisabled}
                    >
                        {!isCollapsed ? (
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                className={cn(
                                    'text-brand-escode-neonblue-neonblue-100 text-lg',
                                    continueDisabled && 'text-gray-500'
                                )}
                            />
                        ) : (
                            t('incomplete-deposits-widget.button-continue')
                        )}
                    </Button>
                </NavLink>
            </div>
        </div>
    );
};

export default forwardRef(IncompleteDeposit);
