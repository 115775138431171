import * as yup from 'yup';
import {
    FieldValues,
    FormProvider,
    SubmitHandler,
    useForm
} from 'react-hook-form';
import {
    FilterBackEventHandler,
    FilterData,
    FilterFieldData
} from '../../data-widget-types';
import {
    ForwardedRef,
    forwardRef,
    useCallback,
    useMemo,
    useState
} from 'react';
import { cloneDeep } from 'lodash';
import { useDropdown } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '../../../../button/button';
import FilterDateStep from '../filter-date-step/filter-date-step';
import FilterField, {
    FilterFieldProps
} from '../filter-overview-step/filter-field';
import FilterStep from '../filter-step/filter-step';
import FilterTextStep from '../filter-text-step/filter-text-step';
import InputField from '../../../../form/field/input-field';
import useDataWidget from '../../use-data-widget';
import useUserSettingsUpdate from 'core/api/client-portal/hooks/user-settings/use-user-settings-update';

interface FilterSaveStepProps {
    filters: FilterData[];
    onBack: FilterBackEventHandler;
    readonly?: boolean;
    savedFilters: Record<string, FilterData[]>;
    savedFiltersSettingId: string;
}

function FilterSaveStep(
    {
        filters,
        onBack,
        readonly = false,
        savedFilters,
        savedFiltersSettingId
    }: FilterSaveStepProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { mutate: updateUserSettings } = useUserSettingsUpdate();
    const { t } = useTranslation();
    const { close } = useDropdown();
    const { filterableFields, hiddenFields, onApplyFilters } = useDataWidget();

    const [selectedField, setSelectedField] = useState<FilterFieldData>();

    const schema = useMemo(
        () =>
            yup.object({
                // TODO Move to common file
                name: yup
                    .string()
                    .required()
                    .test(
                        'unique',
                        t('filters.name-error-unique'),
                        function (value, { createError }) {
                            if (!value) return true;

                            const valid =
                                !Object.keys(savedFilters).includes(value);

                            if (valid) return true;

                            return createError();
                        }
                    )
            }),
        [savedFilters, t]
    );
    const methods = useForm({
        defaultValues: { name: '' },
        mode: 'onChange',
        resolver: yupResolver(schema)
    });
    const name = methods.watch('name', '');

    const save = useCallback(() => {
        updateUserSettings({
            data: [
                {
                    settingId: savedFiltersSettingId,
                    settingJson: JSON.stringify({
                        ...cloneDeep(savedFilters),
                        [name]: filters
                    })
                }
            ]
        });

        onBack();
    }, [
        filters,
        name,
        onBack,
        savedFilters,
        savedFiltersSettingId,
        updateUserSettings
    ]);

    const saveAndApply = useCallback(() => {
        updateUserSettings({
            data: [
                {
                    settingId: savedFiltersSettingId,
                    settingJson: JSON.stringify({
                        ...cloneDeep(savedFilters),
                        [name]: filters
                    })
                }
            ]
        });

        onApplyFilters!(filters);
        close();
    }, [
        close,
        filters,
        name,
        onApplyFilters,
        savedFilters,
        savedFiltersSettingId,
        updateUserSettings
    ]);

    const submit = useCallback<SubmitHandler<FieldValues>>(
        (_formData, event) => {
            event?.preventDefault();
        },
        []
    );

    const handleFieldSelected = useCallback<
        NonNullable<FilterFieldProps['onSelect']>
    >((field) => {
        setSelectedField(field);
    }, []);

    const closeNested = useCallback(() => {
        setSelectedField(undefined);
    }, []);

    return (
        <FilterStep ref={ref}>
            <FilterStep.Header onBack={onBack}>
                {t('filters.create')}
            </FilterStep.Header>
            <FilterStep.Body className="flex flex-col gap-2.5">
                {filters
                    .filter(({ model }) => !!model)
                    .map(({ colId }) => (
                        <FilterField
                            key={colId}
                            onSelect={handleFieldSelected}
                            data={
                                filterableFields!.find(
                                    (item) => item.colId === colId
                                )!
                            }
                            readonly={readonly}
                            columnHidden={hiddenFields[colId]}
                        />
                    ))}
                {!readonly && (
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(submit)}>
                            <InputField
                                name="name"
                                placeholder={t('filters.name-placeholder')}
                            />
                        </form>
                    </FormProvider>
                )}
            </FilterStep.Body>
            {!readonly && (
                <FilterStep.Footer>
                    <Button
                        variant="secondary"
                        className="flex-1"
                        disabled={!methods.formState.isValid}
                        onClick={save}
                    >
                        {t('filters.save')}
                    </Button>
                    <Button
                        variant="primary"
                        className="flex-1"
                        disabled={!methods.formState.isValid}
                        onClick={saveAndApply}
                    >
                        {t('filters.save-apply')}
                    </Button>
                </FilterStep.Footer>
            )}
            {selectedField && (
                <FilterStep.Nested>
                    {selectedField.filterType === 'text' ? (
                        <FilterTextStep
                            data={selectedField}
                            filter={filters.find(
                                (item) => item.colId === selectedField.colId
                            )}
                            onBack={closeNested}
                            tailwindStyle={{ top: ' top-6' }}
                            readonly
                        />
                    ) : selectedField.filterType === 'date' ? (
                        <FilterDateStep
                            data={selectedField}
                            filter={filters.find(
                                (item) => item.colId === selectedField.colId
                            )}
                            tailwindStyle={{ top: ' top-6' }}
                            onBack={closeNested}
                            readonly
                        />
                    ) : undefined}
                </FilterStep.Nested>
            )}
        </FilterStep>
    );
}

export default forwardRef(FilterSaveStep);
export type { FilterSaveStepProps };
