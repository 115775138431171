import { Button, Input, Modal } from 'lib-ui';
import { ChangeEvent, ReactNode, useCallback, useState } from 'react';
import {
    DownloadScheduleRepo,
    VerifyRepositoryConfigurationItem
} from 'core/api/client-portal/autogenerated/data-contracts';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import AddSourceControlConnectionModal from 'ui/source-control-connections/add-source-control-connection-modal/add-source-control-connection-modal';
import toast from 'react-hot-toast';
import useVerifyRepositoryConfiguration from 'core/api/client-portal/hooks/source-control-deposits/use-verify-repository-configuration';
export type AddBranchSelectionModalProps = {
    methods: UseFormReturn<FieldValues, unknown>;
    providersIdsOptions: {
        label: ReactNode;
        value: string | number;
    }[];
};

const AddBranchSelectionModal = ({
    methods,
    providersIdsOptions
}: AddBranchSelectionModalProps) => {
    const { t } = useTranslation();
    const { pop, push } = useModals();
    const [items, setItems] = useState<VerifyRepositoryConfigurationItem[]>([]);
    const [errors, setErrors] = useState<string | null>(null);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const existingConnections: DownloadScheduleRepo[] =
        methods.getValues('connectionsAdded');

    function getProviderLabel(id: string) {
        const provider = providersIdsOptions.find(
            (provider) => provider.value === parseInt(id)
        );
        const parsed_provider = provider?.label?.toString().split('-')[0];
        return parsed_provider !== '' ? parsed_provider : '';
    }

    const { isLoading, mutate: mutateFn } = useVerifyRepositoryConfiguration({
        onSuccess(data, variables, context) {
            const { success } = data.data;

            if (!success) {
                const errors_data = data.data.errors;

                let errorMessage = '';
                for (const id in errors_data) {
                    if (Object.prototype.hasOwnProperty.call(errors_data, id)) {
                        const errorList = errors_data[id];
                        // eslint-disable-next-line no-loop-func
                        errorList.forEach((error: unknown) => {
                            let errorMessageForId = '';
                            switch (error) {
                                case 'BadCredentials':
                                    errorMessageForId = `Invalid Credentials ${getProviderLabel(
                                        id
                                    )}`;
                                    break;
                                case 'BadBranch':
                                    errorMessageForId = `Invalid branch for ${getProviderLabel(
                                        id
                                    )}`;
                                    break;
                                case 'UnknownError':
                                    errorMessageForId = `Unknown error for ${getProviderLabel(
                                        id
                                    )}`;
                                    break;
                                default:
                            }
                            errorMessage += `${errorMessageForId},`;
                        });
                    }
                }

                if (errorMessage !== '') {
                    toast.error(t('toast.control-connection-error'), {
                        duration: 6000,
                        icon: '❌'
                    });
                    setErrors(errorMessage);
                    return;
                }
                toast.error(t('toast.control-connection-error'), {
                    duration: 4000,
                    icon: '❌'
                });
                return;
            }
            setErrors(null);
            toast.success(t('toast.control-connection-success'), {
                duration: 4000,
                icon: '✅'
            });

            // existingConnections is an array of DownloadScheduleRepo

            const newProviderIds = new Set(
                items.map((item) => item.providerId)
            );

            // Filter out existing connections that have matching provider IDs in `items`
            const filteredExistingConnections = existingConnections.filter(
                (connection) => !newProviderIds.has(connection.providerId)
            );

            methods.setValue('connectionsAdded', [
                ...filteredExistingConnections,
                ...items
            ]);
            setTimeout(() => {
                pop();
            }, 800);
        }
    });

    const handleFormData = useCallback(
        (item: string, repository: string | null) => {
            const rawObject: VerifyRepositoryConfigurationItem = {
                branchName: repository ?? null,
                id: Number(item),
                providerId: Number(item)
            };

            setItems((prev) => {
                if (prev.some((prevItem) => prevItem.id === rawObject.id)) {
                    // Item already exists, remove it
                    return prev.filter(
                        (prevItem) => prevItem.id !== rawObject.id
                    );
                } else {
                    // Item doesn't exist, add it
                    return [...prev, rawObject];
                }
            });
        },
        []
    );

    const handleChange = (
        event: ChangeEvent<HTMLInputElement>,
        index: number
    ): void => {
        const checkboxType = event.target.value;
        const newValue = checkboxType + index.toString();

        setSelectedValues((prev) => {
            // if the new value is already in the list, remove it
            if (prev.includes(newValue)) {
                return prev.filter((item) => item !== newValue);
            } else {
                // if the new value is not in the list, add it and remove the other checkbox of the same type
                const otherCheckboxType =
                    checkboxType === 'selectBranch'
                        ? 'wholeRepository'
                        : 'selectBranch';

                return [
                    ...prev.filter(
                        (item) => !item.startsWith(otherCheckboxType + index)
                    ),
                    newValue
                ];
            }
        });
    };
    const addConnection = useCallback(() => {
        push(AddSourceControlConnectionModal, {});
    }, [push]);

    return (
        <Modal size="l" className="h-126 w-150">
            <Modal.Header>
                <div className="flex w-full items-center justify-between">
                    {t('add-source-control-connection.title')}
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="flex w-full flex-col items-center justify-center">
                    {errors && (
                        <p className="text-general-red-red-120 text-sm flex w-full">
                            {errors}
                        </p>
                    )}
                    {providersIdsOptions.map((item, index) => {
                        return (
                            <div
                                className="flex w-full gap-4 items-center justify-between border-b border-solid border-gray-500 py-5"
                                key={index}
                            >
                                <div
                                    className="flex w-[46%] min-w-48 gap-3 text-nowrap"
                                    title={item.label?.toString().split('-')[0]}
                                >
                                    <p className="text-lg truncate">
                                        {item.label?.toString().split('-')[0]}
                                    </p>
                                </div>
                                <div className="flex flex-1 items-center justify-between gap-2">
                                    <div className="flex items-center text-wrap">
                                        <input
                                            type="checkbox"
                                            id="wholeRepository"
                                            value="wholeRepository"
                                            name={index.toString()}
                                            onClick={() =>
                                                handleFormData(
                                                    item.value.toString(),
                                                    null
                                                )
                                            }
                                            onChange={(event) =>
                                                handleChange(event, index)
                                            }
                                            checked={selectedValues.includes(
                                                'wholeRepository' + index
                                            )}
                                        />
                                        <label
                                            htmlFor="wholeRepository"
                                            className="ml-2"
                                        >
                                            {t(
                                                'add-source-control-connection.whole-repository'
                                            )}
                                        </label>
                                    </div>

                                    <div className="flex items-center text-wrap">
                                        <input
                                            type="checkbox"
                                            id="selectBranch"
                                            name={index.toString()}
                                            value="selectBranch"
                                            checked={selectedValues.includes(
                                                'selectBranch' + index
                                            )}
                                            onClick={() =>
                                                handleFormData(
                                                    item.value.toString(),
                                                    ''
                                                )
                                            }
                                            onChange={(event) =>
                                                handleChange(event, index)
                                            }
                                        />
                                        <label
                                            htmlFor="selectBranch"
                                            className="ml-2"
                                        >
                                            {t(
                                                'add-source-control-connection.select-branch'
                                            )}
                                        </label>
                                    </div>
                                    <Input
                                        id={item.value.toString()}
                                        className="h-[40px] bg-transparent border border-general-grey-grey-40 px-4 py-2 text-sm focus:outline-none transition-all text-general-grey-grey-100 rounded-lg"
                                        placeholder="Specific Branch"
                                        name="branchName"
                                        disabled={
                                            !selectedValues.includes(
                                                'selectBranch' + index
                                            )
                                        }
                                        required={selectedValues.includes(
                                            'selectBranch' + index
                                        )}
                                        onChange={(event) =>
                                            handleFormData(
                                                item.value.toString(),
                                                event.target.value
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer showCloseButtonAtEnd>
                <div className="flex w-full gap-4">
                    <Button
                        variant="primary"
                        onClick={() =>
                            mutateFn({
                                data: items
                            })
                        }
                        loading={isLoading}
                        disabled={
                            items.map((item) => item.branchName).includes('') ||
                            selectedValues.length === 0
                        }
                    >
                        {t('source-control.save')}
                    </Button>
                    <Button onClick={addConnection} variant="secondary">
                        {t('source-control.add-connection')}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default AddBranchSelectionModal;
