/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CommitFile,
    DepositCredentailsReturn,
    PrepareFile,
    PrepareResponse,
    ProblemDetails,
    SourceControlCredentials,
    StartDepositRequest,
    StartDepositResponse
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class ApiController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * @description Commit blockIds to a file
     *
     * @tags uploads
     * @name UploadsFilesCommit
     * @summary Commit file
     * @request POST:/api/uploads/{id}/{fileId}/commit
     * @secure
     */
    uploadsFilesCommit = (id: string, fileId: string, data: CommitFile, params: RequestParams = {}) =>
        this.request<void, ProblemDetails | void>({
            path: `/api/uploads/${id}/${fileId}/commit`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * @description Complete uploading for a deposit
     *
     * @tags uploads
     * @name UploadsComplete
     * @summary Complete uploading
     * @request POST:/api/uploads/{id}/complete
     * @secure
     */
    uploadsComplete = (id: string, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/uploads/${id}/complete`,
            method: 'POST',
            secure: true,
            ...params
        });
    /**
     * @description Delete a partially or completely uploaded file
     *
     * @tags uploads
     * @name UploadsFilesDelete
     * @summary delete a file upload
     * @request DELETE:/api/uploads/{id}/{fileId}
     * @secure
     */
    uploadsFilesDelete = (id: string, fileId: string, params: RequestParams = {}) =>
        this.request<void, ProblemDetails | void>({
            path: `/api/uploads/${id}/${fileId}`,
            method: 'DELETE',
            secure: true,
            ...params
        });
    /**
     * @description Prepare a deposit file for uploading
     *
     * @tags uploads
     * @name UploadsFilesPrepare
     * @summary Prepare file upload
     * @request PUT:/api/uploads/{id}/{fileId}
     * @secure
     */
    uploadsFilesPrepare = (id: string, fileId: string, data: PrepareFile, params: RequestParams = {}) =>
        this.request<PrepareResponse, ProblemDetails | void>({
            path: `/api/uploads/${id}/${fileId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * @description Create deposit credentials for source code deposit retrievals
     *
     * @tags credentials
     * @name CredentialsPost
     * @summary Create Deposit credentials for later use.
     * @request POST:/api/deposit-credentials
     * @secure
     */
    credentialsPost = (data: SourceControlCredentials, params: RequestParams = {}) =>
        this.request<DepositCredentailsReturn, ProblemDetails>({
            path: `/api/deposit-credentials`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * @description retrieve existing deposit credentials for source code deposits
     *
     * @tags credentials
     * @name CredentialsGet
     * @summary Retrieve Deposit credentials for later use.
     * @request GET:/api/deposit-credentials/{depositId}
     * @secure
     */
    credentialsGet = (depositId: string, params: RequestParams = {}) =>
        this.request<SourceControlCredentials, ProblemDetails>({
            path: `/api/deposit-credentials/${depositId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * @description Soft delete credentials for source code deposit retrievals
     *
     * @tags credentials
     * @name CredentialsDelete
     * @summary Perform a soft delete of Deposit credentials.
     * @request DELETE:/api/deposit-credentials/{depositId}
     * @secure
     */
    credentialsDelete = (depositId: string, params: RequestParams = {}) =>
        this.request<DepositCredentailsReturn, ProblemDetails>({
            path: `/api/deposit-credentials/${depositId}`,
            method: 'DELETE',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * @description Soft delete file share for source code deposit retrievals
     *
     * @tags DepositFileShare
     * @name DepositFileShareDelete
     * @summary Perform a soft delete of Deposit credentials.
     * @request DELETE:/api/deposit-fileshare/{depositId}
     * @secure
     */
    depositFileShareDelete = (depositId: string, params: RequestParams = {}) =>
        this.request<string, ProblemDetails>({
            path: `/api/deposit-fileshare/${depositId}`,
            method: 'DELETE',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * @description Start a deposit upload
     *
     * @tags uploads
     * @name UploadsStart
     * @summary Start deposit
     * @request POST:/api/uploads
     * @secure
     */
    uploadsStart = (data: StartDepositRequest, params: RequestParams = {}) =>
        this.request<StartDepositResponse, ProblemDetails | void>({
            path: `/api/uploads`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * @description Upload a block that is part of a file.
     *
     * @tags uploads
     * @name UploadsFilesBlocks
     * @summary Upload file block
     * @request PUT:/api/uploads/{id}/{fileId}/{blockId}
     * @secure
     */
    uploadsFilesBlocks = (
        id: string,
        blockId: string,
        fileId: string,
        data: {
            /**
             * File part.
             * @format binary
             */
            block?: File;
        },
        params: RequestParams = {}
    ) =>
        this.request<void, ProblemDetails | void>({
            path: `/api/uploads/${id}/${fileId}/${blockId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params
        });
}
