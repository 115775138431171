/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldError } from 'react-hook-form';

const getNestedError = (name: string, errors: any): string | undefined => {
    const segments = name.split('.');
    let error: any = errors;

    for (const segment of segments) {
        if (error && error[segment]) {
            error = error[segment];
        } else {
            return undefined;
        }
    }

    return (error as FieldError)?.message;
};

export default getNestedError;
