import React from 'react';

interface TermContentProps {
    activeTab: number;
}

const TermContent: React.FC<TermContentProps> = ({ activeTab }) => {
    const content = [
        {
            details: (
                <div className="flex flex-col">
                    <div className="flex flex-col gap-5 ml-5">
                        <p className="flex gap-2">
                            <span className="font-semibold">a.</span>
                            <span>
                                View is a secure online application through
                                which clients of NCC Group can request
                                information regarding their account and
                                agreements and electronically deposit escrow
                                material.
                            </span>
                        </p>
                        <div className="flex flex-col gap-5">
                            <p className="flex gap-2">
                                <span className="font-semibold">b.</span>
                                <span>
                                    By accessing or using View or SFTP, you
                                    agree to be bound by these terms. If you are
                                    using View or SFTP on behalf of a company,
                                    organisation, or other legal entity, then
                                    you:
                                </span>
                            </p>

                            <ol className="list-decimal ml-12 flex flex-col gap-2">
                                <li className="list-alpha">
                                    are accepting them on behalf of yourself and
                                    that entity;
                                </li>
                                <li className="list-alpha">
                                    represent and warrant that you are an
                                    authorised representative of the entity; and
                                </li>
                                <li className="list-alpha">
                                    have the requisite authority to bind the
                                    entity to these terms.
                                </li>
                            </ol>
                        </div>
                        <p className="flex gap-2">
                            <span className="font-semibold">c.</span>
                            <span>
                                You agree to promptly inform your account
                                manager at NCC Group of any changes in your role
                                or responsibilities which mean you are no longer
                                authorised to use View.
                            </span>
                        </p>
                        <p className="flex gap-2">
                            <span className="font-semibold">d.</span>
                            <span>
                                NCC Group will respond to all requests submitted
                                through View as soon as reasonably practicable.
                            </span>
                        </p>
                        <p className="flex gap-2">
                            <span className="font-semibold">e.</span>
                            <span>
                                The material you deposit will be held on NCC
                                Group's online deposit storage system and then
                                transferred to one of NCC Group's secure
                                electronic or physical vaults.
                            </span>
                        </p>
                    </div>
                </div>
            ),
            number: 1,
            title: 'Use of View'
        },
        {
            details: (
                <div className="flex flex-col gap-5 ml-5">
                    <p className="flex gap-2">
                        <span className="font-semibold">a.</span>
                        <span>
                            Clients of NCC Group can deposit and receive escrow
                            material electronically via SFTP.
                        </span>
                    </p>
                    <div className="flex flex-col gap-5">
                        <p className="flex gap-2">
                            <span className="font-semibold">b.</span>
                            <span>
                                The material you deposit will be transferred to
                                one of NCC Group's secure electronic or physical
                                vaults.
                            </span>
                        </p>
                    </div>
                </div>
            ),
            number: 2,
            title: 'Use of SFTP'
        },
        {
            details: (
                <div className="flex flex-col">
                    <div className="flex flex-col gap-5 ml-5">
                        <div className="flex flex-col gap-5">
                            <p className="flex gap-2">
                                <span className="font-semibold">a.</span>
                                <span>You warrant that</span>
                            </p>

                            <ol className="list-decimal ml-12 flex flex-col gap-2">
                                <li className="list-alpha">
                                    the information you provide to NCC Group via
                                    View and SFTP is correct and accurate to the
                                    best of your knowledge;
                                </li>
                                <li className="list-alpha">
                                    you have the full right, ability and
                                    authority to deposit the material;
                                </li>
                                <li className="list-alpha">
                                    your use of View and transfer of material
                                    via SFTP complies with all applicable laws
                                    and regulations which govern the use of
                                    online facilities and import, export or
                                    transmission of software and any other
                                    electronic data;
                                </li>
                                <li className="list-alpha">
                                    the deposit of the material via View or SFTP
                                    will not breach any personal data or import,
                                    re-import, export or re-export controls,
                                    laws or regulations;
                                </li>
                                <li className="list-alpha">
                                    the material you deposit is virus free.
                                </li>
                            </ol>
                        </div>
                        <div className="flex flex-col gap-5">
                            <p className="flex gap-2">
                                <span className="font-semibold">b.</span>
                                <span>You should ensure that:</span>
                            </p>

                            <ol className="list-decimal ml-12 flex flex-col gap-2">
                                <li className="list-alpha">
                                    every deposit is accompanied by a completed
                                    deposit form
                                </li>
                                <li className="list-alpha">
                                    you only make deposits in compressed archive
                                    file formats such as zip;
                                </li>
                                <li className="list-alpha">
                                    each electronic compressed file is not be
                                    larger than 46GB (and that if it is, split
                                    it so that each electronic compressed file
                                    is less than 46GB)
                                </li>
                                <li className="list-alpha">
                                    the total sum of each deposit will not be
                                    larger than 100GB (save as agreed in advance
                                    of the deposit with NCC Group).
                                </li>
                            </ol>
                        </div>
                        <p className="flex gap-2">
                            <span className="font-semibold">c.</span>
                            <span>
                                You may be required to pay NCC Group's
                                additional fees for any deposit that exceeds the
                                limits at clause b.
                            </span>
                        </p>
                        <p className="flex gap-2">
                            <span className="font-semibold">d.</span>
                            <span>
                                You acknowledge that NCC Group is not
                                responsible for any inaccuracies or errors in
                                the material that is deposited using SFTP or
                                View.
                            </span>
                        </p>
                    </div>
                </div>
            ),
            number: 3,
            title: 'Warranties and Deposit of material'
        },
        {
            details: (
                <div className="flex flex-col">
                    <div className="flex flex-col gap-5 ml-5">
                        <p className="flex gap-2">
                            <span className="font-semibold">a.</span>
                            <span>
                                NCC Group will handle personal data in
                                accordance with the Privacy Policy which can be
                                found{' '}
                                <a
                                    className="text-brand-escode-neonblue-neonblue-100 underline"
                                    href="https://www.escode.com/privacy-policy/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    here
                                </a>
                                .
                            </span>
                        </p>
                        <div className="flex flex-col gap-5">
                            <p className="flex gap-2">
                                <span className="font-semibold">b.</span>
                                <span>
                                    By inputting or depositing any personal data
                                    via View or SFTP, you warrant and represent
                                    that you:
                                </span>
                            </p>

                            <ol className="list-decimal ml-12 flex flex-col gap-2">
                                <li className="list-alpha">
                                    will only share personal data to the extent
                                    that it is necessary for the purposes of the
                                    relevant escrow agreement and for your use
                                    of View or STFP
                                </li>
                                <li className="list-alpha">
                                    have all requisite authority and have
                                    obtained and will maintain (including by way
                                    of incorporating into all materials and
                                    processes through which personal data is
                                    captured) all necessary consents required,
                                    or otherwise have a valid lawful basis for
                                    such disclosure under all applicable data
                                    protection and privacy legislation in force
                                    from time to time in the UK including
                                    without limitation the General Data
                                    Protection Regulation ((EU) 2016/679); the
                                    UK GDPR (having the meaning given to it in
                                    section 3(10) (as supplemented by section
                                    205(4)) of the Data Protection Act 2018);
                                    the Data Protection Act 2018; the Privacy
                                    and Electronic Communications Directive
                                    (2002/58/EC) (as updated by Directive
                                    2009/136/EC) and the Privacy and Electronic
                                    Communications Regulations 2003 (SI
                                    2003/2426) as amended (“Data Protection
                                    Legislation”);
                                </li>
                                <li className="list-alpha">
                                    have fully complied with and will comply
                                    with all of your obligations under the Data
                                    Protection Legislation
                                </li>
                                <li className="list-alpha">
                                    will clearly instruct NCC Group in relation
                                    to the personal data and enter into a data
                                    processing agreement where necessary.
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            ),
            number: 4,
            title: 'Personal Data'
        },
        {
            details: (
                <div className="flex flex-col">
                    <div className="flex flex-col gap-5 ml-5">
                        <p className="flex gap-2">
                            <span className="font-semibold">a.</span>
                            <span>
                                You must protect and keep secret the security
                                details issued to you to access View and SFTP
                                and must not record your security details in any
                                way which might lead to their discovery and/or
                                use by any other person.
                            </span>
                        </p>
                        <p className="flex gap-2">
                            <span className="font-semibold">b.</span>
                            <span>
                                You must take reasonable precautions in order to
                                prevent the unauthorised use of any device that
                                is logged into View for the whole time that the
                                device is logged into View.
                            </span>
                        </p>
                        <p className="flex gap-2">
                            <span className="font-semibold">c.</span>
                            <span>
                                If you suspect that an unauthorised person has
                                gained access to and/or used your security
                                details, you must inform NCC Group immediately.
                            </span>
                        </p>
                        <div className="flex flex-col gap-5">
                            <p className="flex gap-2">
                                <span className="font-semibold">d.</span>
                                <span>
                                    You acknowledge that you are solely
                                    responsible for:
                                </span>
                            </p>

                            <ol className="list-decimal ml-12 flex flex-col gap-2">
                                <li className="list-alpha">
                                    all information that is transmitted to View
                                    or via SFTP using your Security Details;
                                </li>
                                <li className="list-alpha">
                                    all actions in relation to View of any
                                    unauthorised Person who is logged into View
                                    using your security details
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            ),
            number: 5,
            title: 'Security'
        },
        {
            details: (
                <div className="flex flex-col">
                    <div className="flex flex-col gap-5 ml-5">
                        The confidentiality provisions of the escrow agreement
                        in place between NCC Group and you shall apply to the
                        material deposited using View and transferred to NCC
                        Group via SFTP.
                    </div>
                </div>
            ),
            number: 6,
            title: 'Confidentiality'
        },
        {
            details: (
                <div className="flex flex-col">
                    <div className="flex flex-col gap-5 ml-5">
                        <p className="flex gap-2">
                            <span>
                                Please read the limitation of liability
                                provisions carefully as they limit the liability
                                of NCC Group in relation to your use of View and
                                the transfer of material via SFTP.
                            </span>
                        </p>
                        <p className="flex gap-2">
                            <span className="font-semibold">a.</span>
                            <span>
                                Nothing in these terms and conditions shall
                                exclude or limit any liability that it is not
                                permissible to exclude or limit. In particular,
                                but without limitation, nothing in these terms
                                and conditions shall limit NCC Group's liability
                                for death or personal injury caused by its
                                negligence or fraudulent misrepresentation.
                            </span>
                        </p>
                        <p className="flex gap-2">
                            <span className="font-semibold">b.</span>
                            <span>
                                Whilst NCC Group has taken reasonable steps to
                                ensure the security and correct functioning of
                                View, no warranties or representations are given
                                (either express or implied) that View is secure
                                or will function correctly or that View will be
                                available at all times.
                            </span>
                        </p>
                        <p className="flex gap-2">
                            <span className="font-semibold">c.</span>
                            <span>
                                NCC Group shall not be liable for loss or damage
                                suffered by you through the use of View and SFTP
                                except to the extent that such loss or damage is
                                directly caused by NCC Group's negligence or
                                breach of contractual or statutory duty. In the
                                event of a claim or claims against NCC Group by
                                you for loss or damage, NCC Group shall only be
                                liable in the aggregate amount of £50,000 for
                                all claims. NCC Group shall not be liable for
                                any indirect or consequential loss or damage
                                (including, without limitation, loss of profit,
                                loss of business or depletion of goodwill) in
                                connection with the use of View and SFTP even if
                                such loss or damage was reasonably foreseeable.
                            </span>
                        </p>
                        <p className="flex gap-2">
                            <span className="font-semibold">d.</span>
                            <span>
                                You agree to indemnify and hold harmless NCC
                                Group from any losses, damages, fines, costs and
                                expenses that NCC Group incurs as a result of
                                your breach of any applicable laws or
                                regulations.
                            </span>
                        </p>
                        <p className="flex gap-2">
                            <span className="font-semibold">e.</span>
                            <span>
                                The parties acknowledge that this clause 7
                                covers the use of View or SFTP to provide code,
                                documents and/or information to NCC Group. Once
                                such material has been received by NCC Group,
                                NCC Group's liability for the material will be
                                covered by the liability in the relevant escrow
                                agreement entered into between you and NCC Group
                                or one of its affiliates.
                            </span>
                        </p>
                    </div>
                </div>
            ),
            number: 7,
            title: 'Limitation of Liability'
        },
        {
            details: (
                <div className="flex flex-col">
                    <div className="flex flex-col gap-5 ml-5">
                        <p className="flex gap-2">
                            <span className="font-semibold">a.</span>
                            <span>
                                NCC Group reserves the right to refuse access to
                                View for any reason.
                            </span>
                        </p>
                        <p className="flex gap-2">
                            <span className="font-semibold">b.</span>
                            <span>
                                The functions and availability of View and SFTP
                                are subject to change, suspension (for any
                                reason including, without limitation,
                                maintenance and/or upgrades) and termination by
                                NCC Group without notification.
                            </span>
                        </p>
                        <p className="flex gap-2">
                            <span className="font-semibold">c.</span>
                            <span>
                                These terms and conditions are subject to change
                                by NCC Group without notification. Therefore you
                                should ensure that you read the terms and
                                conditions on this page prior to each use of
                                View or transfer of material via SFTP.
                            </span>
                        </p>
                    </div>
                </div>
            ),
            number: 8,
            title: 'Changes to View, SFTP and these Terms and Conditions'
        },
        {
            details: (
                <div className="flex flex-col">
                    <div className="flex flex-col gap-5 ml-5">
                        <p className="flex gap-2">
                            <span>
                                In order to maintain the security of its
                                systems, protect its staff and detect fraud and
                                other crimes, NCC Group reserves the right to
                                monitor your use of View without notifying you.
                                Monitoring includes (without limitation)
                                checking for viruses and other malignant code,
                                criminal activity, the deposit of illegal,
                                seditious, pornographic, racist or other
                                undesirable information or content which is
                                unauthorised or which NCC Group considers as
                                being in breach of generally accepted and
                                reasonable information security policies.
                            </span>
                        </p>
                    </div>
                </div>
            ),
            number: 9,
            title: 'Internet safety and Security'
        },
        {
            details: (
                <div className="flex flex-col">
                    <div className="flex flex-col gap-5 ml-5">
                        <p className="flex gap-2">
                            <span>
                                In the event that any provision of these terms
                                and conditions is held to be unenforceable, it
                                will not affect the validity and enforceability
                                of the remaining provisions. The unenforceable
                                term will be treated as being replaced by an
                                enforceable provision that is the closest to the
                                intended purpose of the unenforceable provision.
                            </span>
                        </p>
                    </div>
                </div>
            ),
            number: 10,
            title: 'Enforceability'
        },
        {
            details: (
                <div className="flex flex-col">
                    <div className="flex flex-col gap-5 ml-5">
                        <p className="flex gap-2">
                            <span>
                                These terms of use, its subject matter and its
                                formation are governed by English law and any
                                disputes regarding the use of View or these
                                terms and conditions shall be subject to the
                                exclusive jurisdiction of the courts of England
                                and Wales.
                            </span>
                        </p>
                    </div>
                </div>
            ),
            number: 11,
            title: 'Governing Law'
        }
    ];

    const activeContent = content[activeTab - 1];

    return (
        <div className="w-3/4 p-6 max-h-[662px] overflow-y-scroll">
            <h1 className="font-semibold text-lg mb-4">
                {activeContent.number}. {activeContent.title}
            </h1>
            <div className="font-normal text-base">{activeContent.details}</div>
        </div>
    );
};

export default TermContent;
