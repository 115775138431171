import { UserResponseViewModel } from '../../autogenerated/data-contracts';

const useAgreementsMockData: UserResponseViewModel = {
    accountManagerFullName: 'accountManagerFullName',
    addressLine1: 'addressLine1',
    addressLine2: 'addressLine2',
    addressLine3: 'addressLine3',
    clientId: 1,
    clientName: 'clientName',
    contactId: 1,
    emailAddress: 'emailAddress',
    firstName: 'firstName',
    fullName: 'fullName',
    id: 'id',
    isActive: true,
    jobTitle: 'jobTitle',
    lastName: 'lastName',
    postcode: 'postcode',
    telephoneNumber: 'telephoneNumber',
    title: 'title',
    uiCulture: 'uiCulture',
    userName: 'userName'
};

export default useAgreementsMockData;
