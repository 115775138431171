import { Dropdown } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBell,
    faCloudArrowUp,
    faUniversalAccess
} from '@fortawesome/pro-regular-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useQueryClient } from 'react-query';
import AppHeader, { AppHeaderProps } from 'app-layout/app-header';
import HeaderButton from './header-button';
import LanguageSwitch from './language-switch/language-switch';
import UserButton from './user-button/user-button';

interface MainHeaderProps extends AppHeaderProps {
    collapsed?: boolean;
}

function MainHeader({ className, collapsed, ...restProps }: MainHeaderProps) {
    const { t } = useTranslation();
    const goTo = useNavigate();
    const location = useLocation();

    const isDepositNow = location.pathname.startsWith('/deposit-now');

    const queryClient = useQueryClient();

    const cssClasses = classNames(className, 'transition-all', {
        'left-sider': !collapsed,
        'left-sider-collapsed': collapsed
    });

    const handleDepositNow = () => {
        queryClient.invalidateQueries('use-deposit-details-v2');
        goTo('/deposit-now');
    };

    return (
        <AppHeader {...restProps} className={cssClasses}>
            {collapsed && (
                <h2 className="text-general-grey-grey-90 text-xl font-medium">
                    {/* NOTE: Do not translate app name */}
                    {t('header.app-name')}
                </h2>
            )}

            <HeaderButton
                className="ml-auto"
                disabled={isDepositNow}
                variant="primary"
                icon={
                    <FontAwesomeIcon
                        icon={faCloudArrowUp}
                        className="text-lg"
                    />
                }
                onClick={handleDepositNow}
            >
                {t('header.deposit-now')}
            </HeaderButton>
            <Dropdown multiTrigger suppressMaxSize className="flex gap-2">
                <HeaderButton
                    fab
                    icon={
                        <FontAwesomeIcon
                            icon={faUniversalAccess}
                            className="text-lg"
                        />
                    }
                />
                <LanguageSwitch />

                <HeaderButton
                    fab
                    icon={<FontAwesomeIcon icon={faBell} className="text-lg" />}
                />

                <UserButton />
            </Dropdown>
        </AppHeader>
    );
}

export default MainHeader;
export type { MainHeaderProps };
