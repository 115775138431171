import AppBody from './app-body';
import Profile from 'ui/settings/profile/profile';
import SettingsWidget from 'ui/settings/settings-widget/settings-widget';

function SettingsProfileBody() {
    return (
        <AppBody>
            <SettingsWidget>
                <Profile />
            </SettingsWidget>
        </AppBody>
    );
}

export default SettingsProfileBody;
