import { CommonProps } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MergedFileItem } from 'ui/deposit-now/upload-step/upload-types';
import {
    faClock,
    faCloudArrowUp,
    faCloudCheck,
    faCloudXmark,
    faTrash,
    faXmark
} from '@fortawesome/pro-solid-svg-icons';
import cn from 'core/utils/cn';

interface CardProcessProps extends CommonProps {
    file?: MergedFileItem;
    onDeleteFile?: () => void;
    percentCompleted?: number;
}
type StyleClasses = {
    backgroundColorClass: string;
    color: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon: any;
    iconClass: string;
    title: string;
    titleClass: string;
};

const formatFileSize = (bytes: number): string => {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;
    const gigabyte = megabyte * 1024;

    if (bytes < kilobyte) {
        return `${bytes} B`;
    } else if (bytes < megabyte) {
        return `${(bytes / kilobyte).toFixed(2)} KB`;
    } else if (bytes < gigabyte) {
        return `${(bytes / megabyte).toFixed(2)} MB`;
    } else {
        return `${(bytes / gigabyte).toFixed(2)} GB`;
    }
};
const CardProcess = ({
    className,
    file,
    onDeleteFile,
    percentCompleted,
    style
}: CardProcessProps) => {
    const isQueueFile = file?.uploadStatus !== undefined;
    const fillWidth = `${percentCompleted}%`;

    const stylesClasses: StyleClasses = {
        backgroundColorClass: '',
        color: '',
        icon: '',
        iconClass: '',
        title: '',
        titleClass: ''
    };

    if (isQueueFile) {
        if (file?.uploadStatus === 'verifying') {
            stylesClasses.backgroundColorClass = 'bg-general-orange-orange-10';
            stylesClasses.title = 'Validating:';
            stylesClasses.icon = null;
        } else if (file?.uploadStatus === 'queued') {
            stylesClasses.backgroundColorClass = 'bg-general-grey-grey-20';
            stylesClasses.icon = faClock;
            stylesClasses.title = 'In Queue:';
            stylesClasses.iconClass = 'text-general-grey-grey-100';
        } else if (file?.uploadStatus === 'uploading') {
            stylesClasses.backgroundColorClass = 'bg-general-grey-grey-20';
            stylesClasses.icon = faCloudArrowUp;
            stylesClasses.title = 'Uploading:';
            stylesClasses.iconClass = 'text-general-blue-blue-120';
        } else if (file?.uploadStatus === 'deleting') {
            stylesClasses.backgroundColorClass = 'bg-general-red-red-20';
            stylesClasses.icon = faTrash;
            stylesClasses.title = 'Deleting:';
            stylesClasses.iconClass = 'text-general-red-red-100';
        } else if (file.uploadStatus === 'error') {
            stylesClasses.title = 'Error:';
            stylesClasses.backgroundColorClass = 'bg-general-red-red-20';
            stylesClasses.icon = faCloudXmark;
            stylesClasses.iconClass = 'text-general-red-red-100';
        }
    } else {
        if (file?.completed && file?.verificationState === 0) {
            stylesClasses.backgroundColorClass = 'bg-general-orange-orange-10';
            stylesClasses.title = 'Validating:';
            stylesClasses.icon = null;
        } else if (file?.completed && file?.verificationState === 1) {
            // stylesClasses.title = 'Attached';
            stylesClasses.icon = faCloudCheck;
            stylesClasses.backgroundColorClass =
                'bg-general-lightpurple-lightpurple-10';
            stylesClasses.titleClass = 'text-escode-white';
            stylesClasses.iconClass = 'text-brand-escode-neonblue-neonblue-100';
        } else {
            stylesClasses.title = 'Upload Failed:';
            stylesClasses.backgroundColorClass = 'bg-general-red-red-20';
            stylesClasses.icon = faCloudXmark;
            stylesClasses.iconClass = 'text-general-red-red-100';
        }
    }

    return (
        <div
            className={cn(
                'rounded-lg w-full relative',
                stylesClasses.backgroundColorClass
            )}
        >
            <div className="flex flex-row justify-between py-4 px-4">
                <div
                    className={cn(
                        'flex flex-row items-center gap-2.5',
                        stylesClasses.titleClass
                    )}
                >
                    <span>
                        {stylesClasses.icon && (
                            <FontAwesomeIcon
                                icon={stylesClasses.icon}
                                className={stylesClasses.iconClass}
                            />
                        )}
                    </span>
                    <span className={stylesClasses.titleClass}>
                        {stylesClasses.title}
                    </span>
                    <span className="font-medium break-all">
                        {file?.fileName}
                    </span>
                    <span className="-ml-1.5">
                        ({file?.fileSize && `${formatFileSize(file.fileSize)}`})
                    </span>
                </div>
                <div className="flex items-center gap-2">
                    <span className="font-medium mr-2">
                        {isQueueFile &&
                            file.uploadStatus === 'uploading' &&
                            `${percentCompleted}%`}
                    </span>

                    {file?.uploadStatus !== 'verifying' && (
                        <button
                            className="text-gray-900 hover:text-gray-7 focus:outline-none focus:text-gray-500"
                            type="button"
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onClick={onDeleteFile || (() => {})}
                        >
                            <FontAwesomeIcon
                                data-testid="delete-file"
                                icon={faXmark}
                                className="hover:text-general-red-red-100"
                            />
                        </button>
                    )}
                </div>
            </div>

            <div
                className={cn(
                    'absolute bottom-0 w-full rounded-b-lg h-2',
                    file?.uploadStatus === 'uploading' &&
                        'bg-general-blue-blue-120'
                )}
                style={{
                    transition: 'width 0.1s ease-in',
                    width: fillWidth
                }}
            />
        </div>
    );
};

export default CardProcess;
