import React from 'react';

import AppBody from './app-body';
import AuditHistory from 'ui/settings/audit-history/audit-history';
import SettingsWidget from 'ui/settings/settings-widget/settings-widget';

const SettingsAuditBody = () => {
    return (
        <AppBody>
            <SettingsWidget>
                <AuditHistory />
            </SettingsWidget>
        </AppBody>
    );
};

export default SettingsAuditBody;
