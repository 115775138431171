import {
    ComponentType,
    ForwardRefExoticComponent,
    ForwardedRef,
    PropsWithChildren,
    RefAttributes,
    forwardRef
} from 'react';
import { TailwindProps, tailwindClasses } from '@ncc-frontend/core';
import MenuItem, { MenuItemProps } from './menu-item';
import classNames from 'classnames';

interface MenuProps extends TailwindProps {}

const Menu = forwardRef(function Menu(
    {
        children,
        className,
        tailwindStyle,
        ...restProps
    }: PropsWithChildren<MenuProps>,
    ref: ForwardedRef<HTMLDivElement>
) {
    const cssClasses = classNames(
        className,
        tailwindClasses(
            {
                background: 'bg-white',
                borderRadius: 'rounded-xl',
                boxShadow: 'shadow',
                display: 'flex',
                flexDirection: 'flex-col',
                gap: 'gap-4',
                padding: 'p-3'
            },
            tailwindStyle
        )
    );

    return (
        <div ref={ref} className={cssClasses} {...restProps}>
            {children}
        </div>
    );
}) as ForwardRefExoticComponent<
    PropsWithChildren<MenuProps> & RefAttributes<HTMLDivElement>
> & {
    Item: ComponentType<MenuItemProps>;
};
Menu.Item = MenuItem;

export default Menu;
export type { MenuProps };
