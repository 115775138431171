import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useAuth } from 'react-oidc-context';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { CheckField, InputField, SelectField } from 'lib-ui';
import Button from 'lib-ui/button/button';
import CloudFlareTurnstile from 'ui/turnstile/turnstile';
import toastFunctions from 'lib-ui/toast/Toast';
import useRegisterUser from 'core/api/client-portal-v2/hooks/auth/useRegisterUser';

type FormData = {
    acceptedTerms: boolean;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    agreementNumber: undefined;
    cloudFlareToken: string;
    company: string;
    emailAddress: string;
    firstName: string;
    jobTitle: string;
    lastName: string;
    password: string;
    passwordConfirm: string;
    postcode: string;
    telephone: string;
    title: string;
};

function Register() {
    const { t } = useTranslation();
    const { mutate: registerUser } = useRegisterUser();
    const { signinRedirect } = useAuth();
    const goTo = useNavigate();
    const [cloudFlareToken, setCloudFlareToken] = useState<string | undefined>(
        undefined
    );

    const agreementNumberMessage = 'Please enter a valid agreement number';
    const requiredMessage = 'This field is required';
    const titleMessage = 'This field is required';
    const schema = yup.object({
        acceptedTerms: yup
            .boolean()
            .oneOf([true], requiredMessage)
            .required(requiredMessage),
        addressLine1: yup.string().required(requiredMessage),
        agreementNumber: yup
            .number()
            .required(agreementNumberMessage)
            .typeError(agreementNumberMessage),
        cloudFlareToken: yup.string(),
        company: yup.string().required(requiredMessage),
        emailAddress: yup.string().required(requiredMessage),
        firstName: yup.string().required(requiredMessage),
        jobTitle: yup.string().required(requiredMessage),
        lastName: yup.string().required(requiredMessage),
        password: yup.string().required(requiredMessage),
        passwordConfirm: yup
            .string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required(requiredMessage),
        postcode: yup.string().required(requiredMessage),
        telephone: yup.string().required(requiredMessage),
        title: yup.string().required(titleMessage).typeError(titleMessage)
    });

    const methods = useForm<FormData>({
        defaultValues: {
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            agreementNumber: undefined,
            cloudFlareToken: '',
            company: '',
            emailAddress: '',
            firstName: '',
            jobTitle: '',
            lastName: '',
            password: '',
            passwordConfirm: '',
            postcode: '',
            telephone: '',
            title: ''
        },
        mode: 'all',
        resolver: yupResolver(schema)
    });

    const titles = [
        {
            label: 'Mr',
            value: 'mr'
        },
        {
            label: 'Mrs',
            value: 'mrs'
        },
        {
            label: 'Ms',
            value: 'ms'
        },
        {
            label: 'Sir',
            value: 'sir'
        }
    ];

    const submit = useCallback<SubmitHandler<FormData>>(
        (formData) => {
            const token = cloudFlareToken;
            const payload = {
                ...formData,
                cloudFlareToken: token
            };
            // goTo(`/confirm-registration`);

            registerUser(
                { data: payload },
                {
                    onError(err) {
                        toastFunctions.error(t('toast.error'));
                    },
                    onSuccess(res) {
                        toastFunctions.success(t('toast.success'));
                        setCloudFlareToken('reset');
                        goTo(`/confirm-registration`);
                    }
                }
            );
        },
        [cloudFlareToken, goTo, registerUser, t]
    );
    return (
        <div>
            <section className="w-full p-[7rem]" data-testid="layout-section">
                <h1 className="text-general-grey-grey-90 text-3xl pb-5.5 text-center">
                    <span className="font-semibold text-brand-escode-neonblue-neonblue-100">
                        View&nbsp;
                    </span>
                    {t('auth.register.title')}
                </h1>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(submit)}>
                        <div className="flex flex-col gap-4">
                            <div className="grid grid-cols-3 gap-4">
                                <SelectField
                                    name="title"
                                    label="Title"
                                    placeholder="Title"
                                    options={titles}
                                    required
                                />
                                <InputField
                                    name="firstName"
                                    label="First Name"
                                    placeholder="First Name"
                                    required
                                />
                                <InputField
                                    name="lastName"
                                    label="Last Name"
                                    placeholder="Last Name"
                                    required
                                />
                            </div>

                            <div className="grid grid-cols-3 gap-4">
                                <InputField
                                    name="jobTitle"
                                    label="Job Title"
                                    placeholder="Job Title"
                                    required
                                />
                                <InputField
                                    name="company"
                                    label="Company Name"
                                    placeholder="Company Name"
                                    required
                                />
                                <InputField
                                    name="addressLine1"
                                    label="Address Line 1"
                                    placeholder="Address Line 1"
                                    required
                                />
                            </div>

                            <div className="grid grid-cols-3 gap-4">
                                <InputField
                                    name="addressLine2"
                                    label="Address Line 2"
                                    placeholder="Address Line 2"
                                />
                                <InputField
                                    name="addressLine3"
                                    label="Address Line 3"
                                    placeholder="Address Line 3"
                                />
                                <InputField
                                    name="postcode"
                                    label="Post Code"
                                    placeholder="Post Code"
                                    required
                                />
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <InputField
                                    name="telephone"
                                    label="Telephone"
                                    placeholder="345872 8494"
                                    required
                                />
                                <InputField
                                    name="emailAddress"
                                    label="Email Address"
                                    placeholder="name@email.com"
                                    required
                                />
                            </div>

                            <div className="grid grid-cols-1 gap-4">
                                <InputField
                                    name="agreementNumber"
                                    label="Agreement number"
                                    placeholder="2734831"
                                    required
                                />
                            </div>

                            <div className="grid grid-cols-1 gap-4">
                                <div>
                                    <p> {t('auth.register.password')}</p>
                                    <p>
                                        {t(
                                            'auth.register.passwordRequirements'
                                        )}
                                    </p>
                                </div>

                                <div className="grid grid-cols-2 gap-4">
                                    <InputField
                                        name="password"
                                        label="Password"
                                        placeholder="MyPassword1234!"
                                        type="password"
                                        required
                                    />
                                    <InputField
                                        name="passwordConfirm"
                                        label="Confirm Password"
                                        placeholder="MyPassword1234!"
                                        type="password"
                                        required
                                    />
                                </div>
                                <CheckField
                                    id="acceptedTerms"
                                    label={
                                        <span>
                                            I accept the{' '}
                                            <button
                                                className="text-brand-escode-neonblue-neonblue-100 underline"
                                                onClick={() => goTo(`/terms`)}
                                            >
                                                {t('terms-and-conditions')}
                                            </button>
                                        </span>
                                    }
                                    name="acceptedTerms"
                                />
                                <div className="flex justify-between gap-4">
                                    <CloudFlareTurnstile
                                        cloudFlareToken={cloudFlareToken}
                                        setCloudFlareToken={setCloudFlareToken}
                                    ></CloudFlareTurnstile>
                                    <div className="flex flex-col justify-end items-end">
                                        <div>
                                            <Button
                                                variant="primary"
                                                className="col-span-2"
                                            >
                                                {t(
                                                    'auth.register.registerButton'
                                                )}
                                            </Button>
                                        </div>
                                        <div className="flex justify-end mt-2">
                                            <p>
                                                {t(
                                                    'auth.register.alreadyHaveAccount'
                                                )}
                                                &nbsp;
                                            </p>
                                            <button
                                                className="text-brand-escode-neonblue-neonblue-100"
                                                data-testid="link"
                                                onClick={() => signinRedirect()}
                                                type="button"
                                            >
                                                {t('auth.register.logIn')}{' '}
                                                <FontAwesomeIcon
                                                    icon={faArrowRight}
                                                    className="text-sm text-brand-escode-neonblue-neonblue-100"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </section>
        </div>
    );
}

export default Register;
