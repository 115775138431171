import { DocumentMetadataResponseViewModel } from '../../autogenerated/data-contracts';
import { UseQueryOptions, useQuery } from 'react-query';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

import useIntegrityReportMockData from './use-integrity-report-mock-data';

type IntegrityReportParams = {
    agreementId: number | undefined;
    depositId: number | undefined;
};

function useIntegrityReport<TError = unknown>(
    query: IntegrityReportParams,
    options?: Omit<
        UseQueryOptions<
            DocumentMetadataResponseViewModel | undefined,
            ReactQueryError<TError>
        >,
        'queryKey' | 'queryFn'
    >
) {
    const auth = useAuth();

    return useQuery<
        DocumentMetadataResponseViewModel | undefined,
        ReactQueryError<TError>
    >(
        [
            'deposits',
            'documents',
            {
                query,
                token: auth.user?.access_token,
                tokenType: auth.user?.token_type
            }
        ],
        async () => {
            if (!query.agreementId || !query.depositId) {
                console.warn(
                    'Undefined agreementId or depositId in useDepositVerificationList'
                );
                return undefined;
            }

            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useDepositVerificationList call ignored.',
                    query
                );
                return useIntegrityReportMockData;
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            const response =
                await controller.getTheSingleIntegrityReportForADeposit(
                    query.depositId
                );
            return response.data;
        },
        options
    );
}

export default useIntegrityReport;
export type { IntegrityReportParams };
