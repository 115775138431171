/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation } from 'react-query';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from 'core/api/interceptors';
import useDepositNowStore from 'core/stores/use-deposit-now-store';
import useRefreshToken from 'core/api/spectre-control/hooks/spectre-control-refresh/use-refresh-token';

type CustomFileParams = {
    depositId: string;
    uniqueReference?: string;
};

function useUploadsStart<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<any>,
            ReactQueryError<TError>,
            CustomFileParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();
    const { refetch } = useRefreshToken();
    const { VVToken, spectreAgentUrl } = useDepositNowStore();

    return useMutation(
        [
            'uploadsStart',
            { token: auth.user?.access_token, tokenType: auth.user?.token_type }
        ],
        async ({
            depositId
        }: CustomFileParams): Promise<AxiosResponse<any>> => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn('Mock is enabled useUploadsStart call ignored.');
                return Promise.resolve({} as AxiosResponse<any>);
            }

            let refreshedToken = '';

            const baseURL = spectreAgentUrl;
            await refetch().then((data) => {
                refreshedToken = data.data as string;
            });

            const controller = new ApiController({
                baseURL,
                headers: {
                    Authorization: `Bearer ${refreshedToken ?? VVToken}`,
                    'Cache-FormControl': 'no-cache, no-store, must-revalidate',
                    Expires: '0',
                    Pragma: 'no-cache'
                },
                interceptors
            });

            return controller.uploadsStart(
                {
                    depositId: depositId
                },
                {
                    headers: {
                        Authorization: `Bearer ${refreshedToken ?? VVToken}`,
                        'Cache-FormControl':
                            'no-cache, no-store, must-revalidate',
                        Expires: '0',
                        Pragma: 'no-cache'
                    }
                }
            );
        },
        {
            ...options
        }
    );
}

export default useUploadsStart;
