import { ButtonActiveId } from '../upload-step';
import {
    DownloadScheduleRepo,
    FrequencyType
} from 'core/api/client-portal/autogenerated/data-contracts';
import { Frequency } from 'lib-ui/source-control-deposits/frequency-enum';
import { Modal } from 'lib-ui';
import { useCallback } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'lib-ui/button/button';
import FeedbackFormModal from 'ui/feedback-form/feedback-form-modal';
import toast from 'react-hot-toast';
import useDepositConfirmUpdate from 'core/api/client-portal-v2/hooks/deposits/use-deposit-confirm-update';
import useRefreshToken from 'core/api/spectre-control/hooks/spectre-control-refresh/use-refresh-token';
import useSchedulesCreate from 'core/api/client-portal/hooks/schedules/use-schedules-create';
import useUploadsComplete from 'core/api/spectre-agent/hooks/uploads-complete/use-uploads-complete';

export type FinishDepositModalProps = {
    activeId: ButtonActiveId;
    depositId: number;
    depositUniqueReference?: string;
    description?: string;
    frequencyType?: FrequencyType;
    isOnPrem?: boolean;
    items: DownloadScheduleRepo[];
    nextDownloadAt?: string;
    title?: string;
};

function adjustDateToNextYear(dateStr: string): string {
    const currentDate = new Date();
    const inputDate = new Date(dateStr);

    // Check if the input date is in the past
    if (inputDate < currentDate) {
        inputDate.setFullYear(currentDate.getFullYear() + 1); // Increment the year by one
    }

    return inputDate.toISOString();
}

const FinishDepositModal = ({
    activeId,
    depositId,
    depositUniqueReference,
    description,
    frequencyType,
    isOnPrem,
    items,
    nextDownloadAt,
    title
}: FinishDepositModalProps) => {
    const { t } = useTranslation();
    const { pop, push } = useModals();
    const goTo = useNavigate();
    const { refetch } = useRefreshToken();

    // for On Prem deposits
    const { isLoading: isOnPremLoading, mutate: confirmOnPremDeposit } =
        useDepositConfirmUpdate({
            onError() {
                toast.error(t('finish-deposit-modal.not-confirmed'));
            },
            onSuccess() {
                toast.success(t('finish-deposit-modal.confirmed'));
                setTimeout(() => {
                    pop();
                    if (!localStorage.getItem('feedback-form-submitted')) {
                        push(FeedbackFormModal, {});
                    }
                    goTo('/');
                }, 1500);
            }
        });

    // for VV deposits
    const { isLoading: isVVLoading, mutate: confirmVVDeposit } =
        useUploadsComplete({
            onError() {
                toast.error(t('finish-deposit-modal.not-confirmed'));
            },
            onSuccess() {
                toast.success(t('finish-deposit-modal.confirmed'));
                setTimeout(() => {
                    pop();
                    if (!localStorage.getItem('feedback-form-submitted')) {
                        push(FeedbackFormModal, {});
                    }
                    goTo('/');
                }, 1500);
            }
        });

    const { isLoading: createSchedulesLoading, mutate: schedulesCreate } =
        useSchedulesCreate({
            onError() {
                toast.error(t('finish-deposit-modal.not-confirmed'));
            },
            onSuccess() {
                toast.success(t('finish-deposit-modal.scheduled'));
                setTimeout(() => {
                    pop();
                    if (!localStorage.getItem('feedback-form-submitted')) {
                        localStorage.setItem('feedback-form-submitted', 'true');
                        push(FeedbackFormModal, {});
                    }
                    goTo(`/agreements/`);
                }, 1500);
            }
        });

    const handleSubmit = useCallback(async () => {
        if (activeId === 'from_device') {
            if (!isOnPrem && depositUniqueReference) {
                await refetch();

                setTimeout(() => {
                    confirmVVDeposit({
                        depositUniqueReference
                    });
                }, 1500);
                return;
            }
            return confirmOnPremDeposit({
                depositId
            });
        }
        if (activeId === 'source_control') {
            let nextDownloadAtData = nextDownloadAt ?? new Date().toISOString();
            const frequencyTypeData = frequencyType ?? FrequencyType.Value0;

            // check for daily frequency
            if (frequencyTypeData.toString() === Frequency.Daily) {
                console.log(nextDownloadAt);
            }

            // check for quarterly frequency
            if (frequencyTypeData.toString() === Frequency.Quarterly) {
                nextDownloadAtData = adjustDateToNextYear(nextDownloadAtData);
            }

            return schedulesCreate({
                depositId,
                frequencyType: frequencyTypeData,
                items,
                nextDownloadAt: nextDownloadAtData
            });
        }
        return;
    }, [
        activeId,
        confirmOnPremDeposit,
        confirmVVDeposit,
        depositId,
        depositUniqueReference,
        frequencyType,
        isOnPrem,
        items,
        nextDownloadAt,
        refetch,
        schedulesCreate
    ]);

    return (
        <Modal className="overflow-y-auto" size="s">
            <Modal.Header>
                <h2 className="text-brand-escode-neonblue-neonblue-100 text-xl">
                    {title}
                </h2>
            </Modal.Header>
            <span className="py-2 mt-2">{description}</span>
            <Modal.Body className="flex flex-col gap-1.5"></Modal.Body>
            <Modal.Footer showCloseButtonAtEnd>
                <Button
                    data-testid="confirm-button"
                    variant="secondary"
                    loading={
                        isOnPremLoading || isVVLoading || createSchedulesLoading
                    }
                    onClick={handleSubmit}
                >
                    {t('finish-deposit-modal.confirm-button')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default FinishDepositModal;
