function timestampFormatter(
    value: string | Date | null | undefined,
    locale?: string,
    options: { dateOnly?: boolean } = {}
) {
    if (!value) return '';

    const date = typeof value === 'object' ? value : new Date(value);

    if (isNaN(date.getTime())) {
        return '';
    }

    if (options.dateOnly) {
        return date.toLocaleDateString(locale);
    }

    return date.toLocaleString(locale).replace(/,/g, '');
}

export default timestampFormatter;
