import { Divider, DropdownPanel, Menu } from 'lib-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { MouseEvent, useCallback } from 'react';
import {
    faArrowRightFromBracket,
    faArrowUpLeftFromCircle,
    faBug,
    faCommentsQuestion,
    faGear,
    faToggleLargeOn,
    faUser
} from '@fortawesome/pro-regular-svg-icons';
import { useAuthActions } from 'common/use-auth-actions';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import FeedbackFormModal, {
    FeedbackFormTriggers
} from 'ui/feedback-form/feedback-form-modal';
import TerminationFormModal from 'ui/termination-form/termination-form-modal';

function UserPanel() {
    const { t } = useTranslation();
    const { pop, push } = useModals();
    const { handleSignoutRedirect } = useAuthActions();

    const OLD_PORTAL_URL = process.env.REACT_APP_OLD_PORTAL_URL ?? '';

    const handleFeedbackForm = useCallback(
        (trigger: FeedbackFormTriggers) => {
            if (trigger === 'userPanelClassicView') {
                if (localStorage.getItem('feedback-form-submitted')) {
                    localStorage.removeItem('feedback-form-submitted');
                    window.location.href = OLD_PORTAL_URL;
                    return;
                } else {
                    push(FeedbackFormModal, {
                        trigger: 'userPanelClassicView'
                    });
                }
            }

            if (trigger === 'userPanelFeedback') {
                push(FeedbackFormModal, {
                    trigger: 'userPanelFeedback'
                });
            }

            if (trigger === 'userPanelLogOut') {
                if (localStorage.getItem('feedback-form-submitted')) {
                    pop();
                    localStorage.removeItem('beta-banner');
                    localStorage.removeItem('feedback-form-submitted');
                    handleSignoutRedirect();
                } else {
                    push(FeedbackFormModal, { trigger: 'userPanelLogOut' });
                }
            }
        },
        [OLD_PORTAL_URL, handleSignoutRedirect, pop, push]
    );

    const openTerminationForm = useCallback(() => {
        push(TerminationFormModal, {});
    }, [push]);

    const showCollector = (e: MouseEvent<HTMLDivElement>) => {
        // README: In order to trigger the collector from a dynamically created trigger
        // the showCollectorDialog function given by Jira has been stored on a global
        // object. The creation of NCC_GLOBALS and the hook up of the showCollectorDialog functions
        // is done on a script tag in the index.html file.
        window.NCC_GLOBALS.showCollectorDialog?.();
    };
    const menuTextStyles = 'font-medium';

    return (
        <DropdownPanel>
            <Menu
                tailwindStyle={{
                    gap: 'gap-1'
                }}
            >
                <Link className="flex items-center m-0" to="/settings/profile">
                    <Menu.Item className={menuTextStyles}>
                        <FontAwesomeIcon icon={faUser} />
                        {t('user-menu.my-profile')}
                    </Menu.Item>
                </Link>
                <Divider color="bg-escode-grey-20" />
                <Menu.Item
                    className={menuTextStyles}
                    onClick={() => handleFeedbackForm('userPanelClassicView')}
                >
                    <FontAwesomeIcon icon={faToggleLargeOn} />
                    {t('user-menu.classic-view')}
                </Menu.Item>
                <Menu.Item className={menuTextStyles} onClick={showCollector}>
                    <FontAwesomeIcon icon={faBug} />
                    {t('user-menu.report-problem')}
                </Menu.Item>
                <Menu.Item
                    className={menuTextStyles}
                    onClick={() => handleFeedbackForm('userPanelFeedback')}
                >
                    <FontAwesomeIcon icon={faCommentsQuestion} />
                    {t('header.feedback-form')}
                </Menu.Item>
                <Menu.Item className={menuTextStyles}>
                    <Link
                        className="flex items-center gap-2"
                        to="/settings/system"
                    >
                        <FontAwesomeIcon icon={faGear} />
                        {t('user-menu.settings')}
                    </Link>
                </Menu.Item>
                <Menu.Item
                    className={menuTextStyles}
                    onClick={openTerminationForm}
                >
                    <FontAwesomeIcon icon={faArrowUpLeftFromCircle} />
                    {t('user-menu.termination-form')}
                </Menu.Item>
                <Divider color="bg-gray-200" />
                <Menu.Item
                    className={`${menuTextStyles} text-general-red-red-100`}
                    onClick={() => handleFeedbackForm('userPanelLogOut')}
                >
                    <FontAwesomeIcon icon={faArrowRightFromBracket} />
                    {t('user-menu.log-out')}
                </Menu.Item>
            </Menu>
        </DropdownPanel>
    );
}

export default UserPanel;
