/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Add an agreement party. */
export interface AddAgreementParty {
    /**
     * Gets or sets the name.
     * @minLength 1
     * @maxLength 150
     */
    name: string;
    /**
     * Gets or sets the company.
     * @minLength 1
     * @maxLength 150
     */
    company: string;
    /**
     * Gets or sets the phone.
     * @format tel
     * @maxLength 50
     */
    phone?: string | null;
    /**
     * Gets or sets the email.
     * @format email
     * @minLength 1
     * @maxLength 150
     */
    emailAddress: string;
    /**
     * Gets or sets the comments.
     * @maxLength 3000
     */
    comments?: string | null;
}

/** Adds a file to the deposit. */
export interface AddFileToDepositRequest {
    /**
     * Gets or sets the file identifier.
     * @format uuid
     */
    fileUuid?: string;
    /**
     * Gets or sets the file identifier.
     * @format uuid
     */
    uploadedByUuid?: string;
    /** Gets or sets the name of the file. */
    fileName?: string | null;
    /** Gets or sets the hash of the file. */
    fileHash?: string | null;
    /**
     * Gets or sets the size.
     * @format int64
     */
    size?: number;
    /** Gets or sets the completion flag. */
    completed?: boolean;
    /** Gets or sets the moved flag. */
    moved?: boolean;
    /** File verification status. */
    verificationState?: VerificationState;
}

/** View model for adding an ssh key. */
export interface AddSshKeyRequestViewModel {
    /**
     * Gets or sets the key.
     * @format binary
     */
    key: File;
    /**
     * Gets or sets the name of the key.
     * @minLength 1
     */
    keyName: string;
}

/** The Agreement DTO. */
export interface Agreement {
    /**
     * Gets or sets the identifier.
     * @format int32
     */
    id?: number;
    /**
     * Gets or sets the agreement number.
     * @format int32
     */
    agreementNumber?: number;
    /** Gets or sets the agreement status for the viewing party. */
    agreementStatus?: string | null;
    /** Gets or sets the type of the agreement. */
    agreementType?: string | null;
    /** Gets or sets the name of the software package. */
    softwarePackageName?: string | null;
    /** Agreement Deposit. */
    latestDeposit?: AgreementDeposit;
    /**
     * Gets or sets the live date.
     * @format date-time
     */
    liveDate?: string | null;
    /**
     * Gets or sets the renewal date.
     * @format date-time
     */
    renewalDate?: string | null;
    /** Gets or sets the licensees. */
    licensees?: Party[] | null;
    /** Gets or sets the privileged parties (owner, depositor, distributor). */
    owners?: Party[] | null;
    /**
     * Gets or sets the number of associated parties.
     * @format int32
     */
    numberOfAssociatedParties?: number;
    /**
     * Gets or sets the last verification exercise date.
     * @format date-time
     */
    lastVerificationExerciseDate?: string | null;
    /** Gets or sets a value indicating whether the viewing party can upload a deposit. */
    viewingPartyCanUploadDeposit?: boolean;
    /**
     * Gets or sets the free deposits remaining.
     * @format int32
     */
    freeDepositsRemaining?: number;
    /** Whether the current user is a member of the owning party. */
    isOwner?: boolean;
    /** Gets or sets IsMulitAgreementType. */
    isMultiAgreementType?: boolean;
    /** Gets or sets the agreement location (3 letter office code). */
    location?: string | null;
    /** The name of the uploader associated with this agreement's location. */
    uploaderName?: string | null;
    /** Gets or sets the billing account. */
    billingAccount?: string | null;
    /** Gets or sets the billing account address. */
    billingAccountAddress?: string | null;
    /** Gets or sets the HasBespokeTestingRequirements. */
    hasBespokeTestingRequirements?: boolean;
    /** Gets or sets the HasOptedOutOfVVStorage. */
    hasOptedOutOfVVStorage?: boolean;
}

/** Agreement Deposit. */
export interface AgreementDeposit {
    /**
     * Gets or sets the deposit date.
     * @format date-time
     */
    depositDate?: string;
    /** Gets or sets the level of verification. */
    levelOfVerification?: string | null;
    /** Gets or sets the version. */
    version?: string | null;
    /** Gets or sets a value indicating whether this deposit has been verified. */
    isVerified?: boolean;
}

/** Agreement Details. */
export interface AgreementDetails {
    /**
     * Gets or sets the identifier.
     * @format int32
     */
    agreementId?: number;
    /**
     * Gets or sets the agreement reference.
     * @format int32
     */
    agreementNumber?: number;
    /** Gets or sets the type of the agreement. */
    agreementType?: string | null;
    /** Gets or sets the agreement status. */
    agreementStatus?: string | null;
    /** Gets or sets the name of the software package. */
    softwarePackageName?: string | null;
    /** Gets or sets the software owner. */
    softwareOwner?: string | null;
    /**
     * Gets or sets the last deposit date (of a deposit the logged on user has access to).
     * @format date-time
     */
    lastDepositDate?: string | null;
    /**
     * Gets or sets the number of associated parties.
     * @format int32
     */
    numberOfAssociatedParties?: number;
    /** Gets or sets a value indicating whether the  viewing party can create a deposit. */
    viewingPartyCanCreateDeposit?: boolean;
    /**
     * Gets the free deposits remaining.
     * @format int32
     */
    freeDepositsRemaining?: number | null;
    /** Whether the current user is a member of the owning party. */
    isOwner?: boolean;
    /** Gets or sets IsMultiAgreementType. */
    isMultiAgreementType?: boolean;
    /** Gets or sets Viewable Agreement Parties. */
    viewableAgreementParties?: AgreementPartyViewModel[] | null;
    /** Gets or sets the HasBespokeTestingRequirements. */
    hasBespokeTestingRequirements?: boolean;
    /** Gets or sets the HasOptedOutOfVVStorage. */
    hasOptedOutOfVVStorage?: boolean;
}

/** Agreements Response. */
export interface AgreementListResponseViewModel {
    /** Gets or sets the agreements. */
    agreements?: AgreementViewModel[] | null;
}

/** The agreement party contact view model. */
export interface AgreementPartyContactViewModel {
    /**
     * Gets or sets party contact id.
     * @format int32
     */
    id?: number;
    /**
     * Gets or sets the contact identifier.
     * @format int32
     */
    contactId?: number;
    /**
     * Gets or sets the type.
     * <remarks>
     * This can be main, code and finance
     * </remarks>
     */
    roles?: string[] | null;
    /** Gets or sets the name. */
    name?: string | null;
}

/** Agreement Party View Model. */
export interface AgreementPartyViewModel {
    /**
     * Gets or sets Agreement Party Id.
     * @format int32
     */
    agreementPartyId?: number;
    /** Gets or sets the name of the registered. */
    registeredName?: string | null;
    /** Gets or sets the type of the party. */
    partyType?: string | null;
    /** Gets or sets the party status. */
    partyStatus?: string | null;
    /**
     * Gets or sets the party status date.
     * @format date-time
     */
    partyStatusDate?: string | null;
    /** Gets or sets a value indicating whether this ncc.escrow.view.api.clientportal.Model.V1.AgreementParties.AgreementPartyViewModel is depositor. */
    depositor?: boolean;
    /** Gets or sets the billing account. */
    billingAccount?: string | null;
    /** Get or sets the Agreement Party Contacts. */
    agreementPartyContacts?: AgreementPartyContactViewModel[] | null;
    /** Can edit party contacts. */
    canEditContacts?: boolean;
}

/** The agreement view model. */
export interface AgreementViewModel {
    /**
     * Gets or sets the agreement identifier.
     * @format int32
     */
    agreementId?: number;
    /**
     * Gets or sets the agreement reference.
     * @format int32
     */
    agreementNumber?: number;
    /** Gets or sets the type of the agreement. */
    agreementType?: string | null;
    /** The agreement status. */
    agreementStatus?: string | null;
    /** Gets or sets the name of the software package. */
    softwarePackageName?: string | null;
    /**
     * Gets or sets the live date.
     * @format date-time
     */
    liveDate?: string | null;
    /** Gets or sets the licensees. */
    licensees?: AgreementPartyViewModel[] | null;
    /** Gets or sets the software owners. */
    softwareOwner?: string | null;
    /**
     * Gets or sets the last deposit date.
     * @format date-time
     */
    lastDepositDate?: string | null;
    /** Gets or sets the level of verification. */
    lastDepositLevelOfVerification?: string | null;
    /**
     * Gets or sets the last verification excerise date.
     * @format date-time
     */
    lastVerificationExerciseDate?: string | null;
    /** Gets or sets the version. */
    lastDepositVersion?: string | null;
    /** Gets or sets a value indicating whether last deposit is verified. */
    lastDepositIsVerified?: boolean | null;
    /**
     * Gets or sets a value indicating the number of months since the last deposit.
     * @format int32
     */
    monthsSinceLastDeposit?: number | null;
    /**
     * Gets or sets the month since last verification.
     * @format int32
     */
    monthsSinceLastVerification?: number | null;
    /** Gets or sets a value indicating whether the viewing party can create a deposit. */
    viewingPartyCanCreateDeposit?: boolean;
    /** Gets or sets IsMultiAgreementType. */
    isMultiAgreementType?: boolean;
    /** The name of the uploader associated with this agreement's location. */
    uploaderName?: string | null;
    /** Gets the billing account. */
    billingAccount?: string | null;
    /** Gets the billing account address. */
    billingAccountAddress?: string | null;
    /** Gets or sets the HasBespokeTestingRequirements. */
    hasBespokeTestingRequirements?: boolean;
    /** Gets or sets the HasOptedOutOfVVStorage. */
    hasOptedOutOfVVStorage?: boolean;
}

/**
 * An enumeration of the different types of audit record.
 * @format int32
 */
export enum AuditRecordType {
    Value0 = 0,
    Value1 = 1,
    Value2 = 2,
    Value3 = 3,
    Value4 = 4,
    Value5 = 5,
    Value6 = 6,
    Value7 = 7,
    Value8 = 8,
    Value9 = 9
}

/** Contacts Reponse View Model. */
export interface ClientContactsResponseViewModel {
    /** Gets or sets the contacts. */
    contacts?: ContactViewModel[] | null;
}

/** A generic view model for an audit record. */
export interface ContactAuditRecordViewModel {
    /**
     * Gets or sets the date of the event.
     * @format date-time
     */
    eventTime?: string;
    /** Gets or sets the people involved. */
    user?: string | null;
    /** Gets or sets the description of the event. */
    description?: string | null;
    /** An enumeration of the different types of audit record. */
    recordType?: AuditRecordType;
    /** Gets or sets the changed items. */
    changedFields?: ContactFieldDataChangeAuditViewModel[] | null;
}

/** A category of contact us request. */
export enum ContactCategory {
    SalesEnquiry = 'SalesEnquiry',
    EscrowDeposit = 'EscrowDeposit',
    EscrowAgreement = 'EscrowAgreement',
    Invoicing = 'Invoicing',
    Document = 'Document'
}

/** Contact Details. */
export interface ContactDetails {
    /**
     * Gets or sets the name of the contact.
     * @minLength 0
     * @maxLength 256
     */
    contactName: string;
    /**
     * Gets or sets the telephone number.
     * @minLength 0
     * @maxLength 256
     */
    telephoneNumber: string;
    /**
     * Gets or sets the job title.
     * @minLength 0
     * @maxLength 256
     */
    jobTitle: string;
    /**
     * Gets or sets the email address.
     * @format email
     * @minLength 1
     */
    emailAddress: string;
    /**
     * Gets or sets the name of the company.
     * @minLength 0
     * @maxLength 256
     */
    companyName?: string | null;
    /**
     * Gets or sets the address line1.
     * @minLength 0
     * @maxLength 256
     */
    addressLine1: string;
    /**
     * Gets or sets the address line2.
     * @minLength 0
     * @maxLength 256
     */
    addressLine2?: string | null;
    /**
     * Gets or sets the address line3.
     * @minLength 0
     * @maxLength 256
     */
    addressLine3?: string | null;
    /**
     * Gets or sets the post code.
     * @minLength 1
     */
    postCode: string;
}

/** A class representing the change in data for a particular field. */
export interface ContactFieldDataChangeAuditViewModel {
    /** Gets or sets the name of the field that was changed. */
    fieldName?: string | null;
    /** Gets or sets the new value of the field. */
    newValue?: string | null;
    /** Gets or sets the old value of the field. */
    oldValue?: string | null;
}

/** Get Contact Response. */
export interface ContactResponseViewModel {
    /** Contact View Model. */
    contact?: ContactViewModel;
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** A contact us request. */
export interface ContactUsRequest {
    /**
     * Gets or sets the name of the contact.
     * @minLength 1
     */
    contactName: string;
    /**
     * Gets or sets the contact email.
     * @format email
     * @minLength 1
     */
    contactEmail: string;
    /**
     * Gets or sets the contact telephone.
     * @minLength 1
     */
    contactTelephone: string;
    /** A category of contact us request. */
    contactCategory: ContactCategory;
    /** A sub-category of "contact us" document enquiry. */
    documentCategory?: DocumentEnquiryCategory;
    /**
     * Gets or sets the contact request.
     * @minLength 1
     */
    contactRequest: string;
}

/** Contact View Model. */
export interface ContactViewModel {
    /**
     * Gets or sets the contact identifier.
     * @format int32
     */
    id?: number;
    /** Gets or sets the title. */
    title?: string | null;
    /** Gets or sets the first name. */
    firstName?: string | null;
    /** Gets or sets the last name. */
    lastName?: string | null;
    /** Gets or sets the job title. */
    jobTitle?: string | null;
    /** Gets or sets the first line of the address. */
    addressLine1?: string | null;
    /** Gets or sets the second line of the address. */
    addressLine2?: string | null;
    /** Gets or sets the third line of the address. */
    addressLine3?: string | null;
    /** Gets or sets the post code. */
    postcode?: string | null;
    /** Gets or sets the telephone. */
    telephone?: string | null;
    /** Gets or sets the email. */
    email?: string | null;
    /**
     * Gets or sets the user identifier.
     * @format uuid
     */
    userId?: string | null;
    /** Gets or sets the name of the user. */
    userName?: string | null;
    /** Gets or sets if user can be enabled. */
    userIsEnabled?: boolean;
    /** Gets or sets the InviteEmailSent. */
    inviteEmailSent?: boolean | null;
    /**
     * Gets or sets the LastInviteEmailSentOn.
     * @format date-time
     */
    lastInviteEmailSentOn?: string | null;
}

/** The StartSftpDepositResponse. */
export interface CreateDepositViewModelResponse {
    /**
     * Gets or sets the deposit identifier.
     * @format int32
     */
    depositId?: number;
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** Create a new download schedule attached to a deposit. */
export interface CreateDownloadScheduleRequest {
    /**
     * Gets or sets the deposit identifier.
     * @format int32
     */
    depositId?: number;
    /** How often a task needs to be repeated. */
    frequencyType?: FrequencyType;
    /** Gets or sets the items. */
    items: DownloadScheduleRepo[];
    /**
     * Gets or sets the next download time.
     * @format date-time
     */
    nextDownloadAt: string;
}

/** Create a new source code provider response. */
export interface CreateProviderViewModelResponse {
    /**
     * Gets or sets the provider identifier that was created.
     * @format int32
     */
    providerId?: number;
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** A view model for creating a new source code provider. */
export interface CreateSourceCodeProviderRequest {
    /** Gets or sets the name of the connection. */
    connectionName?: string | null;
    /** Gets or sets the password. */
    password?: string | null;
    /** Gets or sets the private key. */
    privateKey?: string | null;
    /** The different source code providers. */
    providerType?: SourceCodeProviderType;
    /** The different source code Authentication Type. */
    authenticationType?: SourceCodeAuthenticationType;
    /** Gets or sets the repository address. */
    repositoryAddress?: string | null;
    /** Gets or sets the name of the SCC user. */
    userName?: string | null;
}

/** DataCentre. */
export interface DataCentreItem {
    /** Gets or sets the Status. */
    status?: string | null;
    /** Gets or sets the Platform. */
    platform?: string | null;
    /** Gets or sets the Region. */
    region?: string | null;
    /** Gets or sets the Method. */
    method?: string | null;
    /**
     * Gets or sets the PriorityOrder.
     * @format int32
     */
    priorityOrder?: number;
    /** Gets or sets the Location. */
    location?: string | null;
}

/** DataCentreResponse. */
export interface DataCentreResponse {
    /** The Agreement DTO. */
    agreement?: Agreement;
    /** Gets or sets the Items. */
    items?: DataCentreItem[] | null;
    /** Returns true if no validation errors were returned. */
    isValid?: boolean;
    /** Gets or sets the validation errors. */
    validationErrors?: Record<string, string>;
}

/** A client declared deposit file types item. */
export interface DeclaredDepositFileTypes {
    /** Gets or sets the file types. */
    filetypes?: number[] | null;
    /** Gets or sets the custom other file types. */
    other?: string | null;
}

/**
 * View model response from a call to
 * M:ncc.escrow.view.libraries.clientportalfacade.ISourceCodeDownloadFacade.DeleteDownloadSchedule(System.Guid,System.Int32).
 */
export interface DeleteDownloadScheduleResponse {
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** Response from a delete provider request. */
export interface DeleteProviderViewModelResponse {
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** View model for deleting an ssh key. */
export interface DeleteSshKeyRequestViewModel {
    /**
     * Gets or sets the key.
     * @format int32
     */
    keyId: number;
}

/**
 * Delivery Method.
 * @format int32
 */
export enum DeliveryMethod {
    Value0 = 0,
    Value1 = 1
}

/** Deposit Content. */
export interface DepositContent {
    /** Gets or sets a value indicating whether the deposit is compressed. */
    isTheDepositCompressed: boolean;
    /** Gets or sets the compression method. */
    compressionMethod?: string | null;
    /** Gets or sets a value indicating whether the deposit is subject to any export controls. */
    isTheDepositSubjectToAnyExportControls: boolean;
    /** Gets or sets the export controls details. */
    exportControlsDetails?: string | null;
    /** Gets or sets a value indicating whether the deposit contains any personal data. */
    doesTheDepositContainAnyPersonalData: boolean;
    /** Gets or sets the personal data details. */
    personalDataDetails?: string | null;
    /** Gets or sets this deposit is encrypted. */
    isTheDepositEncrypted: boolean;
    /** Gets or sets the encrypt method. */
    encryptMethod?: string | null;
    /** Gets or sets the deposit encrypted confirmation. */
    depositEncryptedConfirmation?: boolean | null;
    /** Gets or sets the is a password required. */
    isAPasswordRequired: boolean;
    /** Gets or sets the password. */
    password?: string | null;
    /** Gets or sets the is deposit replacement of previous deposit. */
    isDepositReplacementOfPreviousDeposit?: boolean | null;
}

/** Deposit Contents. */
export interface DepositContents {
    /** Gets or sets a value indicating whether [source code]. */
    sourceCode?: boolean;
    /** Gets or sets a value indicating whether this ncc.escrow.view.api.clientportal.Model.V1.Deposit.DepositContents is development. */
    development?: boolean;
    /** Gets or sets a value indicating whether [environment or build tools]. */
    environmentOrBuildTools?: boolean;
    /** Gets or sets a value indicating whether this ncc.escrow.view.api.clientportal.Model.V1.Deposit.DepositContents is database. */
    database?: boolean;
    /** Gets or sets a value indicating whether [test environment]. */
    testEnvironment?: boolean;
    /** Gets or sets a value indicating whether [other data]. */
    otherData?: boolean;
    /** Gets or sets a value indicating whether [configuration files]. */
    configurationFiles?: boolean;
}

/** Deposit Datacentre info. */
export interface DepositDataCentre {
    /** Gets or sets whether the deposit is on NCC hardware. */
    isOnPrem?: boolean;
    /** Gets or sets the cloud platform used, eg Azure, AWS etc. */
    platform?: string | null;
    /** Gets or sets the cloud region used, eg UK South. */
    region?: string | null;
}

/** A deposit file item. */
export interface DepositFileItem {
    /**
     * Gets or sets the file identifier.
     * @format int32
     */
    fileId?: number;
    /**
     * Gets or sets the DepositUniqueReference.
     * @format uuid
     */
    depositUniqueReference?: string | null;
    /**
     * Gets or sets the file UniqueReference.
     * @format uuid
     */
    uniqueReference?: string;
    /** Gets or sets the name of the file. */
    fileName?: string | null;
    /** Gets or sets a value indicating whether the upload is completed. */
    completed?: boolean;
    /** File verification status. */
    verificationState?: VerificationState;
    /**
     * Gets or sets the size of the file.
     * @format int64
     */
    fileSize?: number;
    /** Gets or sets UploaderUrl. */
    uploaderUrl?: string | null;
}

/** A Deposit File Type Item. */
export interface DepositFileTypeItem {
    /**
     * File category ID.
     * @format int32
     */
    id?: number;
    /** Gets or sets the file category description. */
    description?: string | null;
    /** Gets or sets the file category extensions. */
    fileExtensions?: string[] | null;
}

/** A Deposit History Details Agreement. */
export interface DepositHistoryDetailsAgreement {
    /**
     * Gets or sets the agreement number.
     * @format int32
     */
    agreementNumber?: number;
    /** Gets or sets the agreement number text. This allows duplicates to be defined. */
    agreementNumberText?: string | null;
    /** Gets or sets whether the user is an owner of the agreement. */
    isOwner?: boolean;
    /** Gets or sets whether the user is a member of a privileged party on the agreement. */
    isPrivilegedParty?: boolean;
    /** Gets or sets the deposit history details. */
    details?: DepositHistoryDetailsItem[] | null;
}

/** A Deposit History Details Item. */
export interface DepositHistoryDetailsItem {
    /**
     * Gets or sets the deposit date.
     * @format date-time
     */
    depositDate?: string;
    /** Gets or sets the software package name. */
    softwarePackageName?: string | null;
    /** Gets or sets the software version. */
    version?: string | null;
    /** Gets or sets the deposit status. */
    status?: string | null;
    /** Gets or sets the deposit owner. */
    depositOwner?: string | null;
    /** Gets or sets the testing level of the deposit. */
    testingLevel?: string | null;
}

/** A Deposit History Summary Item. */
export interface DepositHistorySummaryItem {
    /**
     * Gets or sets the agreement number.
     * @format int32
     */
    agreementNumber?: number;
    /** Gets or sets the agreement number text. This allows duplicates to be defined. */
    agreementNumberText?: string | null;
    /** Gets or sets the agreement owner. */
    agreementOwner?: string | null;
    /** Gets or sets the agreement licencees. */
    agreementLicencees?: string[] | null;
    /** Gets or sets the software name. */
    softwareName?: string | null;
    /**
     * Gets or sets the number of deposits.
     * @format int32
     */
    numberOfDeposits?: number;
    /**
     * Gets or sets the number of verified deposits.
     * @format int32
     */
    numberOfVerifiedDeposits?: number;
    /**
     * Gets or sets the time of the last deposit.
     * @format date-time
     */
    lastDepositReceived?: string | null;
    /**
     * Gets or sets the time of the last verified deposit.
     * @format date-time
     */
    lastVerifiedDepositReceived?: string | null;
    /** Gets or sets the testing level of the latest deposit. */
    testingLevelOfLatestDeposit?: string | null;
}

/** A Deposit Item. */
export interface DepositItem {
    /**
     * Gets or sets the agreement identifier.
     * @format int32
     */
    agreementId?: number;
    /**
     * Gets or sets the deposit identifier.
     * @format int32
     */
    depositId?: number;
    /** Gets or sets the name of the contact. */
    contactName?: string | null;
    /** Gets or sets the telephone number. */
    telephoneNumber?: string | null;
    /** Gets or sets the job title. */
    jobTitle?: string | null;
    /** Gets or sets the email address. */
    emailAddress?: string | null;
    /** Gets or sets the name of the company. */
    companyName?: string | null;
    /** Gets or sets the address line1. */
    addressLine1?: string | null;
    /** Gets or sets the address line2. */
    addressLine2?: string | null;
    /** Gets or sets the address line3. */
    addressLine3?: string | null;
    /** Gets or sets the post code. */
    postCode?: string | null;
    /** Deposit State. */
    lastSavedState?: DepositState;
    /** Gets or sets the name of the software package. */
    softwarePackageName?: string | null;
    /** Gets or sets the name of the software owner company. */
    softwareOwnerCompanyName?: string | null;
    /** Gets or sets the version or release number. */
    versionOrReleaseNumber?: string | null;
    /**
     * Gets the deposit date.
     * @format date-time
     */
    depositDate?: string | null;
    /** The deposit status. */
    escrowDepositStatus?: EscrowDepositStatus;
    /** Gets or sets a value indicating whether this instance is read only. */
    isReadOnly?: boolean;
    /** Gets or sets if it is a source control deposit. */
    isSourceControl?: boolean;
    /** Gets or sets if it is a sftp deposit. */
    isSftp?: boolean;
    /** Gets or sets if it is a scheduled deposit. */
    isScheduledDeposit?: boolean;
    /** Gets or sets if there is a media check report available for this deposit. */
    mediaCheck?: boolean;
    /**
     * Gets or sets the deposit creator's userid.
     * @format uuid
     */
    createdByUserId?: string;
    /** Gets or sets DepositOwner. */
    depositOwner?: string | null;
    /**
     * Testing level types.
     * These evaluate to NOT Verified unless marked (*).
     */
    testingLevel?: TestingLevel;
    /** Gets or sets TestingLevelDescription. */
    testingLevelDescription?: string | null;
}

/**
 * Deposit Licensee Selection.
 * @format int32
 */
export enum DepositLicenseeSelection {
    Value0 = 0,
    Value1 = 1,
    Value2 = 2,
    Value3 = 3
}

/** A deposit report filter. */
export interface DepositReportFilter {
    /**
     * Gets or sets the start date for report data.
     * @format date-time
     */
    startDate?: string | null;
    /**
     * Gets or sets the end date for report data.
     * @format date-time
     */
    endDate?: string | null;
    /** The escrow ReportFormat. */
    reportFormat?: ReportFormat;
}

/**
 * The sort column for client portal deposit list.
 * @format int32
 */
export enum DepositSortColumn {
    Value0 = 0,
    Value1 = 1,
    Value2 = 2,
    Value3 = 3,
    Value4 = 4,
    Value5 = 5,
    Value6 = 6,
    Value7 = 7
}

/** Deposit source control details item. */
export interface DepositSourceControlDetailsItemResponse {
    /**
     * Gets or sets the provider identifier.
     * @format int32
     */
    providerId?: number;
    /** Gets or sets the branch name. */
    branchName?: string | null;
}

/** Deposit Specs. */
export interface DepositSpecs {
    /** Gets or sets the operating system used. */
    operatingSystemUsed?: string | null;
    /** Gets or sets the hardware used to create the deposit. */
    hardwareUsedToCreateTheDeposit?: string | null;
    /** Gets or sets the source code development language. */
    sourceCodeDevelopmentLanguage?: string | null;
    /**
     * Gets or sets the approximate size of media content.
     * @format double
     */
    approximateSizeOfMediaContent: number;
}

/**
 * Deposit State.
 * @format int32
 */
export enum DepositState {
    Value0 = 0,
    Value1 = 1,
    Value2 = 2,
    Value3 = 3,
    Value4 = 4,
    Value5 = 5,
    Value6 = 6,
    Value7 = 7,
    Value8 = 8
}

/** Deposit Type. */
export interface DepositType {
    /** Gets or sets a value indicating whether this instance is the deposit for a scheduled verification exercise. */
    isTheDepositForAScheduledVerificationExercise: boolean;
    /** Gets or sets a value indicating whether [does this deposit contain a virtual machine]. */
    doesThisDepositContainAVirtualMachine: boolean;
    /** Gets or sets a value indicating whether this instance is this deposit for a media file review. */
    isThisDepositForAMediaFileReview: boolean;
    /** Deposit Licensee Selection. */
    isThisDepositForASpecificLicensee: DepositLicenseeSelection;
    /** Gets or sets the licensees that require this deposit. */
    licenseesThatRequireThisDeposit?: number[] | null;
    /** Deposit Contents. */
    contentsOfThisDeposit: DepositContents;
}

/**
 * The deposit type to filter.
 * @format int32
 */
export enum DepositTypesFilter {
    Value0 = 0,
    Value1 = 1,
    Value2 = 2,
    Value3 = 3
}

/** The view model response for disabling a user. */
export interface DisableUserResponseViewModel {
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** A sub-category of "contact us" document enquiry. */
export enum DocumentEnquiryCategory {
    Contract = 'Contract',
    Deposit = 'Deposit',
    TestReport = 'TestReport',
    Other = 'Other'
}

/** DocumentMetadataResponseViewModel. */
export interface DocumentMetadataResponseViewModel {
    /** Response status for documents from documents service. */
    responseStatus?: ResponseStatus;
    /** Get or sets generic document view model reponse item. */
    items?: DocumentMetadataResponseViewModelItem[] | null;
}

/** Create a deposit. */
export interface DocumentMetadataResponseViewModelItem {
    /** Index of file. */
    index?: string | null;
    /** Uri of file. */
    uri?: string | null;
    /** Media type of file e.g application/pdf or application/msword. */
    mediaType?: string | null;
    /** Slx document type. */
    slxType?: string | null;
}

/** The download configurations for a client. */
export interface DownloadScheduleItem {
    /**
     * Gets or sets the SourceCodeDeposit identifier.
     * @format int32
     */
    id?: number;
    /** Gets or sets the agreement number(s). */
    agreementNumber?: string | null;
    /** Gets or sets the name of the software owner company. */
    softwareOwnerCompanyName?: string | null;
    /** Gets or sets the name of the software package. */
    softwarePackageName?: string | null;
    /** Gets or sets the version or release number. */
    versionOrReleaseNumber?: string | null;
    /** How often a task needs to be repeated. */
    frequencyType?: FrequencyType;
    /** Gets or sets the scheduled download items. */
    items?: GetDownloadSchedulesItem[] | null;
    /**
     * Gets or sets when the next download should start.
     * @format date-time
     */
    nextDownloadAt?: string;
    /** Gets or sets CanEdit. */
    canEdit?: boolean;
    /** Gets or sets CanDelete. */
    canDelete?: boolean;
}

/** Details of a download schedule. */
export interface DownloadScheduleRepo {
    /**
     * Gets or sets the identifier.
     * @format int32
     */
    id?: number;
    /** Gets or sets the name of the branch. */
    branchName?: string | null;
    /**
     * Gets or sets the provider identifier.
     * @format int32
     */
    providerId?: number;
}

/** Add Deposit Details Response. */
export interface EditDepositResponse {
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** Edit an existing download schedule attached to a deposit. */
export interface EditDownloadScheduleViewModelRequest {
    /**
     * Gets or sets the download identifier.
     * @format int32
     */
    downloadId?: number;
    /** How often a task needs to be repeated. */
    frequencyType?: FrequencyType;
    /**
     * Gets or sets the items.
     * @minItems 1
     */
    items: DownloadScheduleRepo[];
    /**
     * Gets or sets the next download time.
     * @format date-time
     */
    nextDownloadAt: string;
}

/** A view model for creating a new source code provider. */
export interface EditSourceCodeProviderViewModelRequest {
    /** Gets or sets the name of the connection. */
    connectionName?: string | null;
    /** Gets or sets the password. */
    password?: string | null;
    /** Gets or sets the private key. */
    privateKey?: string | null;
    /**
     * Gets or sets the provider identifier.
     * @format int32
     */
    providerId?: number;
    /** The different source code providers. */
    providerType?: SourceCodeProviderType;
    /** The different source code Authentication Type. */
    authenticationType?: SourceCodeAuthenticationType;
    /** Gets or sets the repository address. */
    repositoryAddress?: string | null;
    /** Gets or sets the name of the SCC user. */
    userName?: string | null;
}

/** The view model response when editing a source code provider. */
export interface EditSourceCodeProviderViewModelResponse {
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/**
 * The deposit status.
 * @format int32
 */
export enum EscrowDepositStatus {
    Value0 = 0,
    Value1 = 1,
    Value2 = 2,
    Value4 = 4,
    Value5 = 5,
    Value6 = 6,
    Value7 = 7,
    Value8 = 8,
    Value9 = 9,
    Value10 = 10,
    Value11 = 11,
    Value12 = 12,
    Value13 = 13,
    Value14 = 14,
    Value15 = 15,
    Value16 = 16,
    Value17 = 17,
    Value18 = 18,
    Value19 = 19,
    Value20 = 20,
    Value21 = 21,
    Value22 = 22,
    Value23 = 23,
    Value24 = 24,
    Value25 = 25,
    Value26 = 26,
    Value27 = 27,
    Value28 = 28,
    Value29 = 29,
    Value30 = 30,
    Value31 = 31,
    Value32 = 32,
    Value33 = 33
}

/** A feedback request. */
export interface FeedbackRequest {
    /**
     * Gets or sets the feedback type.
     * 1 - Feedback on depositing.
     * 2 - Feedback on logout.
     * @format int32
     * @min 1
     * @max 2
     */
    feedbackType: number;
    /**
     * Gets or sets the feedback rating.
     * Range of 1 (worst) - 5 (best).
     * @format int32
     * @min 1
     * @max 5
     */
    rating: number;
    /**
     * Gets or sets the feedback category.
     * 1 - Suggestion.
     * 2 - Issue.
     * 3 - Compliment.
     * @format int32
     * @min 1
     * @max 3
     */
    category: number;
    /**
     * Gets or sets the feedback text.
     * @minLength 1
     */
    feedback: string;
    /**
     * Gets or sets the customer email address.
     * @format email
     */
    email?: string | null;
}

/**
 * How often a task needs to be repeated.
 * @format int32
 */
export enum FrequencyType {
    Value0 = 0,
    Value1 = 1,
    Value2 = 2,
    Value3 = 3,
    Value4 = 4,
    Value5 = 5
}

/** See ncc.escrow.view.api.clientportal.Model.V1.Auditing.GetAgreementPartyContactChangesResponseViewModel. */
export interface GetAgreementPartyContactChange {
    /** Gets or sets the fields. */
    fields?: GetAgreementPartyContactField[] | null;
    /** Gets or sets the name of who made the changes. */
    changedByName?: string | null;
    /**
     * Gets or sets when the record was changed.
     * @format date-time
     */
    changedAt?: string;
}

/** View model response for GetAgreementPartyContactChanges API. */
export interface GetAgreementPartyContactChangesResponseViewModel {
    /** Gets or sets the changes. */
    changes?: GetAgreementPartyContactChange[] | null;
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** See ncc.escrow.view.api.clientportal.Model.V1.Auditing.GetAgreementPartyContactChangesResponseViewModel. */
export interface GetAgreementPartyContactField {
    /** Gets or sets the name. */
    name?: string | null;
    /** Gets or sets the old value. */
    oldValue?: string | null;
    /** Gets or sets the new value. */
    newValue?: string | null;
}

/** Get the view model for the response of a contact audit. */
export interface GetContactAuditResponseViewModel {
    /** Gets or sets the audit records. */
    auditRecords?: ContactAuditRecordViewModel[] | null;
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** Get Declared Deposit File Types Response. */
export interface GetDeclaredDepositFileTypesResponse {
    /** Gets or sets the types of files in the deposit. */
    filetypes?: number[] | null;
    /** Gets or sets the custom file types in the deposit. */
    other?: string | null;
    /** Returns true if no validation errors were returned. */
    isValid?: boolean;
    /** Gets or sets the validation errors. */
    validationErrors?: Record<string, string>;
}

/** Get deposit file types response. */
export interface GetDepositFileTypesResponse {
    /** List of deposit file types. */
    depositFileTypes?: DepositFileTypeItem[] | null;
    /** Returns true if no validation errors were returned. */
    isValid?: boolean;
    /** Gets or sets the validation errors. */
    validationErrors?: Record<string, string>;
}

/** A Get Deposit Files Response. */
export interface GetDepositFilesResponse {
    /** SpectreDepositResponse. */
    spectreDepositResponse?: SpectreDepositResponse;
    /** Gets or sets the deposits. */
    depositFiles?: DepositFileItem[] | null;
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** Get deposit history response. */
export interface GetDepositHistoryResponse {
    /** Gets or sets the client name. */
    clientName?: string | null;
    /**
     * Gets or sets the report time.
     * @format date-time
     */
    reportTime?: string;
    /**
     * Gets or sets the start time for deposit details.
     * @format date-time
     */
    depositDetailsStartTime?: string | null;
    /**
     * Gets or sets the end time for deposit details.
     * @format date-time
     */
    depositDetailsEndTime?: string | null;
    /** Gets the deposit history summary. */
    summary?: DepositHistorySummaryItem[] | null;
    /** Gets the deposit history details. */
    agreementDeposits?: DepositHistoryDetailsAgreement[] | null;
    /** Returns true if no validation errors were returned. */
    isValid?: boolean;
    /** Gets or sets the validation errors. */
    validationErrors?: Record<string, string>;
}

/** Get Deposit for Edit Response. */
export interface GetDepositResponse {
    /** Contact Details. */
    contactDetails?: ContactDetails;
    /** Software Details. */
    softwareDetails?: SoftwareDetails;
    /** Deposit Type. */
    depositType?: DepositType;
    /** Deposit Specs. */
    depositSpecs?: DepositSpecs;
    /** Deposit Content. */
    depositContent?: DepositContent;
    /** Gets or sets the licensees that require this deposit. */
    licenseesThatCanBeSelectedForThisDeposit?: Record<string, string>;
    /** Deposit State. */
    lastSavedState?: DepositState;
    /** Gets or sets a value indicating whether this instance is read only. */
    isReadOnly?: boolean;
    /** Gets the oversized deposit fees warning enabled. */
    oversizedDepositFeesWarningEnabled?: boolean;
    /**
     * Gets or sets the maximum size for upload files in mb.
     * @format int32
     */
    maxSizeForUploadFilesInMB?: number;
    /** The deposit status. */
    depositStatus?: EscrowDepositStatus;
    /** Gets or sets a value indicating whether this instance is scheduled deposit. */
    isScheduledDeposit?: boolean;
    /** Gets or sets the data centre where files will be stored. */
    dataCentre?: string | null;
    /** Gets or sets the value if deposit documents need to be displayed. */
    displayDepositDocuments?: boolean;
    /** Gets or sets the related agreements for a deposit. */
    relatedAgreements?: number[] | null;
    /** Gets or sets if there is a media check report available for this deposit. */
    mediaCheck?: boolean;
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** Deposit source control details item. */
export interface GetDepositSourceControlDetails {
    /** Gets or sets the provider identifier. */
    items?: DepositSourceControlDetailsItemResponse[] | null;
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** Pre-Populated CreateDeposit Response. */
export interface GetDepositTemplateResponse {
    /** Contact Details. */
    contactDetails?: ContactDetails;
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** A Get Deposits Response. */
export interface GetDepositsResponse {
    /** Gets or sets the AgreementPartyType. */
    agreementPartyType?: string | null;
    /**
     * Gets or sets the TotalRecords.
     * @format int32
     */
    totalRecords?: number;
    /** Gets or sets the deposits. */
    deposits?: DepositItem[] | null;
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** Scheduled source code download schedule details. */
export interface GetDownloadSchedulesItem {
    /** Gets or sets the name of the branch to download. */
    branchName?: string | null;
    /** Gets or sets the name of the connection. */
    connectionName?: string | null;
    /**
     * Gets or sets the entity identifier of the download schedule.
     * @format int32
     */
    providerId?: number;
    /** The different source code providers. */
    providerType?: SourceCodeProviderType;
    /** Gets or sets the repository address. */
    repositoryAddress?: string | null;
}

/** The download configurations for a client. */
export interface GetDownloadSchedulesResponse {
    /** Gets or sets the schedules. */
    schedules?: DownloadScheduleItem[] | null;
    /** Returns true if no validation errors were returned. */
    isValid?: boolean;
    /** Gets or sets the validation errors. */
    validationErrors?: Record<string, string>;
}

/** A Get Incomplete Deposits Response. */
export interface GetIncompleteDepositsResponse {
    /** Gets or sets the incomplete deposits. */
    deposits?: IncompleteDepositItem[] | null;
    /** Returns true if no validation errors were returned. */
    isValid?: boolean;
    /** Gets or sets the validation errors. */
    validationErrors?: Record<string, string>;
}

/** Get scheduled deposit statistics response. */
export interface GetScheduledDepositStatsResponse {
    /**
     * Number of annual scheduled deposits.
     * @format int32
     */
    annual?: number;
    /**
     * Number of quarterly scheduled deposits.
     * @format int32
     */
    quarterly?: number;
    /**
     * Number of monthly scheduled deposits.
     * @format int32
     */
    monthly?: number;
    /**
     * Number of weekly scheduled deposits.
     * @format int32
     */
    weekly?: number;
    /**
     * Number of daily scheduled deposits.
     * @format int32
     */
    daily?: number;
    /** Returns true if no validation errors were returned. */
    isValid?: boolean;
    /** Gets or sets the validation errors. */
    validationErrors?: Record<string, string>;
}

/** The view model response when getting a source code provider. */
export interface GetSourceCodeProviderViewModelResponse {
    /** Gets or sets the name of the connection. */
    connectionName?: string | null;
    /**
     * Gets or sets the provider identifier.
     * @format int32
     */
    providerId?: number;
    /** The different source code providers. */
    providerType?: SourceCodeProviderType;
    /** The different source code Authentication Type. */
    authenticationType?: SourceCodeAuthenticationType;
    /** Gets or sets the repository address. */
    repositoryAddress?: string | null;
    /** Gets or sets the name of the SCC user. */
    userName?: string | null;
    /**
     * Gets the number of associated schedules.
     * @format int32
     */
    schedules?: number;
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** Part of a ncc.escrow.view.api.clientportal.Model.V1.Deposit.GetSourceCodeProvidersResponse view model. */
export interface GetSourceCodeProvidersItem {
    /** Gets or sets the name of the connection. */
    connectionName?: string | null;
    /**
     * Gets or sets the entity identifier.
     * @format int32
     */
    id?: number;
    /** The different source code providers. */
    providerType?: SourceCodeProviderType;
    /** The different source code Authentication Type. */
    authenticationType?: SourceCodeAuthenticationType;
    /** Gets or sets the repository address. */
    repositoryAddress?: string | null;
    /** Gets or sets the name of the user. */
    userName?: string | null;
    /**
     * Gets the number of associated schedules.
     * @format int32
     */
    schedules?: number;
}

/** Response view model from M:ncc.escrow.view.api.clientportal.V1.Controllers.DepositController.GetSourceCodeProviders. */
export interface GetSourceCodeProvidersResponse {
    /** Gets or sets the providers. */
    providers?: GetSourceCodeProvidersItem[] | null;
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** Get ssh keys item. */
export interface GetSshKeysResponseItem {
    /**
     * Gets or sets the identifier.
     * @format int32
     */
    id?: number;
    /** Gets or sets the name of the key. */
    keyName?: string | null;
}

/** Get user ssh keys. */
export interface GetSshKeysResponseViewModel {
    /** Gets or sets the items. */
    items?: GetSshKeysResponseItem[] | null;
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** The response for GetSystemConfigurationList. */
export interface GetSystemConfigurationListResponse {
    /** Gets or sets the system configuration items. */
    systemConfigurations?: Record<string, string | null>;
    /** Returns true if no validation errors were returned. */
    isValid?: boolean;
    /** Gets or sets the validation errors. */
    validationErrors?: Record<string, string>;
}

/** The Response. */
export interface GetTooltipsResponseViewModel {
    /** Gets or sets the tooltip items. */
    tooltipItems?: TooltipItem[] | null;
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** The response for getting escrow application messages. */
export interface GetUiTextResponseViewModel {
    /** Gets or sets the messages. */
    messages?: Record<string, string | null>;
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** An Incomplete Deposit Item. */
export interface IncompleteDepositItem {
    /**
     * Gets or sets the agreement identifier.
     * @format int32
     */
    agreementId?: number;
    /**
     * Gets or sets the agreement number.
     * @format int32
     */
    agreementNumber?: number;
    /**
     * Gets or sets the deposit identifier.
     * @format int32
     */
    depositId?: number;
    /** Gets or sets the name of the software package. */
    softwarePackageName?: string | null;
    /** Gets or sets the name of the software version or release number. */
    versionOrReleaseNumber?: string | null;
    /** Deposit State. */
    lastSavedState?: DepositState;
    /**
     * Gets or sets the deposit creation time.
     * @format date-time
     */
    created?: string;
    /**
     * Gets or sets the deposit creator's userid.
     * @format uuid
     */
    createdByUserId?: string;
}

/** The response for inviting a contact. */
export interface InviteContactResponse {
    /** Returns true if no validation errors were returned. */
    isValid?: boolean;
    /** Gets or sets the validation errors. */
    validationErrors?: Record<string, string>;
}

/** Details a customer's NCC contact. */
export interface NCCContact {
    /** Gets or sets the contact's name. */
    name?: string | null;
    /** Gets or sets the contact's division. */
    division?: string | null;
    /** Gets or sets the contact's email address. */
    email?: string | null;
}

/** Details all of a customer's NCC contacts for a role. */
export interface NCCContactRole {
    /** Gets or sets the role name. */
    role?: string | null;
    /** Gets the contacts. */
    contacts?: NCCContact[] | null;
}

/** Notification Item. */
export interface NotificationItem {
    /**
     * Gets or sets the identifier.
     * @format int32
     */
    id?: number;
    /** Gets or sets the subject. */
    subject?: string | null;
    /** Gets or sets the body. */
    body?: string | null;
    /**
     * Gets or sets the created.
     * @format date-time
     */
    created?: string;
    /**
     * Gets or sets the read on.
     * @format date-time
     */
    readOn?: string | null;
    /**
     * Gets or sets the expiry date.
     * @format date-time
     */
    expiry?: string | null;
}

/** Notifications Response View Model. */
export interface NotificationsResponseViewModel {
    /** Gets or sets the notifications. */
    notifications?: NotificationItem[] | null;
    /**
     * Gets the total unread.
     * @format int32
     */
    totalUnread?: number;
}

/** Party Dto. */
export interface Party {
    /**
     * Gets or sets the identifier.
     * @format int32
     */
    id?: number;
    /** Gets or sets the name of the registered. */
    registeredName?: string | null;
    /** Gets or sets the type of the party. */
    partyType?: string | null;
    /** Gets or sets the party status. */
    partyStatus?: string | null;
    /**
     * Gets or sets the party status date.
     * @format date-time
     */
    partyStatusDate?: string;
    /** Gets or sets a value indicating whether this ncc.escrow.view.libraries.dtomodels.V1.Agreements.Party is depositor. */
    depositor?: boolean;
    /** Gets or sets the billing account. */
    billingAccount?: string | null;
    /** Gets or sets the party contacts. */
    partyContacts?: PartyContact[] | null;
    /** Gets or sets the billing account address. */
    billingAccountAddress?: string | null;
    /** Gets or set the if this party can edit contacts. */
    canEditContacts?: boolean;
}

/** A Party contact. */
export interface PartyContact {
    /**
     * Gets or sets party contact id.
     * @format int32
     */
    id?: number;
    /**
     * Gets or sets the contact identifier.
     * @format int32
     */
    contactId?: number;
    /**
     * Gets or sets the type.
     * <remarks>
     * This can be main, code and finance
     * </remarks>
     */
    roles?: string[] | null;
    /** Gets or sets the name. */
    name?: string | null;
}

/** View model for client registration. */
export interface RegistrationDetails {
    /**
     * Gets or sets the agreement number.
     * @format int32
     * @min 1
     * @max 2147483647
     */
    agreementNumber: number;
    /**
     * Gets or sets the company.
     * @minLength 1
     */
    company: string;
    /**
     * Gets or sets the email address.
     * @format email
     * @minLength 1
     */
    emailAddress: string;
    /**
     * Gets or sets the first name.
     * @minLength 1
     */
    firstName: string;
    /**
     * Gets or sets the last name.
     * @minLength 1
     */
    lastName: string;
    /**
     * Gets or sets the telephone.
     * @minLength 1
     */
    telephone: string;
    /**
     * Gets or sets the title.
     * @minLength 1
     */
    title: string;
}

/** The response view model for a client registration. */
export interface RegistrationResponse {
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** Related agreements. */
export interface RelatedDepositAgreements {
    /** Gets or sets the related agreements. */
    relatedAgreements?: number[] | null;
}

/** Release Item. */
export interface ReleaseItem {
    /**
     * Gets or sets the identifier.
     * @format int32
     */
    id?: number;
    /**
     * Gets or sets the agreement identifier.
     * @format int32
     */
    agreementId?: number;
    /**
     * Gets or sets the agreement number.
     * @format int32
     */
    agreementNumber?: number;
    /**
     * Gets or sets the expiry date.
     * @format date-time
     */
    expiry?: string;
    /** Release Status. */
    status?: ReleaseStatus;
    /**
     * Gets or sets the created.
     * @format date-time
     */
    created?: string;
    /** Gets or sets the name of the software. */
    softwareName?: string | null;
    /** Gets or sets the software version. */
    softwareVersion?: string | null;
}

/**
 * Release Status.
 * @format int32
 */
export enum ReleaseStatus {
    Value0 = 0,
    Value1 = 1,
    Value2 = 2,
    Value3 = 3,
    Value4 = 4,
    Value5 = 5
}

/** Releases Response. */
export interface ReleasesResponse {
    /** Gets or sets the releases. */
    releases?: ReleaseItem[] | null;
    /** Returns true if no validation errors were returned. */
    isValid?: boolean;
    /** Gets or sets the validation errors. */
    validationErrors?: Record<string, string>;
}

/**
 * The escrow ReportFormat.
 * @format int32
 */
export enum ReportFormat {
    Value0 = 0,
    Value1 = 1,
    Value2 = 2
}

/** Request Novation Request View Model. */
export interface RequestNovationRequestViewModel {
    /** Gets or sets the agreement ids. */
    agreementIds?: number[] | null;
    /** Gets or sets the type of change. */
    typeOfChange?: string | null;
    /** Gets or sets the comments. */
    comments?: string | null;
    /**
     * The effective Novation Date.
     * @format date-time
     */
    effectiveDate?: string;
    /** Gets or sets the old name of the party company. */
    oldPartyCompanyName?: string | null;
    /** Gets or sets the new name of the party company. */
    newPartyCompanyName?: string | null;
    /** Gets or sets the new party company address. */
    newPartyCompanyAddress?: string | null;
    /** Gets or sets the po number. */
    poNumber?: string | null;
    /** Gets or sets the new party company registration number. */
    newPartyCompanyRegistrationNumber?: string | null;
    /** Gets or sets the new name of the company contact. */
    newPartyCompanyContactName?: string | null;
    /** Gets or sets the new party email address. */
    newPartyEmailAddress?: string | null;
    /** Gets or sets the new party invoice address. */
    newPartyInvoiceAddress?: string | null;
    /** Gets or sets the new party vat number. */
    newPartyVatNumber?: string | null;
    /** Should a custom template should be used instead of the standard NCC template. */
    useCustomTemplate?: boolean;
}

/**
 * Response status for documents from documents service.
 * @format int32
 */
export enum ResponseStatus {
    Value0 = 0,
    Value200 = 200,
    Value404 = 404
}

/** Software Details. */
export interface SoftwareDetails {
    /** Gets or sets the name of the software owner company. */
    softwareOwnerCompanyName?: string | null;
    /** Gets or sets the name of the software package. */
    softwarePackageName?: string | null;
    /**
     * Gets or sets the version or release number.
     * @minLength 1
     */
    versionOrReleaseNumber: string;
    /** Gets or sets the documentation. */
    documentation?: string | null;
}

/**
 * The different source code Authentication Type.
 * @format int32
 */
export enum SourceCodeAuthenticationType {
    Value0 = 0,
    Value1 = 1
}

/**
 * The different source code providers.
 * @format int32
 */
export enum SourceCodeProviderType {
    Value0 = 0,
    Value1 = 1,
    Value2 = 2,
    Value3 = 3
}

/** AuthRequest. */
export interface SpectreAuthResponse {
    /** Gets or sets the Id. */
    id?: string | null;
    /** Gets or sets the Location. */
    location?: string | null;
    /** Gets or sets the Token. */
    token?: string | null;
    /** Gets or sets the Audiences. */
    audiences?: string[] | null;
}

/** SpectreDepositResponse. */
export interface SpectreDepositResponse {
    /** Gets or sets the Id. */
    id?: string | null;
    /** Gets or sets the Location. */
    location?: string | null;
    /** AuthRequest. */
    authToken?: SpectreAuthResponse;
}

/**
 * Testing level types.
 * These evaluate to NOT Verified unless marked (*).
 * @format int32
 */
export enum TestingLevel {
    Value0 = 0,
    Value1 = 1,
    Value2 = 2,
    Value3 = 3,
    Value4 = 4,
    Value5 = 5,
    Value6 = 6,
    Value7 = 7,
    Value8 = 8,
    Value9 = 9,
    Value10 = 10,
    Value128 = 128,
    Value129 = 129,
    Value130 = 130,
    Value131 = 131,
    Value132 = 132,
    Value133 = 133,
    Value134 = 134,
    Value135 = 135,
    Value136 = 136,
    Value137 = 137,
    Value138 = 138,
    Value139 = 139,
    Value140 = 140,
    Value141 = 141,
    Value142 = 142,
    Value143 = 143,
    Value144 = 144
}

/** A tooltip. */
export interface TooltipItem {
    /**
     * The html element this tooltip applies to.
     * @minLength 1
     */
    htmlElement: string;
    /**
     * The tooltip content that appears on the page.
     * @minLength 1
     */
    tooltipContent: string;
}

/** The response view model for a ui culture update. */
export interface UiCultureResponse {
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** Change ui culture view model. */
export interface UiCultureUpdate {
    /**
     * Gets or sets the UI culture.
     * @minLength 1
     */
    uiCulture: string;
}

/** view model for update contact request. */
export interface UpdateContactRequestViewModel {
    /**
     * Gets or sets the title.
     * @minLength 1
     * @maxLength 50
     */
    title: string;
    /**
     * Gets or sets the first name.
     * @minLength 1
     * @maxLength 150
     */
    firstName: string;
    /**
     * Gets or sets the last name.
     * @minLength 1
     * @maxLength 150
     */
    lastName: string;
    /**
     * Gets or sets the job title.
     * @minLength 1
     * @maxLength 150
     */
    jobTitle: string;
    /**
     * Gets or sets the first line of the address.
     * @minLength 1
     * @maxLength 150
     */
    addressLine1: string;
    /**
     * Gets or sets the second line of the address.
     * @minLength 1
     * @maxLength 150
     */
    addressLine2: string;
    /**
     * Gets or sets the third line of the address.
     * @minLength 1
     * @maxLength 150
     */
    addressLine3: string;
    /**
     * Gets or sets the post code.
     * @minLength 1
     * @maxLength 50
     */
    postcode: string;
    /**
     * Gets or sets the telephone number.
     * @minLength 1
     * @maxLength 50
     */
    telephoneNumber: string;
    /**
     * Gets or sets the email.
     * @format email
     * @minLength 1
     * @maxLength 150
     */
    emailAddress: string;
}

/** View model for update contact response. */
export interface UpdateContactResponseViewModel {
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** The view model for updating the role on an agreement party contact. */
export interface UpdateRolesOnAgreementPartyContactRequestViewModel {
    /** Gets or sets the role. */
    role?: string | null;
    /**
     * Gets or sets the contact identifier.
     * @format int32
     */
    contactId?: number;
}

/** Update User Details Request ViewModel. */
export interface UpdateUserDetailsRequestViewModel {
    /**
     * Gets or sets the identifier.
     * @format uuid
     */
    id: string;
    /**
     * Gets or sets the email address.
     * @minLength 1
     * @maxLength 150
     */
    emailAddress: string;
    /** Gets or sets the ui culture. */
    uiCulture?: string | null;
    /** Gets or sets the name of the user. */
    userName?: string | null;
    /** Gets or sets the full name of the account manager. */
    accountManagerFullName?: string | null;
    /**
     * Gets or sets the title.
     * @minLength 1
     * @maxLength 50
     */
    title: string;
    /**
     * Gets or sets the first name.
     * @minLength 1
     * @maxLength 150
     */
    firstName: string;
    /**
     * Gets or sets the last name.
     * @minLength 1
     * @maxLength 150
     */
    lastName: string;
    /**
     * Gets or sets the job title.
     * @minLength 1
     * @maxLength 150
     */
    jobTitle: string;
    /**
     * Gets or sets the first line of the address.
     * @minLength 1
     * @maxLength 150
     */
    addressLine1: string;
    /**
     * Gets or sets the second line of the address.
     * @minLength 1
     * @maxLength 150
     */
    addressLine2: string;
    /**
     * Gets or sets the third line of the address.
     * @minLength 1
     * @maxLength 150
     */
    addressLine3: string;
    /**
     * Gets or sets the post code.
     * @minLength 1
     * @maxLength 50
     */
    postcode: string;
    /**
     * Gets or sets the telephone number.
     * @minLength 1
     * @maxLength 50
     */
    telephoneNumber: string;
}

/** A file upload complete request. */
export interface UploadCompleteRequest {
    /**
     * The file identifier.
     * @format uuid
     */
    uniqueReference?: string;
    /** File verification status. */
    verificationState?: VerificationState;
    /** The file hash. */
    fileHash?: string | null;
}

/** An upload failed request. */
export interface UploadFailedRequest {
    /** Gets or sets the error message reported by the front-end. */
    errorMessage?: string | null;
    /**
     * Gets or sets the deposit's unique identifier.
     * Not strongly typed becauseit could be invalid/corrupt.
     */
    depositUuid?: string | null;
    /**
     * Gets or sets the uri to which the requset failed.
     * Not strongly typed becauseit could be invalid/corrupt.
     */
    requestUri?: string | null;
}

/** An upload start request. */
export interface UploadStartRequest {
    /** Gets or sets the name of the file. */
    fileName?: string | null;
    /**
     * Gets or sets the size of the file in bytes.
     * @format int64
     */
    size?: number;
    /**
     * Gets or sets the last modified date.
     * @format int64
     */
    lastModified?: number;
    /** DataCentre. */
    dataCenter?: DataCentreItem;
}

/** A response from adding a file to a deposit. */
export interface UploaderAddFilesToDepositResponse {
    /** Returns true if no validation errors were returned. */
    isValid?: boolean;
    /** Gets or sets the validation errors. */
    validationErrors?: Record<string, string>;
}

/** A UploaderDepositRequest view model. */
export interface UploaderDepositRequest {
    /**
     * Gets or sets the deposit identifier.
     * @format uuid
     */
    depositUuid?: string;
    /** Gets or sets the Success. */
    success?: boolean;
    /** Gets or sets the Files. */
    files?: AddFileToDepositRequest[] | null;
    /** Gets or sets the FailedItems. */
    failedItems?: number[] | null;
    /** Gets or sets the Error. */
    error?: string | null;
}

/** User Response ViewModel. */
export interface UserResponseViewModel {
    /**
     * Gets or sets the identifier.
     * @format uuid
     */
    id?: string;
    /** Gets or sets the name of the user. */
    userName?: string | null;
    /** Gets or sets the full name. */
    fullName?: string | null;
    /** Gets or sets the email. */
    emailAddress?: string | null;
    /** Gets or sets the ui culture. */
    uiCulture?: string | null;
    /**
     * Gets or sets the contact identifier.
     * @format int32
     */
    contactId?: number | null;
    /**
     * Gets or sets the client identifier.
     * @format int32
     */
    clientId?: number | null;
    /** Gets or sets a value indicating whether this user is active. */
    isActive?: boolean;
    /** Gets or sets the title. */
    title?: string | null;
    /** Gets or sets the first name. */
    firstName?: string | null;
    /** Gets or sets the last name. */
    lastName?: string | null;
    /** Gets or sets the job title. */
    jobTitle?: string | null;
    /** Gets or sets the first line of the address. */
    addressLine1?: string | null;
    /** Gets or sets the second line of the address. */
    addressLine2?: string | null;
    /** Gets or sets the third line of the address. */
    addressLine3?: string | null;
    /** Gets or sets the post code. */
    postcode?: string | null;
    /** Gets or sets the telephone number. */
    telephoneNumber?: string | null;
    /** Gets or sets the full name of the account manager. */
    accountManagerFullName?: string | null;
    /** Gets the name of the client. */
    clientName?: string | null;
    /** Gets or sets the list of NCC contacts for the user. */
    nccContacts?: NCCContactRole[] | null;
    /** Gets the errors. */
    errors?: Record<string, string[]>;
    /** Gets a value indicating whether this ncc.escrow.view.libraries.apicommon.Model.ViewModelResponseBase is successful. */
    success?: boolean;
}

/** Dto for UserSettingRequest. */
export interface UserSetting {
    /** Gets or sets the SettingId. */
    settingId?: string | null;
    /** Gets or sets the SettingJson. */
    settingJson?: string | null;
    /** Gets or sets the SettingValue. */
    settingValue?: string | null;
}

/**
 * File verification status.
 * @format int32
 */
export enum VerificationState {
    Value0 = 0,
    Value1 = 1,
    Value2 = 2
}

/** A repository item to verify. */
export interface VerifyRepositoryConfigurationItem {
    /**
     * Gets or sets the identifier.
     * @format int32
     */
    id?: number;
    /**
     * Gets or sets the provider identifier.
     * @format int32
     */
    providerId?: number;
    /** Gets or sets the name of the branch. */
    branchName?: string | null;
}

/** Verify repository configuration. */
export interface VerifyRepositoryConfigurationViewModelRequest {
    /**
     * Gets or sets the items.
     * @minItems 1
     */
    items: VerifyRepositoryConfigurationItem[];
}
