import { Button, Wizard } from 'lib-ui';
import { CommonProps } from '@ncc-frontend/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    ForwardedRef,
    MouseEventHandler,
    ReactNode,
    forwardRef,
    useCallback,
    useMemo,
    useState
} from 'react';
import {
    // faBorderAll,
    faClockRotateLeft
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import TypeOption, {
    ExportType,
    ExportTypeOptionClickHandler
} from './type-option';

type TypeSelectEventHandler = (type: ExportType) => void;

interface ExportSelectionTypeStepProps extends CommonProps {
    onSelect: TypeSelectEventHandler;
}

function ExportSelectionTypeStep(
    { className, onSelect }: ExportSelectionTypeStepProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    const { t } = useTranslation();
    const [type, setType] = useState<ExportType>();

    const handleChange = useCallback<ExportTypeOptionClickHandler>((type) => {
        setType(type);
    }, []);

    const handleSelection = useCallback<MouseEventHandler>(
        (event) => {
            event.stopPropagation();
            type && onSelect(type);
        },
        [onSelect, type]
    );

    const options = useMemo<{ label: ReactNode; type: ExportType }[]>(
        () => [
            // {
            //     label: (
            //         <>
            //             <FontAwesomeIcon icon={faBorderAll} />{' '}
            //             {t('agreements.export.general-export')}
            //         </>
            //     ),
            //     type: 'general'
            // },
            {
                label: (
                    <>
                        <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
                        {t('agreements.export.deposit-history')}
                    </>
                ),
                type: 'deposit-history'
            }
        ],
        [t]
    );

    return (
        <Wizard.Step ref={ref} className={className}>
            {options.map((item) => (
                <TypeOption
                    key={item.type}
                    type={item.type}
                    onClick={handleChange}
                    active={type === item.type}
                >
                    {item.label}
                </TypeOption>
            ))}

            <Button
                onClick={handleSelection}
                disabled={!type}
                className="w-full"
                variant="primary"
            >
                {t('table.select')}
            </Button>
        </Wizard.Step>
    );
}

export default forwardRef(ExportSelectionTypeStep);
export type {
    ExportSelectionTypeStepProps,
    ExportType,
    TypeSelectEventHandler
};
