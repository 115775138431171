import React from 'react';

import AppBody from './app-body';
import SettingsWidget from 'ui/settings/settings-widget/settings-widget';
import System from 'ui/settings/system/system';

const SettingsSystemBody = () => {
    return (
        <AppBody>
            <SettingsWidget>
                <System />
            </SettingsWidget>
        </AppBody>
    );
};

export default SettingsSystemBody;
