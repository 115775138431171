import { DetailedHTMLProps, TextareaHTMLAttributes, forwardRef } from 'react';
import { memo } from 'react';
import classNames from 'classnames';

interface TextareaProps
    extends DetailedHTMLProps<
        TextareaHTMLAttributes<HTMLTextAreaElement>,
        HTMLTextAreaElement
    > {
    error?: boolean;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
    ({ className, error, rows = 3, ...restProps }, ref) => {
        const cssClasses = classNames(
            'px-3 py-1',
            'bg-ncc-white',
            'border rounded-lg',
            'text-sm leading-6',
            'outline-none',
            !error &&
                'focus:border-brand-escode-neonblue-neonblue-100 focus-within:border-brand-escode-neonblue-neonblue-100',
            {
                'border-general-grey-grey-40': !error,
                'border-general-red-red-100 focus:outline-general-red-red-100':
                    error
            },
            className
        );

        return (
            <textarea
                {...restProps}
                ref={ref}
                className={cssClasses}
                rows={rows}
            />
        );
    }
);

export default memo(Textarea);
export type { TextareaProps };
