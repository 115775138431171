/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    CommitFile,
    PrepareResponse
} from '../../autogenerated/data-contracts';
import { MutationOptions, useMutation } from 'react-query';
import { useEffect, useRef, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import useDepositNowStore from 'core/stores/use-deposit-now-store';
import useRefreshToken from 'core/api/spectre-control/hooks/spectre-control-refresh/use-refresh-token';

export type UploadsFilesBlocks = {
    blocks: PrepareResponse;
    file: File;
    uniqueReference: string;
};
const ErrorCodes: number[] = [401, 422, 500, 502, 504, 400, 403, 404];

function useUploadsFilesBlocks<TError = unknown, TContext = unknown>(
    props: {
        onSettled?: () => void;
        onUploadProgress?: (percentCompleted: number) => void;
    } & Omit<
        MutationOptions<
            AxiosResponse<void | unknown>,
            TError,
            UploadsFilesBlocks,
            TContext
        >,
        'mutationFn'
    >
) {
    const [errorCounter, setErrorCounter] = useState(0);
    const { VVToken, depositUniqueReference, spectreAgentUrl } =
        useDepositNowStore();
    const { refetch: refetchTokenData } = useRefreshToken();
    const timeTaken = useRef<number>(0);
    const isCancelledRef = useRef(false);
    const tokenRef = useRef(VVToken); // UseRef to store the token

    const cancelUpload = () => {
        isCancelledRef.current = true;
    };

    // Keep tokenRef updated
    useEffect(() => {
        if (!VVToken) return;
        tokenRef.current = VVToken;
    }, [VVToken]);

    const { isLoading, mutate: uploadFilesBlocksFn } = useMutation<
        AxiosResponse<unknown, any>,
        TError,
        UploadsFilesBlocks,
        TContext
    >(
        async ({
            blocks,
            file,
            uniqueReference
        }: UploadsFilesBlocks): Promise<AxiosResponse<unknown, any>> => {
            isCancelledRef.current = false;

            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useUploadsFilesBlocks call ignored.'
                );
                return Promise.resolve({} as AxiosResponse<void | unknown>);
            }

            const axiosInstance = axios.create({
                baseURL: spectreAgentUrl
            });

            const successfulChunkUploads = [];

            if (blocks && blocks.blockIds && blocks.blockSize) {
                for (let i = 0; i < blocks.blockIds.length; i++) {
                    if (isCancelledRef.current) {
                        return Promise.resolve(
                            {} as AxiosResponse<void | unknown>
                        );
                    }

                    const blockId = blocks.blockIds[i];
                    const start = i * blocks.blockSize;
                    const end = Math.min((i + 1) * blocks.blockSize, file.size);

                    const formData = new FormData();
                    formData.append('block', file.slice(start, end));

                    const uploadBlockWithTimeout = async (blockIndex: number): Promise<void> => {
                        const startTime = Date.now();
                        let timeout: number;

                        // Use a 5-minute timeout for the first block
                        if (blockIndex === 0) {
                            timeout = 300000; // 5 minutes in milliseconds
                        } else {
                            const timeTakenInMs: number = timeTaken.current * 1000;
                            timeout = timeTakenInMs + 60000; // Add 1 minute to the time taken
                        }

                        let attempts = 0;

                        while (attempts < 4) {
                            try {
                                const chunkResponse = await axiosInstance.put(
                                    `/api/uploads/${depositUniqueReference}/${uniqueReference}/${blockId}`,
                                    formData,
                                    {
                                        headers: {
                                            Authorization: `Bearer ${tokenRef.current}`,
                                            'Cache-Control': 'no-cache, no-store, must-revalidate',
                                            'Content-Type': 'multipart/form-data',
                                            Expires: '0',
                                            Pragma: 'no-cache',
                                        },
                                        onUploadProgress: (progressEvent) => {
                                            const totalUploaded =
                                                blockIndex * blocks.blockSize! + progressEvent.loaded;
                                            const percentCompleted = Math.round(
                                                (totalUploaded / file.size) * 100
                                            );

                                            props.onUploadProgress?.(percentCompleted);
                                        },
                                        timeout,
                                    }
                                );

                                const timeElapsed = (Date.now() - startTime) / 1000;

                                if (blockIndex === 0) {
                                    timeTaken.current = timeElapsed; // Set the time taken for the first block
                                }

                                if (chunkResponse.status === 204) {
                                    successfulChunkUploads.push(blockId);
                                    errorCounter > 0 && setErrorCounter(0);
                                    return; // Exit the loop after a successful upload
                                }
                            } catch (error) {
                                if (axios.isAxiosError(error) && error.code === 'ECONNABORTED') {
                                    console.warn('Timeout on block upload');
                                } else if (
                                    axios.isAxiosError(error) &&
                                    error.response?.status === 401
                                ) {
                                    console.warn('401 Unauthorized, attempting token refresh');
                                    await refetchTokenData(); // Refresh the token
                                } else if (
                                    axios.isAxiosError(error) &&
                                    error.response?.status &&
                                    ErrorCodes.includes(error.response?.status)
                                ) {
                                    console.error('Upload failed with status:', error.response?.status);
                                    if (errorCounter < 4) {
                                        setErrorCounter(errorCounter + 1);
                                    } else {
                                        throw error; // Throw after exceeding retries
                                    }
                                } else {
                                    throw error; // Unknown error, rethrow
                                }
                            }

                            attempts++;
                        }

                        throw new Error(
                            `Failed to upload block ${blockIndex} after 4 attempts`
                        ); // Fail after 4 attempts
                    };

                    await uploadBlockWithTimeout(i);
                }

                if (successfulChunkUploads.length === blocks.blockIds.length) {
                    const commitInstance = axios.create({
                        baseURL: spectreAgentUrl,
                        headers: {
                            Authorization: `Bearer ${tokenRef.current}`,
                            'Cache-Control':
                                'no-cache, no-store, must-revalidate',
                            'Content-Type': 'application/json',
                            Expires: '0',
                            Pragma: 'no-cache'
                        }
                    });
                    const data: CommitFile = {
                        blockIds: blocks.blockIds,
                        fileName: file.name,
                        size: blocks.blockSize
                    };

                    const commitResponse = await commitInstance.post(
                        `/api/uploads/${depositUniqueReference}/${uniqueReference}/commit`,
                        JSON.stringify(data)
                    );

                    return commitResponse;
                }
            }

            return {} as AxiosResponse<void>;
        },
        {
            ...props
        }
    );

    return { cancelUpload, isLoading, uploadFilesBlocksFn };
}

export default useUploadsFilesBlocks;
