import { GetScheduledDepositStatsResponse } from '../../autogenerated/data-contracts';

const useDepositsStatsMockData: GetScheduledDepositStatsResponse = {
    annual: 50,
    daily: 10,
    monthly: 30,
    quarterly: 40,
    weekly: 20
};

export default useDepositsStatsMockData;
