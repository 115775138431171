import AppBody from './app-body';
import TermsAndConditions from 'ui/terms-and-conditions/terms-and-conditions';

function TermsAndConditionsBody() {
    return (
        <AppBody suppressPadding>
            <TermsAndConditions />
        </AppBody>
    );
}

export default TermsAndConditionsBody;
